import { TbKey } from "react-icons/tb";
import { Input } from "../../../components/Input";
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form';
import { z } from 'zod'
import { useAuth } from "../../../hooks/useAuth";

import { LoginMFAContainer, LoginMFAHeader, LoginMFAButton, LoginMFAActions } from "./styles";

const loginMFASchema = z.object({
  otp: z.string().max(6, 'O campo Código aceita no máximo 6 digitos.'),
})

export const LoginMFA = () => {
  const { setStep, login2FA, phone } = useAuth();
  
  const { 
    register, 
    handleSubmit, 
    formState: { errors, isSubmitting } 
  } = useForm({
    resolver: zodResolver(loginMFASchema)
  })

  async function handle2FA(data) {
    await login2FA(data.otp);
  }

  const returnStep = () => {
    setStep(1);
  };
  
  return (
    <LoginMFAContainer>
      <LoginMFAHeader>
        <h3>Autenticação de 2 fatores</h3>
        <p>Enviamos um código SMS para {phone}.</p>
      </LoginMFAHeader>

      <form onSubmit={handleSubmit(handle2FA)}>
        <Input  
          type="text"
          label="Código"
          icon={<TbKey />}
          maxLength={6}
          onlyNumber
          register={register('otp')}
        />
        {errors.otp && <span>{errors.otp.message}</span>}

        <LoginMFAActions>
          <LoginMFAButton type={'button'} onClick={returnStep} disabled={isSubmitting}>Voltar</LoginMFAButton>
          <LoginMFAButton type={'submit'} disabled={isSubmitting}>
            {isSubmitting ? 'Entrando...' : 'Entrar'}
          </LoginMFAButton>
        </LoginMFAActions>
      </form>
    </LoginMFAContainer>
  )
}