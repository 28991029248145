import styled from "styled-components";

export const EntryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: auto;

  &:first-child {
    height: 100%;
  }
`;

export const EntryHeader = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  & a {
    color: var(--header-link-color);
    font-size: 12px;
    font-weight: 400;
  }

`
export const EntryFilter = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: start;
  color: var(--text-primary);
  width: 100%;
  margin-bottom: 30px;

  & h2 {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`

export const FilterItem = styled.div`
  border-radius: 4px;
  border: 1px solid var(--text-primary);
  background: var(--bg-color);
  display: flex;
  height: 38px;
  align-items: center;
  margin-right: 15px;

  & svg {
    margin-left: 10px;
  }

  & input, select {
    background: var(--bg-color) !important;
    color: var(--text-primary) !important;
    border: none;
    height: 36px;
    width: 100%;
    align-items: center;
    margin-left: 10px;
    border-radius: 4px;
  }

  & input:focus, select:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
  }

  & input::placeholder {
    color: var(--text-primary);
  }

  & select option {
    color: var(--text-primary);
  }
`
export const FilterButton = styled.button`
  display: flex;
  width: 180px;
  height: 38px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;

  background-color: ${({theme}) => theme.colors['filter-button-background'] };
  color: ${({theme}) => theme.colors['filter-button-color'] };
  border: ${({theme}) => theme.colors['filter-button-border'] };
`

export const EntryList = styled.div`
  color: var(--text-primary);
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & h2 {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
    margin: 0;
  }

  & h3 {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0.5px;
    margin: 0;
  }
`
export const EntryRow = styled.div`
  width: 100%;
  padding-bottom: 2rem;
`

export const EntryScroll = styled.div`
  overflow-y: auto;
  margin-top: 20px;
  height: 568px;
  scrollbar-width: thin;
  scrollbar-color: gray transparent;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
      background: transparent;
      padding: 3px;
  }

  &::-webkit-scrollbar-thumb {
      background-color: #cecece;
      border-radius: 10px;
  }
`

export const EntryMonth = styled.div`
  padding: 10px 12px;
  border-bottom: 1px solid var(--bg-auxiliary-color);
`

export const EntryDay = styled.div`
  background: var(--bg-auxiliary-color);
  display: flex;
  padding: 10px 32px;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 20px;
`
export const LoaderDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const EntryMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${({theme}) => theme.colors['entry-message-color'] };;
  margin-top: 1em;
`

