import axios from "axios";
import { Input } from "../../../../components/Input";
import { Box } from "../../../../components/box";
import { getImage } from "../../../../services/getAssets";
import { cnpjCpfValidator } from "../../../../utils/taxNumberValidator";
import { toast } from "react-toastify";
import { useTicket } from "../../../../hooks/useTicket";
import { useChargeTicket } from "../../../../hooks/useChargeTicket";
import { formatCnpjCpf } from '../../../../utils/formatter'
import { useState } from "react";
import moment from "moment";
import { calculateDiscountDue, calculateFineDue } from "../../../../utils/boletoUtils";
import { emailIsValid } from "../../../../utils/validator";

export function CobrarBoletoReceiveInfos() {
  const [ticketTaxNumberError, setTicketTaxNumberError] = useState("");
  const [ticketZipCodeError, setTicketZipCodeError] = useState("");
  const [ticketPhoneError, setTicketPhoneError] = useState("");
  const [ticketZipCodeLoader, setTicketZipCodeLoader] = useState(false);

  const { setStep, emitTicketFitbank } = useTicket()

  const { 
    ticketRecipientName, 
    setTicketRecipientName,
    ticketTaxNumber,
    ticketEmail,
    setTicketEmail,
    ticketPhone,
    setTicketPhone,
    ticketZipCode,
    ticketCity,
    setTicketCity,
    ticketState,
    setTicketState,
    ticketValue,
    ticketDueDate,
    setTicketTaxNumber,
    setTicketZipCode,
    fineType,
    fineValue,
    feesValue,
    deadlineDiscount,
    deadlinePayment,
    discountType,
    discountValue,
    chargeDiscount,
    chargeInterest,
    chargeFees,
  } = useChargeTicket()

  function validaTaxNumber(tax) {
    const unformatterTaxNumber = tax.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')
    const taxNumberIsValid =  cnpjCpfValidator(unformatterTaxNumber)

    if (!taxNumberIsValid) {
      setTicketTaxNumberError('CPF/CNPJ inválido.')
      return false
    } else {
      setTicketTaxNumberError('')
      return unformatterTaxNumber
    }
  }

  function validaZipCode(code) {
    const unformatterZipCode = code.replaceAll('-', '').replaceAll('_', '')

    if (unformatterZipCode.length !== 8) {
      setTicketZipCodeError('CEP inválido.')
      return false
    } else {
      setTicketZipCodeError('')
      return unformatterZipCode
    }
  }

  function validaPhone(phone) {
    const unformatterPhone = '+55' + phone
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll('-', '')
    .replaceAll(' ', '')
    .replaceAll('_', '')

    if (unformatterPhone.length < 14) {
      setTicketPhoneError('Telefone inválido.')
      return false
    } else {
      setTicketPhoneError('')
      return unformatterPhone
    }
  }

  function handleConfirm(e) {
    e.preventDefault();

    const taxNumber = validaTaxNumber(ticketTaxNumber);
    const zipCode = validaZipCode(ticketZipCode);
    const phone = validaPhone(ticketPhone);
    
    if (!emailIsValid(ticketEmail)) {
      toast.error('E-mail inválido.');
      
      return
    }

    if (!taxNumber) return 

    if (!zipCode) return

    if (!phone) return

    const data = {
      amount: ticketValue,
      dueDate: ticketDueDate,
      customer: {
        taxNumber,
        name: ticketRecipientName,
        email: ticketEmail,
        phone,
        zipCode,
        city: ticketCity,
        state: ticketState,
      },
      url_logo: getImage('logo-preto.png')
    }

    if (chargeDiscount) {
      data.discounts = {
        discountDueDate: calculateDiscountDue(ticketDueDate, deadlineDiscount),
        discountType,
        discountValue,
        amount: ticketValue,
      }
    }

    if (chargeInterest) {
      data.fine = {
        dateAfterMaturity: calculateFineDue(ticketDueDate, deadlinePayment),
        fineType,
        fineValue,
      }
    }

    if (chargeFees) {
      data.fees = {
        value: feesValue,
      }
    }

    emitTicketFitbank(data)
  }

  function onChangeTaxNumber(taxNumber) {
    setTicketTaxNumber(formatCnpjCpf(taxNumber))
  }

  function onChangeZipCode(zipCode) {
    const unformatted = zipCode.replaceAll('_', '').replaceAll('-', '');

    if (unformatted.length === 8) {
      setTicketZipCodeLoader(true)

      axios.get(`https://viacep.com.br/ws/${unformatted}/json/`).then((response) => {
        if (response.data.erro) {
          toast.error('CEP não encontrado.')
        }

        const data = response.data
        setTicketZipCodeError('');
        setTicketCity(data.localidade)
        setTicketState(data.uf)
        setTicketZipCodeLoader(false)
      }).catch(() => {
        toast.error('CEP inválido.')
      })
    } 

    setTicketZipCode(zipCode)
  }

  return (
    <Box
      title='Cobrar via Boleto'
      description='Insira as informações de quem vai receber o boleto que deseja enviar'
    >
      <form className='step1-content' onSubmit={handleConfirm}>
        <div className='step1-content-body'>
          <div className='step1-inputs'>
            <Input 
              type='text'
              label='Nome de quem vai receber'
              value={ticketRecipientName}
              onChange={setTicketRecipientName}
              isRequired
              disabled={ticketZipCodeLoader}
            />
            <Input 
              type='text'
              label='CPF/CNPJ'
              value={ticketTaxNumber}
              onChange={onChangeTaxNumber}
              maxLength={18}
              isRequired
              disabled={ticketZipCodeLoader}
              error={ticketTaxNumberError}
            />
            <Input 
              type='email'
              label='Email'
              value={ticketEmail}
              onChange={setTicketEmail}
              isRequired
              disabled={ticketZipCodeLoader}
            />
            <Input 
              type='mask'
              mask='(99) 99999-9999'
              label='Telefone'
              value={ticketPhone}
              onChange={setTicketPhone}
              isRequired
              disabled={ticketZipCodeLoader}
              error={ticketPhoneError}
            />
            <Input 
              type='mask'
              mask='99999-999'
              label='CEP'
              value={ticketZipCode}
              onChange={onChangeZipCode}
              isRequired
              disabled={ticketZipCodeLoader}
              error={ticketZipCodeError}
            />
            <Input 
              type='text'
              label='Cidade'
              value={ticketCity}
              onChange={setTicketCity}
              isRequired
              disabled
            />
            <div className='input-sm'>
              <Input 
                type='text'
                label='UF'
                value={ticketState}
                onChange={setTicketState}
                isRequired
                disabled
              />
            </div>
          </div>
        </div>
        <div className='step-buttons'>
          <button type='button' className='step-button' onClick={() => setStep(1)}>Voltar</button>
          <button type='submit' className='step-button step-button-red'>Confirmar</button>
        </div>
      </form>
    </Box>
  )
}