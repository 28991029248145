import { createContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { apiV1 } from './../lib/axios';
import { uploadS3 } from '../services/uploadS3';
import { fileToBase64 } from '../utils/fileToBase64';
import { toast } from 'react-toastify';
import { getDocumentFormat } from '../utils/documentFormat';
import { documentTypes } from '../services/enums';

export const RegisterContext = createContext({});

export function RegisterProvider({ children }) {
  const[document, setDocument] = useState()
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [companyDataPj, setCompanyDataPj] = useState({});
  const [addressPj, setAddressPj] = useState({});
  const [representativePj, setRepresentativePj] = useState([]);
  const [personPf, setPersonPF] = useState({});
  const [contactPf, setContactPF] = useState({});
  const [addressPf, setAddressPf] = useState({});
  const [personType, setPersonType] = useState('');
  const [documentsPj, setDocumentsPj] = useState({});
  const [documentsPf, setDocumentsPf] = useState({});
  const [documentTypePf, setDocumentTypePf] = useState(null);

  // Pessoa Juridica

  const postCompanyDataPj = (data) => {
    switch (data.companyType) {
      case '0':
        setPersonType('SA');
        break;
      case '1':
        setPersonType('LTDA');
        break;
      case '2':
        setPersonType('MEI');
        break;
      case '3':
        setPersonType('ME');
        break;
    }
    setCompanyDataPj(data);
    setStep(2);
  };

  const postMfaData = () => {
    setStep(3);
  };

  const postAddressFormPj = (data) => {
    setAddressPj(data);
    setStep(4);
  };

  const postRepresentativeFormPj = async (data) => {
    setRepresentativePj(data);
    setStep(5);
  };

  const postDocumentsFormPj = async (data) => {
    const documents = {};

    if (data.socialContract?.length) {
      const socialContract = await fileToBase64(
        data.socialContract.item(0)
      ).then((response) => response);

      documents['socialContract'] = {
        base64: socialContract,
        mimetype: data.socialContract.item(0).type.split('/')[1],
      };
    }

    if (data.proofIncome?.length) {
      const proofIncome = await fileToBase64(data.proofIncome.item(0)).then(
        (response) => response
      );

      documents['proofIncome'] = {
        base64: proofIncome,
        mimetype: data.proofIncome.item(0).type.split('/')[1],
      };
    }

    const proofRegistration = await fileToBase64(
      data.proofRegistration.item(0)
    ).then((response) => response);

    documents['proofRegistration'] = {
      base64: proofRegistration,
      mimetype: data.proofRegistration.item(0).type.split('/')[1],
    };

    setDocumentsPj(documents);

    setStep(6);

    // await registerPj(documents);
  };

  const pjRegisterPerson = async () => {
    const data = {
      type: 1,
      companyType: companyDataPj.companyType,
      companyActivity: companyDataPj.companyActivity,
      name: companyDataPj.fantasyName,
      legalName: companyDataPj.socialReason,
      constitutionDate: companyDataPj.constitutionDate,
      phoneNumber: companyDataPj.phone.replace(/[\s()-]/g, ''),
      taxNumber: companyDataPj.cnpj.replace(/\D/g, ''),
      mail: companyDataPj.email,
      motherFullName: representativePj[0].motherName,
      nationality: 'BRA',
      birthState: addressPj.stateCode,
      birthDate: representativePj[0].birthDate,
      servicePackageId: '1',
      birthCity: addressPj.city,
      birthState: addressPj.state,
      invoicing: companyDataPj.monthlyIncome ?? '',
      password: companyDataPj.password
    };

    const personId = await apiV1
      .post('/person/store', data)
      .then((response) => {
        return response.data.person.id;
      })
      .catch((err) => {

      });

    return personId;
  };

  const pjRegisterRepresentative = async (personId, representativePj) => {
    const data = {
      personId,
      name: representativePj.name,
      taxNumber: representativePj.cpf.replace(/\D/g, ''),
      mail: representativePj.email,
      phoneNumber: representativePj.phone.replace(/[\s()-]/g, ''),
      birthDate: representativePj.birthDate,
      motherFullName: representativePj.motherName,
      gender: representativePj.gender,
      maritalStatus: representativePj.maritalStatus,
      birthCity: representativePj.birthCity,
      birthState: representativePj.birthState,
    };

    const representativeId = await apiV1
      .post('/representative/store', data)
      .then((response) => {
        return response.data.data.id;
      })
      .catch((err) => {

      });

    if (representativePj.documentType === 'CNH') {
      const cnh = await fileToBase64(
        representativePj.documentFront.item(0)
      ).then((response) => response);

      const cnhFile = await uploadS3(
        `cnh.${representativePj.documentFront.item(0).type.split('/')[1]}`,
        cnh
      );

      await pjRegisterDocuments({
        personId,
        representativeId,
        type: documentTypes.CNHFrente,
        name: 'CNH',
        format: getDocumentFormat(
          representativePj.documentFront.item(0).type.split('/')[1]
        ),
        file: cnhFile,
      });
    } else {
      const rgFront = await fileToBase64(
        representativePj.documentFront.item(0)
      ).then((response) => response);

      const rgFrontFile = await uploadS3(
        `rgFront.${representativePj.documentFront.item(0).type.split('/')[1]}`,
        rgFront
      );

      await pjRegisterDocuments({
        personId,
        representativeId,
        type: documentTypes.rgFrente,
        name: 'RG Frente',
        format: getDocumentFormat(
          representativePj.documentFront.item(0).type.split('/')[1]
        ),
        file: rgFrontFile,
      });

      const rgBack = await fileToBase64(
        representativePj.documentBack.item(0)
      ).then((response) => response);

      const rgBackFile = await uploadS3(
        `rgBack.${representativePj.documentBack.item(0).type.split('/')[1]}`,
        rgBack
      );

      await pjRegisterDocuments({
        personId,
        representativeId,
        type: documentTypes.rgVerso,
        name: 'RG Verso',
        format: getDocumentFormat(
          representativePj.documentBack.item(0).type.split('/')[1]
        ),
        file: rgBackFile,
      });
    }

    return representativeId;
  };

  const pjRegisterAddress = async (personId) => {
    const data = {
      personId,
      addressLine: addressPj.street,
      addressLine2: addressPj.number,
      zipCode: addressPj.cep.replace(/\D/g, ''),
      neighborhood: addressPj.neighborhood,
      cityName: addressPj.city,
      state: Number(addressPj.state),
      addressType: 0,
      country: 'BRA',
      complement: addressPj.complement,
      cityCode: addressPj.ddd,
    };

    await apiV1
      .post('/address/store', data)
      .then(() => {})
      .catch((err) => {

      });
  };

  const pjRegisterDocuments = async ({
    personId,
    representativeId,
    type,
    name,
    format,
    file,
  }) => {
    const data = {
      personId,
      representativeId,
      documentFile: file,
      documentFormat: format,
      documentName: name,
      documentType: type,
      description: name,
    };

    await apiV1
      .post('/document/store', data)
      .then(() => {})
      .catch((err) => {

      });
  };

  const registerPj = async () => {
    try {
      setLoading(true);

      const documents = documentsPj;

      const personId = await pjRegisterPerson();
      if (!personId) {
        toast.error(
          'Não foi possível concluir o cadastro.'
        );
        setLoading(false);
        return;
      }
      await pjRegisterAddress(personId);

      let representativeIds = []

      representativePj.forEach(async item => {
        representativeIds.push(await pjRegisterRepresentative(personId, item));
      });

      if (documents.socialContract) {
        const socialContract = await uploadS3(
          `contrato-social.${documents.socialContract.mimetype}`,
          documents.socialContract.base64
        );

        await pjRegisterDocuments({
          personId,
          representativeId: representativeIds[0],
          type: documentTypes.socialContract,
          name: 'Contrato Social',
          format: getDocumentFormat(documents.socialContract.mimetype),
          file: socialContract,
        });
      }

      if (documents.proofIncome) {
        const proofIncome = await uploadS3(
          `comprovante-renda.${documents.proofIncome.mimetype}`,
          documents.proofIncome.base64
        );

        await pjRegisterDocuments({
          personId,
          representativeId: representativeIds[0],
          type: documentTypes.compRenda,
          name: 'Comprovante Renda',
          format: getDocumentFormat(documents.proofIncome.mimetype),
          file: proofIncome,
        });
      }
      const proofRegistration = await uploadS3(
        `cartao-cnpj.${documents.proofRegistration.mimetype}`,
        documents.proofRegistration.base64
      );

      await pjRegisterDocuments({
        personId,
        representativeId: representativeIds[0],
        type: documentTypes.CNPJ,
        name: 'Cartão CNPJ',
        format: getDocumentFormat(documents.proofRegistration.mimetype),
        file: proofRegistration,
      });

      setSuccess(true);
      setLoading(false);
    } catch (err) {

    }
  };

  // Pessoa Física

  const postPersonDataPf = (data) => {
    setPersonPF(data);
    setStep(2);
  };

  const postContactDataPf = (data) => {
    setContactPF(data);
    setStep(3);
  };

  const postAddressFormPf = (data) => {
    setAddressPf(data);
    setStep(4);
  };

  const postDocumentsFormPf = async (type, data) => {
    const documentFront = await fileToBase64(data.documentFront.item(0)).then(
      (response) => response
    );

    const documentBack = await fileToBase64(data.documentBack.item(0)).then(
      (response) => response
    );

    const documentFrontData = {
      base64: documentFront,
      mimetype: data.documentFront.item(0).type.split('/')[1],
    };

    const documentBackData = {
      base64: documentBack,
      mimetype: data.documentBack.item(0).type.split('/')[1],
    };

    const documents = {
      documentFront: documentFrontData,
      documentBack: documentBackData,
    };

    setDocumentsPf(documents);
    setDocumentTypePf(type);
    setStep(5);
  };

  const pfRegisterPerson = async (documentType) => {
    const data = {
      type: 0,
      name: personPf.name,
      legalName: personPf.name,
      phoneNumber: contactPf.phone.replace(/[\s()-]/g, ''),
      taxNumber: personPf.cpf.replace(/\D/g, ''),
      mail: contactPf.email,
      birthDate: personPf.birthDate,
      motherFullName: personPf.motherName,
      nationality: 'BRA',
      birthCity: addressPf.city,
      birthState: Number(addressPf.state),
      servicePackageId: '1',
      documentType: documentType,
      password: contactPf.password,
      constitutionDate: personPf.birthDate,
    };

    const personId = await apiV1
      .post('/person/store', data)
      .then((response) => {
        return response.data.person.id;
      })
      .catch((err) => {

      });

    return personId;
  };

  const pfRegisterAddress = async (personId) => {
    const data = {
      personId,
      addressLine: addressPf.street,
      addressLine2: addressPf.number,
      zipCode: addressPf.cep.replace(/\D/g, ''),
      neighborhood: addressPf.neighborhood,
      cityCode: addressPf.ddd,
      cityName: addressPf.city,
      state: Number(addressPf.state),
      addressType: 0,
      country: 'BRA',
      complement: addressPf.complement,
    };

    await apiV1
      .post('/address/store', data)
      .then(() => {})
      .catch((err) => {
        toast.error('Erro ao tentar salvar informações de endereço.');
        setStep(1);
        setLoading(false);
      });
  };

  const registerPf = async () => {
    try {
      const documents = documentsPf;
      const documentType = documentTypePf;

      setLoading(true);
      const personId = await pfRegisterPerson(documentType);

      if (!personId) {
        toast.error('Erro ao enviar os dados pessoais, tente novamente.');
        setStep(1);
        setLoading(false);
        return;
      }

      await pfRegisterAddress(personId);

      const documentFrente = await uploadS3(
        `${documentType.toLowerCase()}-frente.${
          documents.documentFront.mimetype
        }`,
        documents.documentFront.base64
      );
      const documentVerso = await uploadS3(
        `${documentType.toLowerCase()}-verso.${
          documents.documentBack.mimetype
        }`,
        documents.documentBack.base64
      );

      await pjRegisterDocuments({
        personId,
        type: documentType === 'RG' ? 0 : 3,
        name: `${documentType} Frente`,
        format: getDocumentFormat(documents.documentFront.mimetype),
        file: documentFrente,
      });

      await pjRegisterDocuments({
        personId,
        type: documentType === 'RG' ? 7 : 16,
        name: `${documentType} Verso`,
        format: getDocumentFormat(documents.documentBack.mimetype),
        file: documentVerso,
      });

      setSuccess(true);
      setLoading(false);
    } catch (err) {

    }
  };

  const postDocumentsPixExtract = async (data) => {
    const documents = {};
  
    if (data.extrato?.length) {
      try {
        const extrato = await fileToBase64(data.extrato.item(0))
          .then((response) => response);
  
        documents['extrato'] = {
          base64: extrato,
          mimetype: data.extrato.item(0).type.split('/')[1],
        };
      } catch (error) {
        console.error('Erro ao converter extrato para base64:', error);
        toast.error('Erro ao processar o extrato');
        return;
      }
    }
  
    setDocumentsPj(documents);
    setStep(5);
  
    try {
      let proofIncomeLink = '';
      if (documents.extrato) {
        proofIncomeLink = await uploadS3(
          `comprovante-renda.${documents.extrato.mimetype}`,
          documents.extrato.base64
        );
  
        console.log('Link do Comprovante de Renda:', proofIncomeLink);
  
        await pjRegisterDocuments({
          personId,
          representativeId: representativeIds[0],
          type: documentTypes.compRenda,
          name: 'Comprovante Renda',
          format: getDocumentFormat(documents.extrato.mimetype),
          file: proofIncomeLink,
        });
      }
  
      let proofRegistrationLink = '';
      if (data.proofRegistration?.length) {
        const proofRegistrationFile = await fileToBase64(data.proofRegistration.item(0))
          .then((response) => response);
  
        proofRegistrationLink = await uploadS3(
          `cartao-cnpj.${data.proofRegistration.item(0).type.split('/')[1]}`,
          proofRegistrationFile
        );
  
        console.log('Link do Comprovante de Registro:', proofRegistrationLink);
  
        await pjRegisterDocuments({
          personId,
          representativeId: representativeIds[0],
          type: documentTypes.compRegistro,
          name: 'Comprovante Registro',
          format: getDocumentFormat(data.proofRegistration.item(0).type.split('/')[1]),
          file: proofRegistrationLink,
        });
      }
    } catch (error) {
      console.error('Erro ao fazer upload ou registrar documentos:', error);
      toast.error('Erro ao registrar documentos');
    }
  
    return documents;
  }; 

  const registerExtract = async () => {
    try {
      setLoading(true);
  
      const documents = documentsPj;
  
      // Verifica se o documento de extrato está presente
      if (!documents.extrato) {
        toast.error('Documento de extrato não encontrado.');
        setLoading(false);
        return;
      }
  
      const personId = await pjRegisterPerson();
      if (!personId) {
        toast.error('Não foi possível concluir o cadastro.');
        setLoading(false);
        return;
      }
      await pjRegisterAddress(personId);
  
      let representativeIds = [];
  
      for (const item of representativePj) {
        const repId = await pjRegisterRepresentative(personId, item);
        representativeIds.push(repId);
      }
  
      // Upload do extrato para o S3
      const extratoBase64 = await fileToBase64(documents.extrato.file);
      const extrato = await uploadS3(
        `extrato.${documents.extrato.mimetype}`,
        extratoBase64
      );
  
      // Registro do extrato nos documentos PJ
      await RegisterDocuments({
        personId,
        representativeId: representativeIds[0],
        type: documentTypes.extrato, // Certifique-se de que 'extrato' está definido corretamente em documentTypes
        name: 'Extrato',
        format: getDocumentFormat(documents.extrato.mimetype),
        file: extrato,
      });
  
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      console.error('Erro ao registrar o extrato:', err);
      toast.error('Erro ao registrar o extrato.');
      setLoading(false);
    }
  };
  return (
    <RegisterContext.Provider
      value={{
        success,
        step,
        loading,
        postCompanyDataPj,
        postAddressFormPj,
        postRepresentativeFormPj,
        postDocumentsFormPj,
        registerPj,
        registerPf,
        postPersonDataPf,
        postAddressFormPf,
        postDocumentsFormPf,
        setStep,
        postContactDataPf,
        personPf,
        contactPf,
        addressPf,
        companyDataPj,
        representativePj,
        personType,
        addressPj,
        postDocumentsPixExtract,
        registerExtract,
        document,
        setDocument,
        postMfaData,
      }}

    >
      <Outlet />
    </RegisterContext.Provider>
  );
}
