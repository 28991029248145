import React from "react";

import "../../deposito/copiar-dados/Style.css";

export default function () {
    return (
        <div className="pagina-dados">
            <div className="container">
                <div className="titulo row">
                    <p>Copiar dados da conta</p>
                </div>

                <div className="copiar-dados row ">
                <h1>Copiar dados</h1>
                <div className="col-xl-4" >
                    <div>
                        <p>Banco </p>
                        <h2>Banco (653)</h2>   
                   </div> 
                   </div> 
                   <div className="col-xl-2" >
                        <div>
                            <p>CPF</p>
                            <h2>039.453.982-65</h2> 
                        </div> 
                   </div>
                        <div className="row">
                        <div className="col-xl-2" >
                        <p>conta</p>
                        <h2>12349-0</h2>
                   </div>  
                   <div className="col-xl-2" >
                        <p>Agência</p>
                        <h4>000-1</h4>
                   </div> 
                   <div className="col-xl-4" >
                        <p>Nome do titular</p>
                        <h3>Antonio Carlos Silva</h3>
                   </div> 
                   <div className="botao-confirmar">
                        <button>Copiar dados da conta</button>
                   </div>
                   </div>                                                 
                </div>
                       
            </div>
        </div>
    )
}