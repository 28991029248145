import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiV1 } from "../lib/axios";
import { removeTokens, storeTokens } from "../utils/sessionMethods";

export const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [isLogged, setIsLogged] = useState(false);
  const [loginLoader, setLoginLoader] = useState(false);
  const [user, setUser] = useState({});
  const [isFirstAccess, setIsFirstAccess] = useState(false);
  const [isFirstAccessConfirmed, setIsFirstAccessConfirmed] = useState(false);
  const [firstAccessLoader, setFirstAccessLoader] = useState(false);
  const [phone, setPhone] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [step, setStep] = useState(1);

  const [firstLoginPass, setFirstLoginPass] = useState(false);

  const navigate = useNavigate();

  const account = user.account;

  async function login(taxNumber, password, fingerprint, captchaToken) {
    setLoginLoader(true);
    const data = {
      taxNumber,
      password,
      mac: fingerprint,
      captchaToken,
    };

    await apiV1
      .post("/login/session", data)
      .then((response) => {
        setPhone(response.data.phone);

        setIsFirstAccess(response.data.isFirstAccess);

        if( response.data.status ) {
          toast.warning(response.data.message);
          return;
        }

        if (!response.data.isFirstAccess) {
          setFirstLoginPass(true);
        }

        setTaxNumber(taxNumber);
        setLoginLoader(false);
        setStep(2);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoginLoader(false);
      });
  }

  async function login2FA(cod) {
    setLoginLoader(true);

    await apiV1
      .post("/login/authenticate", {
        taxNumber,
        otp: cod,
      })
      .then((response) => {
        setIsLogged(true);

        storeTokens(
          response.data.accessToken,
          response.data.refreshToken,
        );

        getProfile();

        navigate("/pagina-principal");
      })
      .catch((err) => {
        toast.error("Acesso negado, tente novamente!");
      })
      .finally(() => {
        setLoginLoader(false);
      });
  }

  async function logout() {
    setFirstLoginPass(false);
    removeTokens();
    setIsLogged(false);
    setUser({});
    setStep(1);
    navigate("/");
  }

  async function getProfile() {
    await apiV1.get(`/person/show`).then((response) => {
      const result = response.data.data;

      localStorage.setItem("name", result.name);
      localStorage.setItem("taxNumber", result.taxNumber);
      // localStorage.setItem("deviceId", fingerprint);
      localStorage.setItem("personId", result.id);
      localStorage.setItem("accountId", result.account.id);
      localStorage.setItem("bank", result.account.bank);
      localStorage.setItem("bankAccount", result.account.bankAccount);
      localStorage.setItem("bankAccountDigit", result.account.bankAccountDigit);
      localStorage.setItem("bankBranch", result.account.bankBranch);
      localStorage.setItem("login", result.login);

      setUser(result);
    });
  }

  return (
    <AuthContext.Provider
      value={{
        isLogged,
        loginLoader,
        user,
        account,
        isFirstAccess,
        isFirstAccessConfirmed,
        firstAccessLoader,
        phone,
        firstLoginPass,
        step,
        login,
        login2FA,
        logout,
        setIsFirstAccess,
        setIsFirstAccessConfirmed,
        getProfile,
        setFirstLoginPass,
        setStep,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
