import { validarCPF } from "../services/validarCPF";

export function identificarTipoChavePix(chave) {
  const regexCNPJ = /^(\d{14}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/;
  const regexTelefone = /^(\+\d{2}(\d{2}|\d{3})\d{8,9}|\d{11,12})$/;
  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (validarCPF(chave)) {
    return 0; // Chave PIX do tipo CPF
  } else if (regexCNPJ.test(chave)) {
    return 1; // Chave PIX do tipo CNPJ
  } else if (regexEmail.test(chave)) {
    return 2; // Chave PIX do tipo e-mail
  } else if (regexTelefone.test(chave)) {
    return 3; // Chave PIX do tipo telefone
  } else {
    return 4; // Chave PIX com formato desconhecido ou chave aleatória
  }
}