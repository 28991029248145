const formats = [
  { value: 0, label: "pdf" },
  { value: 1, label: "jpg" },
  { value: 2, label: "jpeg" },
  { value: 4, label: "png" },
];

export const getDocumentFormat = (mimetype) => {
  return formats.find((item) => item.label === mimetype).value;
};
