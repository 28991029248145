import moment from "moment";

export function deadlineOptions() {
  let options = [{ name: "Até 1 dia", value: 1 }];

  for (let i = 2; i < 31; i++) {
    options.push({ name: `Até ${i} dias`, value: i });
  }

  return options;
}

export function interestAmounts() {
  let options = [{ name: "1% ao mês", value: 1 }];

  for (let i = 2; i < 31; i++) {
    options.push({ name: `${i}% ao mês`, value: i });
  }

  return options;
}

export function discountDueDates() {
  let options = [{ name: "Até a data do vencimento", value: 1 }];

  for (let i = 1; i < 11; i++) {
    options.push({ name: `Até ${i} dias antes do vencimento`, value: i });
  }

  return options;
}

export function calculateDiscountDue(dueDate, discountDueDate) {
  if (discountDueDate === 1) {
    return dueDate;
  }

  return moment(dueDate).subtract(discountDueDate, "days").format("YYYY-MM-DD");
}

export function calculateFineDue(dueDate, fineDate) {
  return moment(dueDate).add(fineDate, "days").format("YYYY-MM-DD");
}
