export const genderOptions = [
  { label: "Selecione uma Opção", value: "", selected: true , disabled: true},
  { label: "Masculino", value: 0 },
  { label: "Feminino", value: 1 },
  { label: "Outros", value: 2 },
]

export const getGender = (gender) => {
  const genderObj = genderOptions.find((item) => item.label == gender);

  return genderObj?.value;
};

export const getGenderLabel = (gender) => {
  const genderObj = genderOptions.find((item) => item.value == gender);

  return genderObj?.label;
}
