import React from "react";

import "../deposito/Style.css"

import { Link } from "react-router-dom"

export default function () {
    return (
        <div className="deposito">
            <div className="container">
                <div className="titulo">
                    <p>Deposito</p>
                </div>

                <div className="forma-deposito">
                    <div>
                        <h1>Como você deseja depositar</h1>
                    </div>

                    <Link to={"/gerar-codigo"}>
                        <div className="bloco">
                            <div>
                                <h2>Gere um código Pix</h2>
                                <p>Gerar um códigodo para depositar</p>
                            </div>
                        </div>
                    </Link>

                    <Link to={"/pagina-novo-boleto"}>
                        <div className="bloco">
                            <div>
                                <h2>Gerar um boleto para Deposito</h2>
                                <p>Gerar um boleto para fazer um deposito</p>
                            </div>
                        </div>
                    </Link>

                    <Link to={"/copiar-dados"}>
                        <div className="bloco">
                            <div>
                                <h2>Copiar dados da conta</h2>
                                <p>Ao selecionar essa opção poderar copiar seus dados</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}