import { BoxModal } from '../../../components/boxModal';
import { TbCircleKey } from 'react-icons/tb';
import './styles.css';

export const FeedBackKeyDeleted = ({handleCloseModal, keyType }) => {

  const titleMessage = () => {
    return 'Chave em processo de exclusão!'
  }

  const description = () => {
    return "Sua chave está em processo de exclusão, aguarde alguns instantes que em breve ela será excluida definitivamente."
  }



  return (

    <BoxModal
            icon='circleCheck'
            title={titleMessage()}
            description={description()}
            maxWidth={724}
            close={handleCloseModal}
          >
            <div className='pix-new-key-container'>
              <div className="pix-new-key-info">
                <TbCircleKey size={40} />

                <div className="pix-new-key-content">
                  <strong>Chave Pix do tipo: {TYPE_KEY_LIST[keyType].name}</strong>
                </div>
              </div>

              <span className='pix-new-key-status'>
                {'Exclusão em andamento'}
              </span>
            </div>
          </BoxModal>
  );
}
// código reaproveitado, necessário criar uma lib de auxilio futuramente
const TYPE_KEY_LIST = [
  {name: 'CPF'},
  {name: 'CNPJ'},
  {name: 'E-mail'},
  {name: 'Telefone'},
  {name: 'Chave aleatória'},
]

const KEY_TYPE = {
  CPF: 0,
  CNPJ: 1,
  MAIL: 2,
  PHONE: 3,
  RANDOM: 4,
}
