import { apiV1 } from "../lib/axios";

export function isPasswordStrong(password) {
  if (password.length < 8) {
    return false;
  }

  if (!/[A-Z]/.test(password)) {
    return false;
  }

  if (!/[a-z]/.test(password)) {
    return false;
  }

  if (!/[0-9]/.test(password)) {
    return false;
  }

  if (!/[!@#$%^&*()\-_=+{};:,<.>/?]/.test(password)) {
    return false;
  }

  return true;
}



export async function forgotPassword(taxNumber, email) {
  try {
    await apiV1.post("/account/forgotpassword", {taxNumber, email});

    return {ok: true}
  } catch (err) {
    return {ok: true, erro: err} // retornando true mesmo com erro, para não dar certeza se CPF e emails estão relacionados, e dar brecha para invasores
  }
}

export async function verifyHash(hash) {
  try {
    await apiV1.post("/account/verifyhash", {temporaryHash : hash});

    return {ok: true}
  } catch (err) {
    return {ok: false, erro: err}
  }
}

export async function changePassword(hash, password, confirmPassword, code) {
    await apiV1.post("/account/changepassword", {temporaryHash : hash, newPassword: password, newConfirmPassword : confirmPassword, code });

    return {ok: true}
}
