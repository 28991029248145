import { apiV1 } from "../lib/axios";

export const uploadS3 = async (name, file) => {
  const url = await apiV1
    .post("/s3url", { name, file })
    .then(async (response) => {
      return response.data.documentUrl;
    })

  return url
};
