import React from "react";

import "./Style.css"

import { Link } from "react-router-dom"

import { BsCreditCard2Back } from "react-icons/bs"
import { BsUpcScan } from "react-icons/bs"
import { MdSmartphone } from "react-icons/md"
import { FaPix } from "react-icons/fa6";

export default function () {
    return (
        <div className="pagar">
            <div className="container">
                <div className="titulo">
                    <p>Pagar</p>
                </div>

                <div className="forma-pagamento">
                    <div>
                        <h1>Formas de Pagamento</h1>
                    </div>

                        {/* <div className="pagamentos">
                            <div className="row">
                                <div className="col-xl-1">
                                    <BsCreditCard2Back />
                                </div>

                                <div className="col-xl-9">
                                    <h2>Fatura</h2>
                                    <p>Pague sua fatura referente ao mês (EM BREVE)</p>
                                </div>
                            </div>
                        </div> */}
                 

                    <Link to={"/pagina-pix"}>
                        <div className="pagamentos">
                            <div className="row">
                                <div className="col-xl-1">
                                    <FaPix/>
                                </div>

                                <div className="col-xl-9">
                                    <h2>Pix</h2>
                                    <p>Pague via Pix</p>
                                </div>
                            </div>
                        </div>
                    </Link>

                    {/* <div className="pagamentos">
                        <div className="row">
                            <div className="col-xl-1">
                                <MdSmartphone />
                            </div>

                            <div className="col-xl-9">
                                <h2>Recarga</h2>
                                <p>Recarregue seu celular (EM BREVE)</p>
                            </div>
                        </div>
                    </div> */}

                    <Link to={"/pagina-pagar-boleto"}>
                        <div className="pagamentos">
                            <div className="row">
                                <div className="col-xl-1">
                                    <BsUpcScan />
                                </div>

                                <div className="col-xl-9">
                                    <h2>Boleto</h2>
                                    <p>Pague via Boleto</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}