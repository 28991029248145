import { Navigate, Outlet } from "react-router-dom";
import { useEffect } from "react";
import { getAccessToken } from "./utils/sessionMethods";

export function Private() {
  const isLogged = !!getAccessToken();

  if (!isLogged) {
    return <Navigate to="/" />;
  }

  useEffect(() => {
    if (!isLogged) {
      return <Navigate to="/" />;
    }
  });

  return <Outlet />;
}
