import styled from 'styled-components';

export const ResumeInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ResumeContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  span {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  > div {
    max-width: 18.75rem;
    width: 100%;
  }
`;