import React, { useEffect, useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import BeatLoader from 'react-spinners/BeatLoader';
import { css } from '@emotion/react';
import ExtratoRow from './extrato-row/ExtratoRow';
import moment from 'moment';
import { useEntrys } from '../../hooks/useEntrys';
import { Fragment } from 'react';
import DescExtrato from './desc-extrato/DescExtrato';
import { PageHeader } from '../page-header';
import {
  EntryContainer,
  EntryDay,
  EntryFilter,
  EntryHeader,
  EntryList,
  EntryMessage,
  EntryMonth,
  EntryRow,
  EntryScroll,
  FilterButton,
  FilterItem,
  LoaderDiv,
} from './styles';
import ExtratoExport from './TemplateExtrato/ExtratoExport';
import {Pagination} from 'antd'
import { Input } from '../Input';

export default function () {
  const [formatedEntries, setFormatedEntries] = useState(null);
  const [descExtrato, setDescExtrato] = useState();
  const [Extrato, setExtrato] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, limit: 50 });
  const [showCustomDateInputs, setShowCustomDateInputs] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    type: '',
    initialDate: '',
    finalDate: '',
  });
  const [showDetails, setShowDetails] = useState(false);

  const { entries, entriesLoader, fetchEntries, countEntries, setCountEntries } = useEntrys();

  moment.updateLocale("pt-br", {
    months: [ "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro", ],
    weekdays: [ "Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado", ],
  });

  const handleInitialDateOnChange = (value) => {
    const initialDate = moment(value).format('YYYY-MM-DD');
    setFilterOptions({
      ...filterOptions,
      initialDate: initialDate,
    });
  };

  const handleFinalDateOnChange = (value) => {
    const finalDate = moment(value).format('YYYY-MM-DD');
    setFilterOptions({
      ...filterOptions,
      finalDate: finalDate,
    });
  };
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-top: 3em;
  `;
  async function onMountedEntrys() {
    const extratos = entries;

    const transacoesAgrupadas = {};
     setExtrato(extratos)
    extratos.forEach((transacao) => {
      const mes = moment(transacao.date).format('YYYY-MM');
      const dia = moment(transacao.date).format('YYYY-MM-DD');

      if (!transacoesAgrupadas[mes]) {
        transacoesAgrupadas[mes] = {};
      }

      if (!transacoesAgrupadas[mes][dia]) {
        transacoesAgrupadas[mes][dia] = [];
      }

      transacoesAgrupadas[mes][dia].push(transacao);
    });

    setFormatedEntries(transacoesAgrupadas);
  }

  const handleOnClick = async (item) => {
    setDescExtrato(item);
    setShowDetails(true);
  };

  const closeDetails = async () => {
    setDescExtrato();
    setShowDetails(false);
    setShowCustomDateInputs(false);
  };

  const handleFilterOptions = (e) => {
    let initialDate;
    let finalDate;

    if (e.target.id == 'date') {
      if (e.target.value) {
        if (e.target.value === 'custom-date') {
          setShowCustomDateInputs(true);
        } else {
          initialDate = moment()
            .subtract(e.target.value, 'days')
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss');
          finalDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');

          setFilterOptions({
            ...filterOptions,
            initialDate,
            finalDate,
          });
          setShowCustomDateInputs(false);
        }
      } else {
        setFilterOptions({
          ...filterOptions,
          initialDate: '',
          finalDate: '',
        });
      }
    }

    if (e.target.id == 'type') {
      if (e.target.value) {
        setFilterOptions({
          ...filterOptions,
          type: e.target.value,
        });
      } else {
        setFilterOptions({
          ...filterOptions,
          type: '',
        });
      }
    }
  };
  async function handleQuery() {
    const { type, initialDate, finalDate } = filterOptions;
    setPagination({ ...pagination, page : 1 });
    await fetchEntries(1, initialDate, finalDate, type).then(() => {
      onMountedEntrys();
    });
  }

  async function handlePageChange(page) {
    setPagination({ ...pagination, page });
    const { type, initialDate, finalDate } = filterOptions;
    await fetchEntries(page, initialDate, finalDate, type).then(() => {
      onMountedEntrys();
    });
  }

  const togglePopup = () => {
    setShowPopup(true);
    setLoader(true);
  };
   const buttonAppers = () => {
   setShowPopup(!showPopup);
   setLoader(false);
  };

  const pages = () => {
    let pagesArr;

    if (showDetails) {
      pagesArr = [
        {
          name: 'Extrato',
          link: '',
          action: closeDetails,
        },
        { name: 'Detalhes', link: '' },
      ];
    } else {
      pagesArr = [{ name: 'Extrato', link: '' }];
    }

    return pagesArr;
  };

  useEffect(() => {
    fetchEntries();
  }, []);

  useEffect(() => {
    onMountedEntrys();
  }, [entries]);

  return (
    <EntryContainer className="pagina-extrato">
      <div className="col-md-10 pagina-extrato-content">
        <EntryHeader>
          <div className="col-xl-12">
            <PageHeader title="Extrato" pages={pages()} />
          </div>
        </EntryHeader>

        {!showDetails ? (
          <>
            <EntryFilter>
              <div className="col-md-12 d-flex align-items-end">
                <div className="col-md-5">
                  <h2>Período</h2>
                  <FilterItem className="filter-item">
                    <select
                      defaultValue={'Selecione'}
                      id="date"
                      onChange={(e) => handleFilterOptions(e)}
                    >
                      <option value={''} className="filter-item-option" hidden>
                        Selecione
                      </option>
                      <option value={7} className="filter-item-option">
                        Últimos 7 dias
                      </option>
                      <option value={15} className="filter-item-option">
                        Últimos 15 dias
                      </option>
                      <option value={30} className="filter-item-option">
                        Últimos 30 dias
                      </option>
                      <option value={60} className="filter-item-option">
                        Últimos 60 dias
                      </option>
                      <option
                        value={'custom-date'}
                        className="filter-item-option"
                      >
                        Escolher Período
                      </option>
                    </select>
                  </FilterItem>
                </div>
                <div className="col-md-5">
                  <h2>Tipo de Transação</h2>
                  <FilterItem className="filter-item">
                    <select
                      defaultValue={'Selecione'}
                      id="type"
                      onChange={(e) => handleFilterOptions(e)}
                    >
                      <option value={''} className="filter-item-option">
                        Selecione
                      </option>
                      <option
                        value={'Pix recebido'}
                        className="filter-item-option"
                      >
                        Pix recebido
                      </option>
                      <option
                        value={'Pix enviado'}
                        className="filter-item-option"
                      >
                        Pix enviado
                      </option>
                      <option
                        value={'Estorno de Pagamento Pix'}
                        className="filter-item-option"
                      >
                        Pix Estornado
                      </option>
                      <option
                        value={'Estorno de Pix'}
                        className="filter-item-option"
                      >
                        Devolução de Pix
                      </option>
                      <option
                        value={'TED recebido'}
                        className="filter-item-option"
                      >
                        TED recebido
                      </option>
                      <option
                        value={'TED enviado'}
                        className="filter-item-option"
                      >
                        TED enviado
                      </option>
                      <option
                        value={'Estorno de Pagamento TED'}
                        className="filter-item-option"
                      >
                        Estorno de Pagamento TED
                      </option>
                      <option
                        value={'Pagamento de boleto'}
                        className="filter-item-option"
                      >
                        Pagamento de boleto
                      </option>
                      <option
                        value={'CreditBoleto'}
                        className="filter-item-option"
                      >
                        Confirmação de pgto de boleto
                      </option>
                      <option
                        value={'Estorno de Pagamento Boleto'}
                        className="filter-item-option"
                      >
                        Estorno de Pagamento Boleto
                      </option>
                    </select>
                  </FilterItem>
                </div>
                <div className="col-md-2">
                  <FilterButton className='btn' onClick={handleQuery}>Buscar</FilterButton>
                </div>
              </div>
              {showCustomDateInputs && (
                <div className="col-md-12">
                  <div className="filter-item-date d-flex flex-row gap-3 col-md-5">
                    <div className="col-md-5">
                      <Input
                        label="Data inicial"
                        type="date"
                        id="initialDate"
                        onChange={handleInitialDateOnChange}
                        hidden={!showCustomDateInputs}
                      />
                    </div>
                    <div className="col-md-5">
                      <Input
                        label="Data final"
                        type="date"
                        id="finalDate"
                        onChange={handleFinalDateOnChange}
                        hidden={!showCustomDateInputs}
                      />
                    </div>
                  </div>
                </div>
              )}
            </EntryFilter>

            <EntryList>
            <Pagination
          onChange={(e) => handlePageChange(e)}
          style={{ marginTop: '2.5em' }}
          pageSize={50}
          showSizeChanger={false}
          defaultCurrent={1}
          total={countEntries}
          current={pagination.page}
        />
              <EntryRow>
                <EntryScroll className="col-xl-12">
                  <EntryRow>
                    {formatedEntries && !entriesLoader && (
                      <>
                        {Object.keys(formatedEntries).map((element, index) => (
                          <Fragment key={index}>
                            <EntryMonth className="col-md-12">
                              <h2>
                                {moment(
                                  Object.keys(formatedEntries[element])[0]
                                ).format('MMMM[,] YYYY')}
                              </h2>
                            </EntryMonth>

                            {Object.keys(formatedEntries[element]).map(
                              (item, index) => (
                                <Fragment key={index}>
                                  <EntryDay className="col-md-12">
                                    <h2>
                                      {moment(
                                        formatedEntries[element][item][0].date
                                      ).format('DD[,] dddd')}
                                    </h2>
                                    <h3>R$</h3>
                                  </EntryDay>
                                  {formatedEntries[element][item].map(
                                    (entry) => (
                                      <ExtratoRow
                                        key={entry.id}
                                        data={entry}
                                        handleClick={handleOnClick}
                                      />
                                    )
                                  )}
                                </Fragment>
                              )
                            )}
                          </Fragment>
                        ))}
                      </>
                    )}
                    {entries.length < 1 && !entriesLoader && (
                      <EntryMessage>Sem resultados!</EntryMessage>
                    )}
                    <LoaderDiv>
                      <BeatLoader
                        color={'var(--secondary-color)'}
                        loading={entriesLoader}
                        css={override}
                        size={20}
                      />
                    </LoaderDiv>
                  </EntryRow>
                </EntryScroll>
              </EntryRow>
            </EntryList>
            <div className="col-md-10 d-flex align-items-end gap-3">
        {(!Loader && !entriesLoader) && <FilterButton className='btn mt-3' onClick={() => togglePopup()}>Exportar</FilterButton>}
      </div>
      {showPopup && <ExtratoExport data={Extrato} onClick={() => buttonAppers()} setLoader={setLoader} setShowPopup={setShowPopup}/>}
          </>
        ) : (
          <DescExtrato data={descExtrato} closeDetails={closeDetails} />
        )}
        <ToastContainer limit={2} />
      </div>
    </EntryContainer>
  );


}
