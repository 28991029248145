import React, {useEffect, useState} from "react";
import { useForm } from 'react-hook-form';
import {departmentForContactformList} from "../../utils/departments";
import { standardEmail } from "../../utils/standardEmail";
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod'
import './style.css';
import { Link } from 'react-router-dom'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { apiV1 } from "../../lib/axios";
import contactInfo from '../../services/contact-info.json'
import { toast } from "react-toastify";

const contactFormSchema = z.object({
  department: z.string().min(1, 'O campo departamento é obrigatório.'),
  subject: z.string().min(1, 'O campo assunto é obrigatório.'),
  message: z.string().min(1, 'O campo mensagem é obrigatório.'),
});

export const ContactFormPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { register, handleSubmit, formState: { errors }, setError } = useForm({
    resolver: zodResolver(contactFormSchema),
  });


  const [receiverEmail, setReceiveEmail] = useState('');

  useEffect(()=>{
    if(receiverEmail === ''){
      const email = getReceiverEmail();
      setReceiveEmail(email);
    }
  },[])

  function getReceiverEmail(){
    const currentChannelEmail = contactInfo.emailSac;
    return currentChannelEmail ? currentChannelEmail : standardEmail;
  }

  function buildFinalData(formData){
    return {receiverEmail: receiverEmail, ...formData}
  }

  const onSubmit = async (formData) => {

    const finalData = buildFinalData(formData);
    setIsLoading(true);

    const response = await sendForm(finalData);

    if (response.data.ok) {
      setIsLoading(false);
      setIsEmailSent(true);
      toast.success('Formulário enviado com sucesso!');
    } else {
      toast.error('Falha ao enviar formulário!');
      setIsLoading(false);
    }
  };

  async function sendForm(data){
    try {
      return await apiV1.post("/attendance/contact_support", data);
    } catch (error) {
      toast.error('Falha ao enviar formulário!');
      console.error("error when send the form: ", error);
    }
  }

  return (
    <div className="page-container">
      <div className="page-content">
        <div className="form-container">
          {isEmailSent ?
            <div>
              <div className="flex just-center align-center mt-10">
                <h2>Formulário enviado com sucesso!</h2>
              </div>
              <div className="flex flex-col mt-4">
                <p className="flex just-center align-center">Em breve nossa equipe lhe retornará!</p>
                <p className="flex just-center align-center">Caso necessite enviar um novo formulário, clique no botão abaixo:</p>
                <div className="button-field mt-4">
                  <button onClick={() => setIsEmailSent(false)}>Enviar novo formulário</button>
                </div>
              </div>
            </div> :
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col just-center">

              <div className="flex just-center align-center mt-10">
                <h2>Formulário de Contato</h2>
              </div>

              <div className="flex flex-col mt-4">
                <label htmlFor="department">Departamento:</label>
                <select id="department" {...register('department')}>
                  {departmentForContactformList.map((department, i) => (
                    <option key={i} selected={department.selected} value={department.value}>{department.label}</option>
                  ))}
                </select>
                {errors.department && <span className="error">{errors.department.message}</span>}
              </div>

              <div className="flex flex-col mt-4">
                <label htmlFor="subject">Assunto:</label>
                <input type="text" id="subject" {...register('subject')} />
                {errors.subject && <span className="error">{errors.subject.message}</span>}
              </div>

              <div className="flex flex-col mt-4">
                <label htmlFor="message">Mensagem:</label>
                <textarea type="text" id="message" {...register('message')} />
                {errors.message && <span className="error">{errors.message.message}</span>}
              </div>

              <div className="button-field mt-4">
                <button type="submit" disabled={isEmailSent}>{isLoading ? 'Enviando...' : 'Enviar'}</button>
              </div>
            </form>}

        </div>
      </div>
    </div>
  );
}
