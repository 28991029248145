import styled from 'styled-components';

import { Button } from '../../button';
import { Link } from 'react-router-dom';

export const LoginContent = styled.div`
  max-width: 68.75rem;
  max-height: 39.75rem;
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  background: ${({ theme }) => theme.colors['bg-color']};
  border: 0;
  border-radius: 12px;

  > img {
    width: 34.375rem;
    height: 39.75rem;

    border-radius: 0 12px 12px 0;
  }
  @media (max-width: 480px) {
    > img {
      display: none;
    }
  max-height: 39.75rem;
  width: 90%;
  height: 100%;

  }
`;

export const LoginFormContainer = styled.div`
  padding: 2rem 2.4375rem 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (max-width: 480px) {
    width: 120%; 
    margin: 0 auto; 
    
    
  }
`;

export const ImageContainer = styled.div`
  height: 10rem;
  width: 90%;
  max-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const NewUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  margin-top: 1.5rem;
`;

export const NewUserContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.875rem;

  div {
    max-width: 27.4375rem;

    h3 {
      font-size: 0.875rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  span {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.danger};
  }
`;

export const LoginButton = styled(Button)`
  background: ${({ theme }) => theme.colors['primary-color']};
  color: ${({ theme }) => theme.colors.white};

  width: 11.25rem;

  &:hover {
    background: ${({ theme }) => theme.colors['secondary-color']};
  }
`;

export const BackButton = styled(Button)`
  background: transparent;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.colors['primary-color']};
  width: 20%;

  color: ${({ theme }) => theme.colors['primary-color']};
`;
export const BackButtonLink = styled(Link)`
  width: 90%;
  margin-top: 2rem;

  @media (max-width: 480px) {
    #backButton{
      width: 50%;
      margin-top: 2rem;}
  
  }
`;
