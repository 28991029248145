import './style.css'
import Asterisk from '../../shared/components/asterisk/asterisk'

export function Select({
  label,
  description,
  placeholder,
  icon,
  value,
  onChange,
  error,
  isRequired,
  disabled,
  register,
  options
}) {
  return (
    <div className="input-container">
      <label htmlFor="">{label} {isRequired && <Asterisk/>}</label>

      {description && <p>{description}</p>}

      <div className="select-with-icon">
        {icon}

        <select
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
          required={isRequired}
          disabled={disabled}
          {...register}
        >
          {options?.map((option, index)=>(
            <option key={index} selected={option.selected}  value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>

      {error && <span>{error}</span>}
    </div>
  )
}
