import { useState } from "react";
import { createContext } from "react";
import { apiV1 } from "../lib/axios";
import moment from "moment";

export const TedContext = createContext({});

export function TedProvider({ children }) {
  const [favorites, setFavorites] = useState([]);
  const [alreadyFavorites, setAlreadyFavorites] = useState(false);
  const [favoritesLoader, setFavoritesLoader] = useState(false);

  const [agencia, setAgencia] = useState("");
  const [conta, setConta] = useState("");
  const [digito, setDigito] = useState("");
  const [tipoConta, setTipoConta] = useState(0);
  const [data, setData] = useState(moment().format("YYYY-MM-DD"));
  const [isFavorite, setisFavorite] = useState();
  const [cpf, setCpf] = useState("");
  const [nome, setNome] = useState("");
  const [banco, setBanco] = useState("");

  async function fetchFavorites() {
    setFavoritesLoader(true);

    await apiV1
      .get("/ted/fitbank/favorites")
      .then((response) => {
        setFavorites(response.data.favorites);
        setFavoritesLoader(false);
      })
      .catch((err) => {

        setFavoritesLoader(false);
        // toast.error
      });
  }

  return (
    <TedContext.Provider
      value={{
        favorites,
        agencia,
        conta,
        digito,
        tipoConta,
        data,
        isFavorite,
        cpf,
        nome,
        banco,
        favoritesLoader,
        alreadyFavorites,
        setFavorites,
        fetchFavorites,
        setAgencia,
        setConta,
        setDigito,
        setTipoConta,
        setData,
        setisFavorite,
        setCpf,
        setNome,
        setBanco,
        setAlreadyFavorites,
      }}
    >
      {children}
    </TedContext.Provider>
  );
}
