import styled from "styled-components";
import { Button } from "../../../components/button";

export const FormContainer = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  span {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.danger};
  }
`;

export const LoginButton = styled(Button)`
  background: ${({ theme }) => theme.colors["primary-color"]};
  color: ${({ theme }) => theme.colors.white};

  width: 11.25rem;

  &:hover {
    background: ${({ theme }) => theme.colors["secondary-color"]};
  }
`;


export const ForgotPasswordLink = styled.div`
  display: flex;
  width: 80%;
  justify-content: center;
  margin-top: 10px;

  & a{
    text-decoration: none;
    color: var(--primary-color);
    font-weight: 600;
    font-size: 14px;
  }
`

export const LoginButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
`
