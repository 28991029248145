import { MdKeyboardArrowRight } from "react-icons/md"
import { LuWallet } from "react-icons/lu"
import { TbFileDollar } from "react-icons/tb"
import { PageHeader } from "../../components/page-header"
import { BoletoItem } from "./components/boleto-item"
import { NavLink } from "react-router-dom"
import { useTicket } from '../../hooks/useTicket'
import { useEffect } from "react"
import { css } from "@emotion/react";
import moment from "moment"
import { BeatLoader } from "react-spinners"
import { formatCnpjCpf } from "../../utils/formatter"

import "./styles.css"

moment.locale('pt-br')

export function PaginaBoleto() {
  const { fetchTickets, boletos, boletosLoader } = useTicket();

  useEffect(() => {
    fetchTickets();
  }, [])

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-top: 3em;
  `;

  return (
    <div className="boleto-container">
      <div className="boleto-header">
        <PageHeader title='Boletos' pages={['Boleto']} />

        <div className="boleto-actions">
          <span>
            Pagar ou Cobrar
            <MdKeyboardArrowRight size={24} />
          </span>

          <div className="actions-buttons">
            <NavLink to='/pagina-pagar-boleto'>
              <button>
                <div className="button-icon">
                  <LuWallet size={45} />
                </div>
                <span>Pagar boleto</span>
              </button>
            </NavLink>

            <NavLink to='/pagina-cobrar-boleto'>
              <button>
                <div className="button-icon button-icon-gray">
                  <TbFileDollar size={45} />
                </div>
                <span>Cobrar com boleto</span>
              </button>
            </NavLink>
          </div>
        </div>
      </div>

      <div className="boleto-content">
        {!boletosLoader ? (
          <>
            <h3>Boletos enviados</h3>

            {boletos.length ? (
              <>
                <div className="boletos-list">
                  {boletos.map((boleto, index) => (
                    <BoletoItem
                      key={index}
                      name={boleto.customerName}
                      date={moment(boleto.createdAt).format('LLL')}
                      cpf={formatCnpjCpf(boleto.customerTaxNumber)}
                      // bank={boleto.bank}
                      value={boleto.totalValue}
                    />
                  ))}
                </div>
              </>
            ) : (
              <span className="empty-boletos">Nenhum resultado encontrado.</span>
            )}
          </>
        ) : (
          <div className="loader-div">
            <BeatLoader
              color={"var(--secondary-color)"}
              loading={boletosLoader}
              css={override}
              size={20}
            />
          </div>
        )}
      </div>
    </div>
  )
}
