import { BoxModal } from '../../../components/boxModal';
import { TbCircleKey } from 'react-icons/tb';
import './styles.css';

export const FeedBackKeyCreated = ({handleCloseModal, keyType }) => {

  const titleMessage = (keytype) => {
    return keytype == KEY_TYPE.RANDOM ? 'Chave Cadastrada!' : 'Chave solicitada com sucesso!'
  }

  const baseMessage = (keyType) => {
    return `Você receberá por ${keyType == KEY_TYPE.PHONE ? 'SMS' : TYPE_KEY_LIST[keyType].name} um código de confirmação para poder prosseguir com o cadastro da sua chave. Em seguida, entre em gerenciar chaves novamente para inserir o código de confirmação.`
  }

  const description = (keytype) => {

    switch (keytype) {
      case KEY_TYPE.MAIL:
        return baseMessage(keytype);
      case KEY_TYPE.PHONE:
        return baseMessage(keytype);
      case KEY_TYPE.RANDOM:
        return 'Agora é só compartilhar sempre que você quiser receber PIX';
      default:
        return 'Agora é só aguardar enquanto fazemos os procedimentos de liberação da sua chave';
    }
  }



  return (

    <BoxModal
            icon='circleCheck'
            title={titleMessage(keyType)}
            description={description(keyType)}
            maxWidth={724}
            close={handleCloseModal}
          >
            <div className='pix-new-key-container'>
              <div className="pix-new-key-info">
                <TbCircleKey size={40} />

                <div className="pix-new-key-content">
                  <strong>Chave Pix do tipo: {TYPE_KEY_LIST[keyType].name}</strong>
                </div>
              </div>

              <span className='pix-new-key-status'>
                {keyType == KEY_TYPE.RANDOM ? 'Chave ativa' : 'Cadastro em andamento'}
              </span>
            </div>
          </BoxModal>
  );
}
// código reaproveitado, necessário criar uma lib de auxilio futuramente
const TYPE_KEY_LIST = [
  {name: 'CPF'},
  {name: 'CNPJ'},
  {name: 'E-mail'},
  {name: 'Telefone'},
  {name: 'Chave aleatória'},
]

const KEY_TYPE = {
  CPF: 0,
  CNPJ: 1,
  MAIL: 2,
  PHONE: 3,
  RANDOM: 4,
}
