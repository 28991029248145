import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Context from './Context';

function DataProvider({ children }) {
  const [receiver, setReceiver] = useState({});
  
  const context = {
    receiver,
    setReceiver
  };

  return (
    <Context.Provider value={ context }>
      {children}
    </Context.Provider>
  );
}

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DataProvider;