const companyTypes = [
  {
    label: 'SA',
    value: 0,
  },
  {
    label: 'LTDA',
    value: 1,
  },
  {
    label: 'MEI',
    value: 2,
  },
  {
    label: 'ME',
    value: 3,
  },
];

export const getCompanyType = (type) => {
  return companyTypes.find((item) => item.value == type).label;
};
