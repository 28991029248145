export const birthState = [
  {
    label: "AC",
    value: "0",
  },
  {
    label: "AL",
    value: "1",
  },
  {
    label: "AP",
    value: "2",
  },
  {
    label: "AM",
    value: "3",
  },
  {
    label: "BA",
    value: "4",
  },
  {
    label: "CE",
    value: "5",
  },
  {
    label: "DF",
    value: "6",
  },
  {
    label: "ES",
    value: "7",
  },
  {
    label: "GO",
    value: "8",
  },
  {
    label: "MA",
    value: "9",
  },
  {
    label: "MT",
    value: "10",
  },
  {
    label: "MS",
    value: "11",
  },
  {
    label: "MG",
    value: "12",
  },
  {
    label: "PA",
    value: "13",
  },
  {
    label: "PB",
    value: "14",
  },
  {
    label: "PR",
    value: "15",
  },
  {
    label: "PE",
    value: "16",
  },
  {
    label: "PI",
    value: "17",
  },
  {
    label: "RJ",
    value: "18",
  },
  {
    label: "RN",
    value: "19",
  },
  {
    label: "RS",
    value: "20",
  },
  {
    label: "RO",
    value: "21",
  },
  {
    label: "RR",
    value: "22",
  },
  {
    label: "SC",
    value: "23",
  },
  {
    label: "SP",
    value: "24",
  },
  {
    label: "SE",
    value: "25",
  },
  {
    label: "TO",
    value: "26",
  },
];

export const getBirthState = (state) => {
  const stateObj = birthState.find((item) => item.label === state);

  return stateObj.value;
};

export const getBithStateLabel = (state) => {
  const stateObj = birthState.find((item) => item.value === state);

  return stateObj.label;
}
