import { Input } from '../../../../components/Input'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Box } from '../../Box'
import { DocumentsFormContent, DocumentsFormInputs, InputContainer, RepresentativeFormContent } from './style'
import { useRegister } from '../../../../hooks/useRegister'

const DocumentsFormSchema = z.object({
  // rgFront: z.any(),
  // rgBack: z.any(),
  cnh: z.any(),
  socialContract: z.any(),
  proofIncome: z.any(),
  proofRegistration: z.any(),
})

export const DocumentsForm = () => {
  const { postDocumentsFormPj, setStep, loading, personType } = useRegister();

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: zodResolver(DocumentsFormSchema)
  })

  const handleNext = async (data) => {
    let validDocuments = true;
    Object.keys(data).map((document)=>{
      const isValid = validateFile(document, data[document].item(0))

      if(!isValid) validDocuments = false;
    })

    if(validDocuments){
      await postDocumentsFormPj(data);
    }
  }

  const validateFile = (name, value) => {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];

    if (!value) {
      setError(name, { type: 'manual', message: 'Por favor, escolha um arquivo.' });
      return false;
    }

    if (!allowedTypes.includes(value.type)) {
      setError(name, { type: 'manual', message: 'Tipo de arquivo não permitido.' });
      return false;
    }

    return true;
  };

  const handleBack = () => {
    setStep(4);
  }

  return (
    <Box
      handleSubmit={handleSubmit}
      submitEvent={handleNext}
      title="Preencha seus com seus documentos."
      description="Para prosseguir precisamos de alguns documentos."
      pointList={[
        'Evite luzes que podem impedir a visibilidade do documento.',
        'Remova acessórios como capas plásticas de proteção.',
        'Rasuras e partes danificadas podem impedir a leitura do documento.',
        'Em caso de documentos digitais é necessário que o mesmo contenha o QR para validação.',
        'Evite documentos como RG digitalizados, bata uma foto legível do documento e o envie.',
        'Evite mandar frente e verso juntos no mesmo arquivo.',
        'Verifique a validade do seu documento de RG, aceitamos o documento com até 10 anos.',
        'Em caso de CNH, aceitamos em formato digital ou com o documento aberto, mostrando frente e verso',
      ]}
      buttonOne="Voltar"
      buttonTwo="Finalizar"
      buttonTwoLoading="Finalizando..."
      marginLeft="l"
      handleBack={handleBack}
    >
      <DocumentsFormInputs>
        <DocumentsFormContent>

          <InputContainer>
            {/* <Input
              type="file"
              accept="image/*,application/pdf"
              label="CNH"
              error={errors.cnh && errors.cnh.message}
              isRequired
              disabled={loading}
              register={register('cnh', {
                required: true,
              })}
            /> */}

            {/* <Input
              type="file"
              accept="image/jpeg"
              label="RG Verso"
              error={errors.rgBack && errors.rgBack.message}
              isRequired
              disabled={loading}
              register={register('rgBack', {
                required: true,
              })}
            /> */}

            {(personType !== 'MEI' && personType !== 'ME') &&
              <Input
              type="file"
              accept=".jpg, .jpeg, .png, .pdf"
              label="Contrato Social"
              error={errors.socialContract && errors.socialContract.message}
              isRequired
              disabled={loading}
              register={register('socialContract', {
                required: true,
              })}
              />
            }

            {(personType !== 'MEI' && personType !== 'ME') &&
              <Input
                type="file"
                accept=".jpg, .jpeg, .png, .pdf"
                label="Comprovante de Renda"
                error={errors.proofIncome && errors.proofIncome.message}
                isRequired
                disabled={loading}
                register={register('proofIncome', {
                  required: true,
                })}
              />
            }

            <Input
              type="file"
              accept=".jpg, .jpeg, .png, .pdf"
              label="Comprovante de Inscrição ou Situação Cadastral"
              error={errors.proofRegistration && errors.proofRegistration.message}
              isRequired
              disabled={loading}
              register={register('proofRegistration', {
                required: true,
              })}
            />
          </InputContainer>
        </DocumentsFormContent>
      </DocumentsFormInputs>
    </Box>
  )
}
