export const departmentForContactformList = [
  { label: "Selecione...", value: "", disabled: true },
  { label: "Conta Corrente", value: "contaCorrente" },
  { label: "Segurança e Fraude", value: "segurancaFraude" },
  { label: "Falar com meu gerente", value: "falarGerente" },
  { label: "Ouvidoria", value: "ouvidoria" },
  { label: "Reclamação", value: "reclamacao" },
  { label: "SAC - Serviço de Atendimento ao Cidadão", value: "sac" },

]
