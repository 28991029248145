import './styles.css'
import circleCheckIcon from '../../assets/image/circle-check.svg'
import boletoEnviadoIcon from '../../assets/image/boleto-enviado.svg'

const icons = {
  circleCheck: circleCheckIcon,
  boletoEnviado: boletoEnviadoIcon,
}

export function Box({ children ,icon, title, description, className }) {
  return (
    <div className={`box-container ${className}`}>
      <div className='box-header'>
        {icon && <img src={icons[icon]} alt="" />}
        <h2>{title}</h2>
        {description && <p>{description}</p>}
      </div>

      {children}
    </div>
  )
}