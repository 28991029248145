import { MdKeyboardArrowRight } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { Fragment } from "react";

import "./styles.css"

export function PageHeader({ title, pages }) {
  return (
    <div className="page-header-container">
      <nav className="page-header-nav">
        <NavLink to={'/pagina-principal'}>
          Página inicial
        </NavLink>
        {pages.map((page, index) => (
          <Fragment key={index}>
            <MdKeyboardArrowRight size={16} className="arrow-icon" />
            {index === pages.length - 1 
              ? (
                <span>{page.name}</span>
              ) 
              : (
                <NavLink to={page.link} onClick={page.action}>{page.name}</NavLink>
              )}
          </Fragment>
        ))}
      </nav>

      <span>{title}</span>
    </div>
  )
}
