import { Input } from '../../../../components/Input';
import { formatCnpj } from '../../../../utils/formatter';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Box } from '../../Box';
import { useNavigate } from 'react-router-dom';
import { useRegister } from '../../../../hooks/useRegister';
import { validarCPF } from '../../../../services/validarCPF';
import { calcularIdade } from '../../../../services/calcularIdade';

import {
  InputContainer,
  PersonDataFormContent,
  PersonDataFormInputs,
} from './style';
import moment from 'moment';

const PersonDataFormSchema = z.object({
  cpf: z
    .string()
    .min(1, 'O campo CPF é obrigatório.')
    .max(18, 'O campo CPF aceita no máximo 11 digitos.')
    .refine((value) => validarCPF(value), {
      message: 'CPF inválido',
    }),
  name: z.string().regex(
    /^[a-zA-Z\u00C0-\u017F.]+\s[a-zA-Z\u00C0-\u017F.]+\s*([a-zA-Z\u00C0-\u017F.]+\s*)*$/,
    {
      message: 'Digite pelo menos dois nomes separados por espaço',
    }
  ),
  birthDate: z.string().refine((value) => {
    const dataNascimento = new Date(value);
    const idade = calcularIdade(dataNascimento);

    return (idade >= 18 && idade <= 110);
  }, { message: 'A data de nascimento não é válida' }),
  motherName: z.string().regex(
    /^[a-zA-Z\u00C0-\u017F.]+\s[a-zA-Z\u00C0-\u017F.]+\s*([a-zA-Z\u00C0-\u017F.]+\s*)*$/,
    {
      message: 'Digite pelo menos dois nomes separados por espaço',
    }
  ),
});

export const PersonDataForm = () => {
  const { postPersonDataPf, personPf } = useRegister();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cpf: personPf.cpf ?? '',
      name: personPf.name ?? '',
      birthDate: personPf.birthDate ?? '',
      motherName: personPf.motherName ?? '',
    },
    resolver: zodResolver(PersonDataFormSchema),
  });

  const handleNext = async (data) => {
    postPersonDataPf(data);
  };

  const handleBack = () => {
    navigate('/');
  };

  return (
    <Box
      handleSubmit={handleSubmit}
      submitEvent={handleNext}
      title="Preencha seus dados"
      description="Complete os campos essenciais para iniciar o processo de abertura de conta pessoal"
      buttonOne="Cancelar"
      buttonTwo="Prosseguir"
      marginLeft="l"
      handleBack={handleBack}
    >
      <PersonDataFormInputs>
        <PersonDataFormContent>
          <span>Dados Pessoais</span>

          <InputContainer>
            <Input
              type="mask"
              label="CPF"
              placeholder="000.000.000-00"
              mask="999.999.999-99"
              error={errors.cpf && errors.cpf.message}
              isRequired
              register={register('cpf', {
                required: true,
              })}
            />

            <Input
              type="text"
              label="Nome Completo"
              error={errors.name && errors.name.message}
              isRequired
              register={register('name', {
                required: true,
                onChange: (e) => e.target.value = e.target.value.replace(/[^a-zA-ZÀ-ÿ\s]/g, '')
              })}
            />

            <Input
              type="text"
              label="Nome Completo da Mãe"
              error={errors.motherName && errors.motherName.message}
              isRequired
              register={register('motherName', {
                required: true,
                onChange: (e) => e.target.value = e.target.value.replace(/[^a-zA-ZÀ-ÿ\s]/g, '')
              })}
            />

            <Input
              type="date"
              label="Data de Nascimento"
              max={moment(new Date()).format("YYYY-MM-DD")}
              error={errors.birthDate && errors.birthDate.message}
              isRequired
              register={register('birthDate', {
                required: true,
              })}
            />
          </InputContainer>
        </PersonDataFormContent>
      </PersonDataFormInputs>
    </Box>
  );
};
