import { Input } from '../../../../components/Input'
import { useRegister } from '../../../../hooks/useRegister'
import { InputContainer, ResumeContent, ResumeInputs } from './styles'
import { BoxDefault } from '../../BoxDefault'
import { ConfirmModal } from './ConfirmModal'
import { useState } from 'react'
import { getBithStateLabel } from '../../../../utils/birthState'

export const ResumePf = () => {
  const [ConfirmResumeModal, setConfirmResumeModal] = useState(false);

  const { 
    setStep,
    personPf, 
    contactPf, 
    addressPf, 
    registerPf
  } = useRegister()

  const handleNext = () => {
    setConfirmResumeModal(true)
  }

  const handleBack = () => {
    setStep(4)
  }

  const handleCloseModal = () => {
    setConfirmResumeModal(false)
  }

  const handleConfirm = async () => {
    await registerPf()

    handleCloseModal()
  }

  return (
    <BoxDefault
      submitEvent={handleNext}
      title="Resumo dos dados"
      description="Validade seus dados antes de concluir, caso exista alguma divergência de dados sua conta estará sujeita a reprovação."
      buttonOne="Voltar"
      buttonTwo="Concluir"
      buttonTwoLoading="Carregando..."
      marginLeft="l"
      handleBack={handleBack}
    >
      <ResumeInputs>
        <ResumeContent>
          <span>Dados Pessoais</span>

          <InputContainer>
            <Input
              type="text"
              label="CPF"
              value={personPf.cpf}
              disabled
            />
            <Input
              type="text"
              label="Nome"
              value={personPf.name}
              disabled
            />
            <Input
              type="date"
              label="Data de Nascimento"
              value={personPf.birthDate}
              disabled
            />
            <Input
              type="text"
              label="Nome da Mãe"
              value={personPf.motherName}
              disabled
            />
          </InputContainer>
        </ResumeContent>

        <ResumeContent>
          <span>Dados de Contato</span>

          <InputContainer>
            <Input
              type="text"
              label="E-mail"
              value={contactPf.email}
              disabled
            />
            <Input
              type="text"
              label="Telefone"
              value={contactPf.phone}
              disabled
            />
          </InputContainer>
        </ResumeContent>

        <ResumeContent>
          <span>Dados de Endereço</span>

          <InputContainer>
            <Input
              type="text"
              label="CEP"
              value={addressPf.cep}
              disabled
            />
            <Input
              type="text"
              label="Rua"
              value={addressPf.street}
              disabled
            />
            <Input
              type="text"
              label="Número"
              value={addressPf.number}
              disabled
            />
            <Input
              type="text"
              label="Bairro"
              value={addressPf.neighborhood}
              disabled
            />
            <Input
              type="text"
              label="Cidade"
              value={addressPf.city}
              disabled
            />
            <Input
              type="text"
              label="Estado"
              value={getBithStateLabel(addressPf.state)}
              disabled
            />
            <Input
              type="text"
              label="Complemento"
              value={addressPf.complement}
              disabled
            />
          </InputContainer>
        </ResumeContent>
      </ResumeInputs>

      <ConfirmModal
        closeModal={handleCloseModal}
        isOpen={ConfirmResumeModal}
        setConfirmResumeModal={setConfirmResumeModal}
        event={handleConfirm}
      />
    </BoxDefault>
  )
}
