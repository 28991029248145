import { BoxModal } from '../../../components/boxModal';
import './styles.css';
import { useState, useRef } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form';

import { SendConfirmationCode, ResendConfirmationCodeToUser } from '../../../services/pix.service';
import { ResendConfirmationCode } from './ResendConfirmationCode';
import { toast } from 'react-toastify';


const confirmationCodeFormSchema = z.object({
  confirmationCode: z.string().min(1, 'O campo código de confirmação é obrigatório.'),
});

export const ValidateConfirmationCode = ({handleCloseModal, typeKey, startPuller}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationCodeSuccessfullySent, setConfirmationCodeSuccessfullySent] = useState(false);
  const resendConfirmationcodeRef = useRef(null);
  const { register, handleSubmit, formState: { errors }, setError } = useForm({
    resolver: zodResolver(confirmationCodeFormSchema),
  });

  const onSubmit = async ({confirmationCode}) => {
    try{
      setHasError(false);
      setErrorMessage(null);
      setIsLoading(true);
      await sendConfirmationCode(confirmationCode);
      setIsLoading(false);
      setConfirmationCodeSuccessfullySent(true);
      //startPuller();
    } catch(error) {
      setErrorMessage(error.response.data.message);
      setIsLoading(false);
      setHasError(true);
      toast.error('Erro ao enviar código de confirmação');
    }
  };
  async function resendConfirmationCode() {
    try {
      await ResendConfirmationCodeToUser(typeKey);
    } catch (error) {
      toast.error('Erro ao reenviar código de confirmação');
      resendConfirmationcodeRef.current.StopTimer();
      console.error("Erro ao reenviar código de confirmação: ", error);
    }
  }
  async function sendConfirmationCode(confirmationCode){
    try {
      await SendConfirmationCode(typeKey, confirmationCode);
    } catch (error) {
      console.error("Erro ao enviar código de confirmação: ", error.response.data.message);
      throw error;
    }
  }


  return (
    <>
      {!confirmationCodeSuccessfullySent && (
        <BoxModal
          title='Código de confirmação'
          description={`Insira abaixo o código de confirmação recebido por ${TYPE_KEY_LIST[typeKey].name}.`}
          maxWidth={724}
          close={handleCloseModal}
        >
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col just-center">
            <div className="confirmation-code-container">
              <label htmlFor="confirmationCode">Código de confirmação:</label>
              <input type="text" id="confirmationCode" {...register('confirmationCode')} />
              {errors.confirmationCode && <span className="error">{errors.confirmationCode.message}</span>}
              {hasError && <span className="validation-code-error">{errorMessage}</span>}
            </div>

            <div className="button-send-code-container">
              <button type="submit" disabled={isLoading}>{isLoading ? 'Enviando...' : 'Enviar'}</button>
            </div>
          </form>
          <ResendConfirmationCode ref={resendConfirmationcodeRef} onClick={resendConfirmationCode}></ResendConfirmationCode>
        </BoxModal>
      )}
      {confirmationCodeSuccessfullySent && (
        <BoxModal
          title='Código de confirmação enviado com sucesso!'
          description='Agora é só aguardar enquanto fazemos os procedimentos de liberação da sua chave!'
          maxWidth={724}
          close={handleCloseModal}
        >
        </BoxModal>
      )}
    </>
  );
}

const TYPE_KEY_LIST = [
  {name: 'CPF'},
  {name: 'CNPJ'},
  {name: 'E-mail'},
  {name: 'SMS'},
  {name: 'Chave aleatória'},
]
