import { BsX } from 'react-icons/bs'
import circleCheckIcon from '../../assets/image/circle-check.svg'
import { useState, useEffect } from 'react';

import './styles.css'
const icons = {
  circleCheck: circleCheckIcon,
}
export function BoxModal({ children, title, description, icon, maxWidth, maxHeight, close }) {
  return (
    <div 
      className="box-modal-container" 
      style={{ 
        maxWidth: `${maxWidth}px`, 
        maxHeight: maxHeight ? `${maxHeight}px` : 'auto', 
        width: '100%'
      }}
    >
      <div className='box-modal-header'>
        <button className='close-keys-modal' onClick={close}><BsX size={32} /></button>
        {icon && <img src={icons[icon]} alt="" />}
        <h2 className='box-modal-title'>{title}</h2>
        {description && <p>{description}</p>}
      </div>
      {children}
    </div>
  )
}