import styled from "styled-components";

export const Button = styled.button`
  all: unset;
  padding: 0.5rem 0;

  border: 0;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  font-size: 1rem;
  font-weight: 600;
  
  &:disabled {
    opacity: 1;
    background: ${({ theme }) => theme.colors["disabled-color"]};
    cursor: progress;
  }
`