import React from "react";
import MenuLateral from "../menu-lateral/MenuLateral";
import './Style.css'
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";

const AppLayout = ({ children }) => {
  const { user, getProfile } = useAuth()

  useEffect(() => {
    if (!user.id) {
      getProfile()
    }
  }, [user])

  return (
    <>
      <MenuLateral />
      <div className="app-layout">
        <Outlet />
      </div>
    </>
  );
};

export default AppLayout;
