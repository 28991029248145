import React, { useEffect, useCallback } from 'react';
import { useState } from 'react';
import { TbEye, TbEyeOff } from 'react-icons/tb';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useAccount } from '../../hooks/useAccount';
import { getBankCode } from '../../utils';
import { css } from '@emotion/react';
import { apiV1 } from '../../lib/axios';
import BeatLoader from 'react-spinners/BeatLoader';
import { useAuth } from '../../hooks/useAuth';
import { useTed } from '../../hooks/useTed';
import './Style.css';
import { formatValueToMonetary } from '../../utils/formatter';
import { LiaMoneyBillSolid } from 'react-icons/lia';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { BsThreeDots } from 'react-icons/bs';

const PaginaInicial = () => {
  const { isLogged, getProfile, user } = useAuth();
  const { balance, avaliableBalance, blockedBalance, getBalance } =
    useAccount();
  const { setAlreadyFavorites } = useTed();
  const { isLoading, setIsloading } = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [mostraSaldo, SetMostraSaldo] = useState(false);
  const [transferenciasRecentes, setTransferenciasRecentes] = useState([]);
  const [isBalanceVisible, setIsBalanceVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const override = css`
    display: flex;
    margin: 0 auto;
    border-color: red;
  `;

  async function getTransferenciasRecentes() {
    try {
      const response = await apiV1.get('/transfer/recents');
      setTransferenciasRecentes(response.data.elements);
    } catch (error) {
      console.log(error);
    }
  }

  const getNamedFirstChar = useCallback((name) => {
    const [firstName = '', lastName = ''] = name.split(' ');
    return (
      firstName?.charAt(0)?.toUpperCase() +
        lastName?.charAt(0)?.toUpperCase() || ''
    );
  }, []);

  useEffect(() => {
    if (isLogged) {
      getProfile();
    }
  }, [isLogged]);

  useEffect(() => {
    getTransferenciasRecentes();
  }, []);

  useEffect(() => {
    if (user.account) {
      getBalance();
    }
  }, [user]);

  const toggleBalanceVisibility = () => {
    setIsBalanceVisible(!isBalanceVisible);
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const toggleExpansion = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className="pagina-principal">
      <div className="header">
    <div className="col-md-5">
        <h1>Conta Corrente</h1>
    </div>
    <div className="col-md-5">
        <span>Conta corrente</span>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <p className="balance-text">
                {isBalanceVisible ? (
                    formatValueToMonetary(balance)
                ) : (
                    <>
                        R$: <BsThreeDots size={30} />
                    </>
                )}
            </p>
            {isBalanceVisible ? (
                <TbEyeOff
                    size={24}
                    onClick={toggleBalanceVisibility}
                    className="toggle-balance-icon"
                />
            ) : (
                <TbEye
                    size={24}
                    onClick={toggleBalanceVisibility}
                    className="toggle-balance-icon"
                />
            )}
        </div>
        <div className="d-flex">
            <div onClick={toggleVisibility} style={{ cursor: 'pointer' }}>
                {isVisible ? (
                    isLoading ? (
                        <BeatLoader
                            color="var(--text-primary)"
                            loading={isLoading}
                            size={14}
                        />
                    ) : (
                      <>
                      <div className="container">
                          <div className="empty-space"></div>
                          <div className="balance-section">
                              <p>Disponível</p>
                              <label className="details-available">
                                  {isBalanceVisible ? (
                                      formatValueToMonetary(avaliableBalance)
                                  ) : (
                                      <span className="balance-placeholder">
                                          <BsThreeDots />
                                      </span>
                                  )}
                              </label>
                          </div>
                          <div className="balance-section">
                              <p>Bloqueado</p>
                              <label className="details-blocked">
                                  {isBalanceVisible ? (
                                      formatValueToMonetary(blockedBalance)
                                  ) : (
                                      <span className="balance-placeholder">
                                          <BsThreeDots />
                                      </span>
                                  )}
                              </label>
                          </div>
                      </div>
                      <span className="details-toggle">
                          Ocultar detalhes <MdKeyboardArrowUp size={20} />
                      </span>
                  </>
                  
                    )
                ) : (
                    <span
                        style={{
                            fontSize: '12px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            color: 'var(--recent-icons-background)',
                        }}
                    >
                        Saldo detalhado <MdKeyboardArrowDown size={20} />
                    </span>
                )}
            </div>
        </div>
    </div>
</div>
      <div
        className="body"
        style={{
          transition: 'margin-top 0.6s ease',
          marginTop: isVisible ? '10px' : '0px',
        }}
      >
        <div className="d-flex col-md-10">
          <div className="col-md-6">
            <h1>
              Transferências <MdKeyboardArrowRight />
            </h1>
          </div>
          <div className="d-flex col-md-6">
            <Link to={'/pagina-pix'}>
              <div className="svg-circle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M8.62736 9.99716C8.775 9.84952 9.02926 9.84952 9.17691 9.99716L11.2821 12.1024C11.6704 12.4906 12.1871 12.7039 12.7339 12.7039H13.1468L10.492 15.3587C9.66357 16.1652 8.31841 16.1652 7.48998 15.3587L4.82426 12.6957H5.07853C5.62534 12.6957 6.14208 12.4824 6.53032 12.0942L8.62736 9.99716ZM9.17691 7.98488C9.00192 8.13525 8.77773 8.13799 8.62736 7.98488L6.53032 5.88784C6.14208 5.475 5.62534 5.28635 5.07853 5.28635H4.82426L7.48725 2.62226C8.31841 1.79258 9.66357 1.79258 10.492 2.62226L13.1495 5.27814H12.7339C12.1871 5.27814 11.6704 5.4914 11.2821 5.87964L9.17691 7.98488ZM5.07853 5.90151C5.45583 5.90151 5.80306 6.05462 6.09287 6.32256L8.1899 8.4196C8.38676 8.59184 8.64376 8.71488 8.9035 8.71488C9.1605 8.71488 9.4175 8.59184 9.61436 8.4196L11.7196 6.31436C11.9875 6.04915 12.3566 5.89604 12.7339 5.89604H13.7647L15.3587 7.49001C16.1871 8.31844 16.1871 9.6636 15.3587 10.492L13.7647 12.086H12.7339C12.3566 12.086 11.9875 11.9329 11.7196 11.6649L9.61436 9.55971C9.23432 9.17967 8.56994 9.17967 8.1899 9.56244L6.09287 11.6567C5.80306 11.9247 5.45583 12.0778 5.07853 12.0778H4.20854L2.62223 10.492C1.79255 9.6636 1.79255 8.31844 2.62223 7.49001L4.20854 5.90151H5.07853Z"
                    fill="var(--bg-circle-text-color)"
                  />
                </svg>
              </div>
              <p>PIX</p>
            </Link>
            <Link to={'/pagina-ted'}>
              <div className="svg-circle">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.63181 2.44714C7.93715 2.72703 7.95777 3.20146 7.67788 3.5068L4.70494 6.75H15C15.4142 6.75 15.75 7.08579 15.75 7.5C15.75 7.91422 15.4142 8.25 15 8.25H3.00002C2.70259 8.25 2.43327 8.07425 2.3135 7.802C2.19374 7.52975 2.24617 7.21246 2.44715 6.99321L6.57215 2.49321C6.85204 2.18787 7.32647 2.16724 7.63181 2.44714ZM2.25002 10.5C2.25002 10.0858 2.5858 9.75 3.00002 9.75H15C15.2974 9.75 15.5668 9.92576 15.6865 10.198C15.8063 10.4703 15.7539 10.7875 15.5529 11.0068L11.4279 15.5068C11.148 15.8121 10.6736 15.8328 10.3682 15.5529C10.0629 15.273 10.0423 14.7986 10.3221 14.4932L13.2951 11.25H3.00002C2.5858 11.25 2.25002 10.9142 2.25002 10.5Z"
                    fill="var(--bg-circle-text-color)"
                  />
                </svg>
              </div>
              <p>TED</p>
            </Link>
            <Link to={'/pagina-echeque'}>
              <div className="svg-circle">
                <LiaMoneyBillSolid size={40} />
              </div>
              <p>e-Check</p>
            </Link>
          </div>
        </div>
        {transferenciasRecentes.length && (
          <div className="col-md-10">
            <h2>Tranferências Recentes</h2>
            <div className="d-flex">
              {transferenciasRecentes.map((item) => {
                return (
                  <div className="recent-card" key={item.id}>
                    {item.pix ? (
                      <Link
                        state={{
                          pixKey: item?.toPixKey,
                        }}
                        to={{ pathname: '/pagina-pagar-pix' }}
                        key={item.id}
                      >
                        <div className="recent-icons">
                          {getNamedFirstChar(item.toName)}
                        </div>
                        <div className="recent-content">
                          <p>
                            {item.toName.split(' ').length > 1
                              ? item.toName.split(' ')[0] +
                                ' ' +
                                item.toName.split(' ')[1]
                              : item.toName.split(' ')[0].split('')[0]}
                          </p>
                          <span>{getBankCode(item.toBank) || ''}</span>
                        </div>
                      </Link>
                    ) : (
                      <Link
                        onClick={() => setAlreadyFavorites(false)}
                        state={{
                          idTransfer: item?.id || undefined,
                        }}
                        to={{ pathname: '/pagina-transferencia' }}
                        key={item.id}
                      >
                        <div className="recent-icons">
                          {getNamedFirstChar(item.toName)}
                        </div>
                        <div className="recent-content">
                          <p>{item.toName}</p>
                          <span>{getBankCode(item.toBank) || ''}</span>
                        </div>
                      </Link>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="footer">
        <div className="col-md-10">
          <h1>Descubra mais</h1>
        </div>
        <div className="d-flex col-md-10">
          <Link to={'/contato'}>
            <div className="card-footer ">
              <img src="images/contato.png" />
              <h3>Contato</h3>
              <p>Precisando resolver alguma coisa, fale conosco</p>
            </div>
          </Link>

          {/* <Link to={'/perguntas-frequentes'}>
            <div className='card-footer'>
              <img src='images/perguntas-frequentes.png'/>
              <h3>Perguntas Frequentes</h3>
              <p>Queremos responder as suas dúvidas com asssertividade</p>
            </div>
          </Link> */}

          <a href={process.env.REACT_APP_COMPANY_SITE} target="_blank">
            <div className="card-footer">
              <img src="images/pagina-corporativa.png" />
              <h3>Página Corporativa</h3>
              <p>Quem somos, nossa visão e propósito</p>
            </div>
          </a>
        </div>
      </div>
      <ToastContainer limit={2} />
    </div>
  );
};

export default PaginaInicial;
