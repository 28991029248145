import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0px;
    padding: 0px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
  }

  body {
    background: var(--bg-color);
    color: var(--text-primary);
  }

  body::-webkit-scrollbar {
    width: 0.3rem;
  }

  body::-webkit-scrollbar-thumb {
    background-color: var(--gray-300);   
    border-radius: 20px;  
  }

  html, body, #root{
    height: 100%;
  }
`;
