import { Input } from '../../../../components/Input';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Box } from '../../Box';
import { AddressFormContent, DoubleInput, InputContainer, Select, StateInput } from './style';
import { getAddressByCep } from '../../../../utils/getAddressByCep';
import { useRegister } from '../../../../hooks/useRegister';
import { useState } from 'react';
import { birthState, getBirthState } from '../../../../utils/birthState';
import { Select as SelectComponent}  from '../../../../components/select'

const CampanyDataFormSchema = z.object({
  cep: z
    .string()
    .max(9, 'O campo CEP aceita no máximo 8 digitos.')
    .refine((value) => {
      const cep = value.replace(/\D/g, '')

      if (cep.length === 8) {
        return true
      }

      return false
    }, { message: "CEP Inválido." }),
  street: z.string(),
  number: z.coerce.string().regex(/^\d+$/, {
    message: 'Este campo deve conter apenas números.',
  }),
  complement: z.string(),
  neighborhood: z.string(),
  city: z.string(),
  state: z.string(),
  ddd: z.string().optional(),
});

export const AddressForm = () => {
  const { postAddressFormPj, setStep, addressPj } = useRegister();
  const [disabledAddressInputs, setDisabledAddressInputs] = useState({
    street: false,
    city: false,
    state: false,
    neighborhood: false,
  });

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(CampanyDataFormSchema),
    defaultValues: {
      cep: addressPj.cep ?? '',
      street: addressPj.street ?? '',
      number: addressPj.number ?? '',
      complement: addressPj.complement ?? '',
      neighborhood: addressPj.neighborhood ?? '',
      city: addressPj.city ?? '',
      state: addressPj.state ?? '',
      ddd: addressPj.ddd ?? '',
    },
  });

  const handleNext = (data) => {
    postAddressFormPj(data);
  };

  const handleBack = () => {
    setStep(1);
  };

  const setAddress = async (cep) => {
    const unformattedCep = cep.replace(/\D/g, '');

    if (unformattedCep.length === 8) {
      searchAddress(unformattedCep)
        .then((address) => {
          setDisabledAddressInputs({
            street: true,
            city: true,
            state: true,
            neighborhood: true,
          });
          setValue('street', address.logradouro);
          setValue('city', address.localidade);
          setValue('state', getBirthState(address.uf));
          setValue('neighborhood', address.bairro);
          setValue('ddd', address.ddd)
          setDisabledAddressInputs({
            street: address.logradouro,
            city: address.localidade,
            state: address.uf,
            neighborhood: address.bairro,
          });
        })
        .catch((err) => {
          setDisabledAddressInputs({
            street: false,
            city: false,
            state: false,
            neighborhood: false,
          });
          setValue('street', '');
          setValue('city', '');
          setValue('state', '');
          setValue('neighborhood', '');
          setError('cep', 'Cep inválido')
        });
    }
  };

  const searchAddress = async (unformattedCep) => {
    const address = await getAddressByCep(unformattedCep);
    if (address.erro) {
      throw new Error('Não foi possível encontrar o endereço');
    }
    return address;
  };

  return (
    <Box
      handleSubmit={handleSubmit}
      submitEvent={handleNext}
      title="Dados de Endereço"
      description="Informe as Informações Precisas para Registro do seu endereço"
      buttonOne="Voltar"
      buttonTwo="Prosseguir"
      marginLeft="m"
      handleBack={handleBack}
    >
      <AddressFormContent>
        <InputContainer>
          <Input
            type="mask"
            label="CEP"
            placeholder="00000-000"
            mask="99999-999"
            error={errors.cep && errors.cep.message}
            isRequired
            register={register('cep', {
              required: true,
              onChange: async (e) => await setAddress(e.target.value),
            })}
          />

          <Input
            type="text"
            label="Rua"
            error={errors.street && errors.street.message}
            isRequired
            disabled={disabledAddressInputs.street}
            register={register('street', {
              required: true,
            })}
          />

          <DoubleInput>
            <Input
              type="text"
              label="Número"
              error={errors.number && errors.number.message}
              isRequired
              onlyNumber
              register={register('number', {
                required: true,
                valueAsNumber: true,
                onChange: (e) => e.target.value = e.target.value.replace(/\D/g, '')
              })}
            />

            <Input
              type="text"
              label="Complemento"
              error={errors.complement && errors.complement.message}
              isRequired
              register={register('complement')}
            />
          </DoubleInput>

          <Input
            type="text"
            label="Cidade"
            error={errors.city && errors.city.message}
            isRequired
            disabled={disabledAddressInputs.city}
            register={register('city', {
              required: true,
            })}
          />

          <SelectComponent
            label="Estado"
            isRequired
            defaultValue={'default'}
            disabled={disabledAddressInputs.state}
            register={register(`state`, {
              required: true,
            })}
            options={birthState}
            error={errors.state && errors.state.message}
          />

          <Input
            type="text"
            label="Bairro"
            error={errors.neighborhood && errors.neighborhood.message}
            isRequired
            disabled={disabledAddressInputs.neighborhood}
            register={register('neighborhood', {
              required: true,
            })}
          />
        </InputContainer>
      </AddressFormContent>
    </Box>
  );
};
