export const fileToBase64 = (arquivo) => {
  return new Promise((resolve, reject) => {
    if (!arquivo) {
      reject(new Error("Nenhum arquivo fornecido."));
      return;
    }

    const leitor = new FileReader();

    // Define a função de callback para quando a leitura for concluída
    leitor.onload = function (evento) {
      // O conteúdo do arquivo em base64 estará em event.target.result
      const conteudoBase64 = evento.target.result;

      // Resolve a Promise com a string base64
      resolve(conteudoBase64);
    };

    // Define a função de callback para tratamento de erro
    leitor.onerror = function (evento) {
      reject(new Error("Erro ao ler o arquivo."));
    };

    // Lê o conteúdo do arquivo como uma URL de dados (base64)
    leitor.readAsDataURL(arquivo);
  });
};
