import React from "react";

import "./Style.css";

import { entryTypes } from "../../../services/enums";
import moment from "moment";
import { MdKeyboardArrowRight } from "react-icons/md";
import { getBankCode } from "../../../utils";

export default function (props) {
  const displayType = entryTypes.filter((type) => type.key === props.data.description)[0]?.displayValue ?? props.data.details;

function filterType(type) {
  if (type  ===  "e-Check compensado" && props.data.details) {
    return `${type} - ${props.data.details}`;
  }
  return type;
}

  const hideTaxNumber = (taxNumber) => {
    if(taxNumber){
        let originalTaxNumber = taxNumber;
        let formattedTaxNumber;
        if(originalTaxNumber.length === 14){
            formattedTaxNumber = `CNPJ ** *** ${originalTaxNumber.slice(5,8)} /**** - ${originalTaxNumber.slice(-2)}`;
        } else if(originalTaxNumber.length === 11){
            formattedTaxNumber = `CPF *** *** ${originalTaxNumber.slice(6,9)} - ${originalTaxNumber.slice(-2)}`
        }

        return formattedTaxNumber
    }
    return "CPF/CNPJ não informado"
  }
  return (
    <div className="extrato-item">
      <div className="col-xl-1">
        <div className="circle"></div>
        <div className="column"></div>
      </div>
      <div className="col-xl-8">
        <div>
          <h2>{filterType(displayType)}</h2>
          <p>{moment(props.data.date).format("HH:mm")}</p>
          {/* <p>{props.data.receiverName || "Nome não informado"}</p> */}
          <p>{props.data.typeEnum === 'Recebido' ? props.data.payerName : props.data.receiverName || "Nome não informado"}</p>
        </div>
      </div>
      <div className="col-xl-3">
        <div className="value-area">
          <h2
            style={
              parseFloat(props.data.value) < 0
                ? { color: "#DC3545" }
                : { color: "#3D8BFD" }
            }
          >
            {String(props.data.value).replaceAll('.', ',')}
          </h2>
          <MdKeyboardArrowRight size={32} onClick={()=> props.handleClick(props.data)} />
        </div>
        <div className="info-area">
          {props.data.typeEnum === 'Recebido' ?
            <>
              <p>{ hideTaxNumber(props.data.payerTaxNumber) }</p>
              <p>{ getBankCode(props.data.payerBank) }</p>
            </>
            :
            <>
              <p>{ hideTaxNumber(props.data.receiverTaxNumber) }</p>
              <p>{ getBankCode(props.data.receiverBank) }</p>
            </>
          }
        </div>
      </div>
    </div>
  );
}
