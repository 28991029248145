import { createContext, useState } from 'react';
import { apiV1 } from '../lib/axios';
import { toast } from 'react-toastify';
import { useAuth } from '../hooks/useAuth';
import { set } from 'react-hook-form';

export const EntryContext = createContext({});

export function EntryProvider({ children }) {
  const [entries, setEntries] = useState([]);
  const [ countEntries, setCountEntries ] = useState(0);
  const [entriesLoader, setEntriesLoader] = useState(false);

  const { user } = useAuth();

  async function updateEntriesFitbank() {
    if (user.taxNumber !== '12345678900') {
      await apiV1
        .post(`/entrys/fitbank/update`)
        .then((response) => {})
        .catch((err) => {});
    }
  }

  async function fetchEntries(
    page = '',
    initialDate = '',
    finalDate = '',
    type = ''
  ) {
    setEntriesLoader(true);

      await updateEntriesFitbank();

    setEntries([]);

    await apiV1
      .get(
        `/entrys?page=${page}&initialDate=${initialDate}&finalDate=${finalDate}&type=${type}`
      )
      .then(async (response) => {
        setEntries(response.data.entrys);
        setCountEntries(response.data.count);
        setEntriesLoader(false);
      })
      .catch(() => {
        toast.error('Não foi possivel carregar os Extratos');
        setEntriesLoader(false);
      });
  }

  return (
    <EntryContext.Provider
      value={{
        entries,
        entriesLoader,
        updateEntriesFitbank,
        fetchEntries,
        countEntries,
        setCountEntries,
      }}
    >
      {children}
    </EntryContext.Provider>
  );
}
