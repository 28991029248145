const unidades = ["", "um", "dois", "três", "quatro", "cinco", "seis", "sete", "oito", "nove"];
const dezenas10a19 = ["dez", "onze", "doze", "treze", "quatorze", "quinze", "dezesseis", "dezessete", "dezoito", "dezenove"];
const dezenas = ["", "dez", "vinte", "trinta", "quarenta", "cinquenta", "sessenta", "setenta", "oitenta", "noventa"];
const centenas = ["", "cem", "duzentos", "trezentos", "quatrocentos", "quinhentos", "seiscentos", "setecentos", "oitocentos", "novecentos"];

export function convertNumberToWords(numero) {
  
  const formattedNumber = parseFloat(numero);
  const [parteInteira, parteDecimal] = formattedNumber?.toFixed(2)?.split('.')?.map(Number);

  const extensoInteira = converteNumero(parteInteira);
  const extensoDecimal = converteNumero(parteDecimal, true);

  if (parteDecimal > 0) {
    return `${extensoInteira} e ${extensoDecimal}`;
  } else {
    return extensoInteira;
  }
}

function converteNumero(numero, isCentavos = false) {
  if (numero === 0) return isCentavos ? "zero centavos" : "zero reais";
  if (numero === 1 && !isCentavos) return "um real";

  const partes = [];
  const milhar = Math.floor(numero / 1000);
  numero %= 1000;
  const centena = Math.floor(numero / 100);
  numero %= 100;
  const dezena = Math.floor(numero / 10);
  const unidade = numero % 10;

  if (milhar) partes.push(milhar === 1 ? "mil" : `${unidades[milhar]} mil`);
  if (centena) partes.push(centena === 1 && !numero ? "cem" : centenas[centena]);
  if (dezena) {
    if (dezena === 1 && unidade) {
      partes.push(dezenas10a19[unidade]);
    } else {
      partes.push(dezenas[dezena]);
      if (unidade) partes.push(unidades[unidade]);
    }
  } else if (unidade) {
    partes.push(unidades[unidade]);
  }

  const unidadeMonetaria = isCentavos ? "centavos" : "reais";
  return `${partes.join(" e ")} ${unidadeMonetaria}`;
}
