import React, { useState } from 'react';
import { TbUser } from 'react-icons/tb';
import { ToastContainer, toast } from 'react-toastify';
import { forgotPassword } from '../../../services/login.service';
import { Link } from 'react-router-dom';
import { getImage } from '../../../services/getAssets';
import { BackButton, BackButtonLink, FormContainer, ImageContainer, InputContainer, LoginButton, LoginContent, LoginFormContainer } from './styles';
import { Input } from '../../Input';

const EsqueciSenha = () => {
  const [taxNumber, setTaxNumber] = useState('');
  const [email, setEmail] = useState('');
  const [forgotPasswordLoader, setForgotPasswordLoader] = useState(false);
  const [isSentEmail, setIsSentEmail] = useState(false);

  const handleConfirm = async () => {
    if (!taxNumber || !email) {
      return toast.error('Digite um email e CPF/CNPJ');
    }
    setForgotPasswordLoader(true);

    try{
      const { ok } = await forgotPassword(taxNumber, email);

      setForgotPasswordLoader(false);

      if (!ok) return toast.error('Não foi possivel enviar a solicitação de redefinição de senha');

      setIsSentEmail(true);
    } catch(err) {
      toast.error('Não foi possivel enviar a solicitação de redefinição de senha');
      setForgotPasswordLoader(false);
    }

  };
  return (
    <>
      <LoginContent>
          {!isSentEmail ? (
            <LoginFormContainer className='col-md-10'>
              <ImageContainer>
                <img src={getImage('logo.png')} alt="" />
              </ImageContainer>
              <FormContainer>
                <h2 className='text-center'>Redefinir a senha</h2>

                <InputContainer>
                  <Input
                    type="text"
                    label="CPF/CNPJ"
                    icon={<TbUser />}
                    onChange={setTaxNumber}
                  />
                </InputContainer>
                <InputContainer>
                  <Input
                    type="text"
                    label="Email"
                    icon={<TbUser />}
                    onChange={setEmail}
                  />
                </InputContainer>
                { forgotPasswordLoader
                  ? (
                    <LoginButton disabled>
                      Confirmando...
                    </LoginButton>
                  ) : (
                    <LoginButton onClick={handleConfirm}>
                      Confirmar
                    </LoginButton>
                )}
              </FormContainer>

                <BackButtonLink to={'/'}>
                  <BackButton id='backButton'>Voltar</BackButton>
                </BackButtonLink>
            </LoginFormContainer>
          ) : (
            <LoginFormContainer>
              <ImageContainer>
                <img src={getImage('logo.png')} alt="" />
              </ImageContainer>
              <div className='col-xl-12 d-flex flex-column'>
                <h2 className='text-center'>Solicitação Enviada</h2>
                <h5 className='text-center'>
                  Caso esse Email e esse CPF/CNPJ sejam válidos, será enviada para o email cadastrado uma solicitação de
                  redefinição de senha.
                </h5>
                <BackButtonLink to={'/'}>
                  <BackButton>Voltar</BackButton>
                </BackButtonLink>
              </div>
            </LoginFormContainer>
          )}
          <img src="/images/login-image.png" alt="" />
          <ToastContainer/>
      </LoginContent>
    </>
  );
};

export default EsqueciSenha;
