import { NavLink } from 'react-router-dom'
import { useChargeTicket } from '../../../../hooks/useChargeTicket'
import { Box } from '../../../../components/box'
import { Input } from '../../../../components/Input'
import moment from 'moment'
import * as Checkbox from '@radix-ui/react-checkbox';
import { PiCheckBold } from 'react-icons/pi'
import { TbCurrencyReal } from 'react-icons/tb'
import { FaPercentage } from 'react-icons/fa'
import { useTicket } from '../../../../hooks/useTicket'
import { deadlineOptions, discountDueDates, interestAmounts } from '../../../../utils/boletoUtils'

import './styles.css'

export function CobrarBoletoInfos() {
  const { setStep } = useTicket()

  const { 
    ticketValue, 
    ticketDueDate, 
    ticketBillingName, 
    setTicketValue,
    setTicketDueDate,
    setTicketBillingName, 
    fineValue,
    setFineValue,
    setFessValue,
    setDeadlinePayment,
    fineType,
    setFineType,
    setDeadlineDiscount,
    setDiscountType,
    discountValue,
    setDiscountValue,
    discountType,
    chargeInterest,
    chargeFees,
    chargeDiscount,
    setChargeInterest,
    setChargeFees,
    setChargeDiscount,
  } = useChargeTicket()

  function handleProceed(e) {
    e.preventDefault();
    
    setStep(2);
  }

  return (
    <Box
      title='Cobrar via Boleto'
      description='Insira as informações do boleto que deseja enviar'
      className='cobrar-boleto-box'
    >
      <form onSubmit={handleProceed} className='step1-content'>
        <div className='cobrar-boleto-inputs'>
          <div className='step1-content-body'>
            <div className='step1-inputs'>
              <Input 
                type='currency'
                label='Valor'
                icon={<TbCurrencyReal size={18} />}
                value={ticketValue}
                onChange={setTicketValue}
                isRequired
              />
              <Input 
                type='date'
                label='Data de Vencimento'
                min={moment().format("YYYY-MM-DD")}
                max="9999-12-31"
                value={ticketDueDate}
                onChange={setTicketDueDate}
                isRequired
              />
              <Input 
                type='text'
                label='Nome da Cobrança'
                value={ticketBillingName}
                onChange={setTicketBillingName}
                isRequired
              />
            </div>
          </div>
          
          <div className='cobrar-boleto-options'>
            <label className='options-section-title'>Opções <span>*</span></label>
            <div className='cobrar-boleto-checkbox-container'>
              <div className='cobrar-boleto-checkbox-item'>
                <Checkbox.Root 
                  className='cobrar-boleto-checkbox-root' 
                  id="dar-desconto" 
                  checked={chargeDiscount}
                  onCheckedChange={(isChecked) => setChargeDiscount(isChecked)}
                >
                  <Checkbox.Indicator className='cobrar-boleto-checkbox-indicator' asChild>
                    <PiCheckBold size={14} />
                  </Checkbox.Indicator>
                </Checkbox.Root>
                <label className='cobrar-boleto-checkbox-label' htmlFor="dar-desconto">Dar desconto</label>
              </div>
              <div className='cobrar-boleto-checkbox-item'>
                <Checkbox.Root 
                  className='cobrar-boleto-checkbox-root' 
                  id="cobrar-multa" 
                  checked={chargeInterest}
                  onCheckedChange={(isChecked) => setChargeInterest(isChecked)}
                >
                  <Checkbox.Indicator className='cobrar-boleto-checkbox-indicator' asChild>
                    <PiCheckBold size={14} />
                  </Checkbox.Indicator>
                </Checkbox.Root>
                <label className='cobrar-boleto-checkbox-label' htmlFor="cobrar-multa">Cobrar Multa</label>
              </div>

              <div className='cobrar-boleto-checkbox-item'>
                <Checkbox.Root 
                  className='cobrar-boleto-checkbox-root' 
                  id="cobrar-juros" 
                  checked={chargeFees}
                  onCheckedChange={(isChecked) => setChargeFees(isChecked)}
                >
                  <Checkbox.Indicator className='cobrar-boleto-checkbox-indicator' asChild>
                    <PiCheckBold size={14} />
                  </Checkbox.Indicator>
                </Checkbox.Root>
                <label className='cobrar-boleto-checkbox-label' htmlFor="cobrar-juros">Cobrar Juros</label>
              </div>
            </div>
          </div>

          {chargeDiscount && (
            <div>
              <div className='step1-content-body'>
                <div className='step1-inputs'>
                  <div className='fine-select-container'>
                    <label>Prazo máximo para o desconto <span>*</span></label>
                    <div className='fine-select-content'>
                      <select
                        defaultValue={"Até 1 dia"}
                        id="type-fine"
                        required={chargeDiscount}
                        onChange={(e) => setDeadlineDiscount(e.target.value)}
                      >
                        {discountDueDates().map((item) => <option key={item.value} value={item.value}>{item.name}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className='fine-select-container'>
                    <label>Tipo de desconto <span>*</span></label>
                    <div className='fine-select-content'>
                      <select
                        defaultValue={"Valor fixo"}
                        id="type-fine"
                        required={chargeDiscount}
                        onChange={(e) => setDiscountType(e.target.value)}
                      >
                        <option value={"fixed"}>Valor fixo</option>
                        <option value={"percent"}>Percentual</option>
                      </select>
                    </div>
                  </div>
                  {
                    discountType === 'fixed'
                    ? (
                      <Input 
                        type='currency'
                        label='Valor do desconto'
                        icon={<TbCurrencyReal size={18} />}
                        value={discountValue}
                        onChange={setDiscountValue}
                        isRequired
                      />
                    ) : (
                      <Input 
                        type='number'
                        label='Valor do desconto'
                        icon={<FaPercentage size={18} />}
                        value={discountValue}
                        onChange={(e) => setDiscountValue(e)}
                        isRequired
                      />
                    )
                  }
                </div>
              </div>
            </div>
          )}

          {chargeInterest && (
            <div>
              <div className='step1-content-body'>
                <div className='step1-inputs'>
                  <div className='fine-select-container'>
                    <label>Prazo máximo para pagamento <span>*</span></label>
                    <div className='fine-select-content'>
                      <select
                        defaultValue={"Até 1 dia"}
                        id="type-fine"
                        required={chargeInterest}
                        onChange={(e) => setDeadlinePayment(e.target.value)}
                      >
                        {deadlineOptions().map((item) => <option key={item.value} value={item.value}>{item.name}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className='fine-select-container'>
                    <label>Tipo de multa <span>*</span></label>
                    <div className='fine-select-content'>
                      <select
                        defaultValue={"Valor fixo"}
                        id="type-fine"
                        required={chargeInterest}
                        onChange={(e) => setFineType(e.target.value)}
                      >
                        <option value={"fixed"}>Valor fixo</option>
                        <option value={"percent"}>Percentual</option>
                      </select>
                    </div>
                  </div>
                  {
                    fineType === 'fixed'
                    ? (
                      <Input 
                        type='currency'
                        label='Valor da multa'
                        icon={<TbCurrencyReal size={18} />}
                        value={fineValue}
                        onChange={setFineValue}
                        isRequired
                      />
                    ) : (
                      <Input 
                        type='number'
                        label='Valor da multa'
                        icon={<FaPercentage size={18} />}
                        value={fineValue}
                        onChange={(e) => setFineValue(e)}
                        isRequired
                      />
                    )
                  }
                </div>
              </div>
            </div>
          )}
          <div className='step1-content-body'>
            <div className='step1-inputs'>
              {chargeFees && (
                <div className='fine-select-container'>
                  <label>Valor de juros <span>*</span></label>
                  <div className='fine-select-content'>
                    <select
                      defaultValue={"1% ao mês"}
                      id="type-fine"
                      required={chargeInterest && chargeFees}
                      onChange={(e) => setFessValue(e.target.value)}
                    >
                      {interestAmounts().map((item) => <option key={item.value} value={item.value}>{item.name}</option>)}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='step-buttons'>
          <NavLink to='/pagina-boleto' className='step-button'>Cancelar</NavLink>
          <button type='submit' className='step-button step-button-red'>Prosseguir</button>
        </div>
      </form>
    </Box>
  )
}