import styled from "styled-components";

export const AccessContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) =>
    props.theme.colors['login-background']
      ? `url(${props.theme.colors['login-background']})`
      :  props.theme.colors['login-gradient']
      ?? `linear-gradient(${props.theme.colors["gradient-deg"]}, ${props.theme.colors["gradient-primary"]} 0%, ${props.theme.colors["gradient-secondary"]} 100%)`};
  background-size: cover;
  background-color: ${(props) =>
    props.theme.colors['login-background']
      ? props.theme.colors['login-background-complement']
      : props.theme.colors['login-gradient']
      ? props.theme.colors['login-gradient-complement'] : ''}
`;
