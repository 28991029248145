import { useState } from "react";
import { createContext } from "react";
import { toast } from "react-toastify";
import { apiV1 } from "../lib/axios";

export const ClientContext = createContext({});

export function ClientProvider({ children }) {

  const [clientLoader, setClientLoader] = useState(false);
  const [isCreatingClient, setIsCreatingclient] = useState(false);
  const [client, setClient] = useState([]);
  const [error, setError] = useState();
  const [clientData, setClientData] = useState();
  const [formData, setFormData] = useState({
    isEcheck: true,
    name: "",
    email: "",
    phone: "",
    pixKey: "",
  });

  async function fetchClient () {
    setClientLoader(true);

   return await apiV1
      .get("/clients/list")
      .then(async (response) => {
        setClient(response.data);
        setClientLoader(false);
        return response.data;
      })
      .catch((err) => {

        setClientLoader(false);
         toast.error('Erro ao buscar clientes');
      });
  }

  async function deleteClient(id) {
    try {
      await apiV1.delete("/clients/delete", {
        data: {
          clientId: id,
        },
      });
      toast.success("Cliente deletado com sucesso");
    } catch (err) {
      toast.error(err.message);
    }
  }


  const validateName = (name) => {
    const nameParts = name.trim().split(/\s+/);
    return nameParts.length >= 2 && nameParts.every(part => part.length > 0);
  };
     let errorForms;

  function regexForms(user) {
    user.phone = user.phone.replace(/\D/g, '');
    const emailRegex = /^[\w.-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^(\+\d{2}(\d{2}|\d{3})\d{8,9}|\d{11,12})$/;
    if(!(emailRegex.test(user.email) && phoneRegex.test(user.phone))) {
      errorForms = 'Campos de email e/ou telefone inválidos';
      // toast.error(errorForms);
      setError(errorForms);
      return false;
    }
    if (validateName(user.name) === false) {
      errorForms = 'preencha o nome completo do cliente';
      // toast.error(errorForms);
      setError(errorForms);
      return false;
    }
    if (!user.pixKey > 0) {
      errorForms = 'Chave pix inválida';
      // toast.error(errorForms);
      setError(errorForms);
      return false;
    }
    return true;
  }

  function regexFormsUpdate(user) {
    const emailRegex = /^[\w.-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^(\+\d{2}(\d{2}|\d{3})\d{8,9}|\d{11,12})$/;
    if(!(emailRegex.test(user.mailToSend) && phoneRegex.test(user.phoneToSend))) {
      errorForms = 'Campos de email e/ou telefone inválidos';
      // toast.error(errorForms);
      setError(errorForms);
      return false;
    }
    if (validateName(user.name) === false) {
      errorForms = 'preencha o nome completo do cliente';
      // toast.error(errorForms);
      setError(errorForms);
      return false;
    }
    if (!user.pixKey > 0) {
      errorForms = 'Chave pix inválida';
      // toast.error(errorForms);
      setError(errorForms);
      return false;
    }
    return true;
  }

  async function updateClient(data) {
    data.phoneToSend = data.phoneToSend.replace(/\D/g, '');
    setClientLoader(true);
  
    if (regexFormsUpdate(data) === false) {
      setClientLoader(false);
      return;
    } else {
      if (
        clientData.mailToSend === data.mailToSend &&
        clientData.phoneToSend === data.phoneToSend &&
        clientData.pixKey === data.pixKey &&
        clientData.name === data.name
      ) {
        toast.error('Nenhum dado foi alterado');
        setClientLoader(false);
        return;
      }
  
      try {
        const response = await apiV1.patch("clients/update", data);
        toast.success("Cliente atualizado com sucesso");
  
        // Atualizar o estado local com os novos dados
        setClientData((prevData) => ({
          ...prevData,
          ...data,
        }));
  
        setClientLoader(false);
        return response;
      } catch (err) {
        setClientLoader(false);
        toast.error(err.message);
      }
    }
  }

  async function createClient() {
    setClientLoader(true);
  
    if (regexForms(formData) === false) {
      setClientLoader(false);
      return;
    }
  
    try {
       await apiV1.post("clients/store", {
        isEcheck: true,
        name: formData.name,
        mailToSend: formData.email,
        phoneToSend: formData.phone.replace(/\D/g, ''),
        pixKey: formData.pixKey,
      });
  
      toast.success("Cliente criado com sucesso");
      // Limpa os campos do formulário ou faz outras ações necessárias
      setFormData({
        name: '',
        email: '',
        phone: '',
        pixKey: '',
      });
  
    } catch (err) {
      toast.error(err.message);
    } finally {
      setClientLoader(false);
    }
  }
  
  const context = {
    createClient,
    fetchClient,
    setFormData,
    formData,
    client,
    error,
    setError,
    updateClient,
    deleteClient,
    setClientData,
    clientData,
    isCreatingClient, 
    setIsCreatingclient
  };

  return (
    <ClientContext.Provider
      value={context}
    >
      {children}
    </ClientContext.Provider>
  );
}

