import styled from "styled-components";

export const CompanyDataFormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  span {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const CompanyDataFormInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  > div {
    max-width: 18.75rem;
    width: 100%;
  }
  > span{
     display: flex;
  }
`;

export const InstituicaoInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  label {
    font-size: 0.875rem;
    font-weight: 400;

    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  div {
    height: 43px;
  }

  span {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.danger};
  }
`;

export const Select = styled.select`
  width: 100%;
  max-height: 2.6875rem;
  height: 100%;
  padding: 0.625rem 0.75rem;
  background-color: ${({ theme }) => theme.colors['bg-input-color']};
  border: 1px solid ${({ theme }) => theme.colors['text-primary']};
  border-radius: 4px;

  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ErrorMessage = styled.span`
  margin-top: 2rem;
  color: red;
`;

export const Div = styled.div`
position: relative;
transform: translateY(-377%);
margin-left: 24.1rem;
`;

export const WarnMessage = styled.span`
  font-size: 13.5px !important;
  color: var(--color-danger);
  margin-left: 0.2rem;
  font-weight: 700;
  border-bottom: 1px solid ${({ theme }) => theme.colors['text-primary']};
  
`;