import axios from "axios";
import {
  getAccessToken,
  getRefreshToken,
  removeTokens,
  storeTokens,
} from "../utils/sessionMethods";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";

const getUrlApi = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:3000/api/v1/web";
  } else {
    return process.env.REACT_APP_URL_API_V1;
  }
};

export const apiV1 = axios.create({
  baseURL: getUrlApi(),
});

apiV1.interceptors.request.use(
  (config) => {
    const token = getAccessToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

const tryRefreshToken = async () => {
  const token = getRefreshToken();

  return axios
    .get(`${apiUrl}/login/session`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      storeTokens(response.data.accessToken, response.data.refreshToken);
    });
};

async function error401handling(error) {
  await tryRefreshToken()
    .then(() => {
      api(error.config);
    })
    .catch((err) => {

      removeTokens();
      toast.warning("Sessão expirada, entre novamente.");

      setTimeout(() => {
        document.location.href = "/";
      }, 5000)
    });
  return Promise.reject(error);
}

const ignoredRoutes = ["/login/session", "/login/refresh"];

apiV1.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      !ignoredRoutes.includes(error.config.url) &&
      error.response.status === 401
    ) {
      return error401handling(error);
    }

    return Promise.reject(error);
  }
);
