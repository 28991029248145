import { css } from '@emotion/react';
import { useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { FiUser } from 'react-icons/fi';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { PiKeyFill } from 'react-icons/pi';
import { TbTrash } from 'react-icons/tb';
import { BeatLoader } from 'react-spinners';
import { Input } from '../../components/Input';
import { PageHeader } from '../../components/page-header';
import { useAccount } from '../../hooks/useAccount';
import { useAuth } from '../../hooks/useAuth';
import { formatPhone } from '../../utils/formatter';
import { PasswordChangeModal } from './password-change-modal';

import './styles.css';

export function Settings() {
  const { user } = useAuth();
  const { editAvatar, deleteAvatar } = useAccount();

  const [newEmail, setNewEmail] = useState('');
  const [newTel, setNewTel] = useState('');
  // const [avatar, setAvatar] = useState('')
  const [loader, setLoader] = useState(false);

  const [passwordChangeModal, setPasswordChangeModal] = useState(false);

  const handleAvatarChange = async (e) => {
    setLoader(true);

    if (e.target.files) {
      const file = e.target.files[0];

      await editAvatar(file);

      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const handleDeleteAvatar = async () => {
    setLoader(true);

    await deleteAvatar()
      .then(() => {
        setLoader(false);
      })
      .catch(() => {
        setLoader(true);
      });
  };

  const override = css`
    display: flex;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <>
      <div className="settings-container">
        <div className="settings-header">
          <PageHeader
            title="Configurações"
            pages={[{ name: 'Configurações', link: '' }]}
          />
        </div>

        <div className="settings-section">
          <div className="settings-section-title">
            <span>Foto de Perfil</span>
            <MdKeyboardArrowRight size={24} />
          </div>

          <div className="settings-avatar-content">
            <div className="settigs-avatar">
              {loader ? (
                <BeatLoader
                  color="white"
                  loading={loader}
                  css={override}
                  size={10}
                />
              ) : (
                <>
                  {user?.avatar?.url ? (
                    <img src={user.avatar.url} alt="" />
                  ) : (
                    <FiUser size={40} />
                  )}
                </>
              )}
            </div>

            <div className="avatar-input-file">
              <label htmlFor="new-avatar" className="settings-avatar-button">
                <AiOutlineCloudUpload size={16} />
                Enviar nova Foto
              </label>
              <input
                type="file"
                id="new-avatar"
                onChange={handleAvatarChange}
                accept="image/png,image/jpeg,image/webp"
              />
            </div>

            <button
              className="settings-avatar-button button-without-bg"
              onClick={handleDeleteAvatar}
            >
              <TbTrash size={16} />
              Apagar foto
            </button>
          </div>
        </div>

        <div className="settings-content">
          <div className="settings-section">
            <div className="settings-section-title">
              <span>Informações de Contato</span>
              <MdKeyboardArrowRight size={24} />
            </div>

            <form className="settings-contanct-content">
              <Input
                type="email"
                label="Email"
                value={user.mail}
                placeholder="fuladodetal@gmail.com"
                disabled
              />

              <Input
                type="email"
                label="Telefone"
                value={formatPhone(String(user.phoneNumber))}
                placeholder="(21) 98765-4321"
                disabled
              />

              {/* <button type='submit' className='settings-button'>Confirmar ajustes</button> */}
            </form>
          </div>

          <div className="settings-section without-border">
            <div className="settings-section-title">
              <span>Senha</span>
              <MdKeyboardArrowRight size={24} />
            </div>

            <div className="settings-contanct-content">
              <button
                className="change-password-button"
                onClick={() => setPasswordChangeModal(true)}
              >
                <PiKeyFill size={16} />
                Alterar Senha
              </button>
            </div>
          </div>
        </div>
      </div>

      <PasswordChangeModal
        isOpen={passwordChangeModal}
        setPasswordChangeModal={setPasswordChangeModal}
      />
    </>
  );
}
