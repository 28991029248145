import React from "react";;
import 'jspdf-autotable';
import { useState } from 'react';
import { FaFilePdf, FaFileCsv } from 'react-icons/fa';
import Select from 'react-select';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { PopUpDivMain, ButtonExport, ButtonDiv} from "./styles";
import { FilterButton } from "../styles";

export default function(props) {

    const [exportType, setExportType] = useState('');
    const [placeholder, setPlaceholder] = useState('selecione o tipo de exportação');
    const [loading, setLoading] = useState(false);

    async function Export() {
      if (exportType) {
        if (exportType === 'PDF') {
          generatePDF();
      } else {
          generateCSV();
      }
      } else {
      alert('Selecione o tipo de exportação');
    }
  }

    async function generateCSV() {
        let header = "Nº da transação, CPF/CNPJ DO DESTINIARIO, BANCO DO DESTINATARIO, AGÊNCIA DO DESTINATARIO, Nº DA CONTA DO DESTINATARIO, TIPO DA TRANSAÇÃO, VALOR DA TRANSAÇÂO, NOME DO REMETENTE, CPF/CNPJ DO REMETENTE, BANCO DO REMETENTE, AGÊNCIA DO REMETENTE, Nº DA CONTA DO REMETENTE, DATA DA TRANSAÇÃO \n";
        setLoading(true);
        const csvBody = props.data.map((entry) => {
          return `${entry.documentNumber}, ${entry.receiverTaxNumber}, ${entry.receiverBank}, ${entry.receiverBankBranch}, ${entry.receiverBankAccount} - ${entry.receiverBankAccountDigit}, ${entry.description ? entry.description : "-"}, ${entry.value}, ${entry.payerName}, ${entry.payerTaxNumber}, ${entry.payerBank}, ${entry.payerBankBranch}, ${entry.payerBankAccount} - ${entry.payerBankAccountDigit}, ${entry.date} \n`;
         });
         const csv = header + csvBody.join('');
         const encodedUri = encodeURI(csv);
         const link = document.createElement('a');
         link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodedUri);
         link.setAttribute('download', 'relatorio.csv');
         document.body.appendChild(link);
         setTimeout(function() {
         setLoading(false);
           link.click();
         }, 1000);
       }

       function generatePDF() {
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'pt',
            format: 'A4'
        });
         setLoading(true);
        autoTable(doc, {
            head: [['Nº da transação', 'CPF/CNPJ DO DESTINIARIO', 'BANCO DO DESTINATARIO', 'AGÊNCIA DO DESTINATARIO', 'Nº DA CONTA DO DESTINATARIO', 'TIPO DA TRANSAÇÃO', 'VALOR DA TRANSAÇÂO', 'NOME DO REMETENTE', 'CPF/CNPJ DO REMETENTE', 'BANCO DO REMETENTE', 'AGÊNCIA DO REMETENTE', 'Nº DA CONTA DO REMETENTE', 'DATA DA TRANSAÇÃO']],
            body: props.data.map((entry) => [
                entry.documentNumber, entry.receiverTaxNumber, entry.receiverBank, entry.receiverBankBranch, `${entry.receiverBankAccount} - ${entry.receiverBankAccountDigit}`, `${entry.description ? entry.description : "-"}`, entry.value, entry.payerName, entry.payerTaxNumber, entry.payerBank, entry.payerBankBranch, `${entry.payerBankAccount} - ${entry.payerBankAccountDigit}`, entry.date
            ]),
            styles: { fontSize: 6 },
            margin: { top: 20, bottom: 20 },
            pageBreak: 'auto',
            theme: 'grid'
        });

        doc.save('relatorio.pdf');
        setTimeout(function() {
          setLoading(false);
        }, 1000);
    }

      function handleFilterOptions(e) {
       setExportType(e.value);
        setPlaceholder(e.label);
      }

      function closePopup() {
        props.setLoader(false);
        props.setShowPopup(false);
      }

      const options = [
        { value: 'PDF', label: <><FaFilePdf /> Exportar PDF</> },
        { value: 'CVS', label: <><FaFileCsv /> Exportar CSV</> },
      ];


      return (
            <PopUpDivMain className="col-md-4">
            <h4>Selecione exportação</h4>
            <Select className="select placeholder-gray-500"
              onChange={(e) => handleFilterOptions(e)}
              options={options}
              placeholder={placeholder}
              />
            <ButtonDiv>
            {!loading ? <ButtonExport className="col-md-3 btn" onClick={() => Export() }>Exportar</ButtonExport> : <FilterButton disabled>Exportando...</FilterButton>}
            <button className="btn btn-secondary col-md-3"  onClick={() => closePopup() }>Fechar</button>
            </ButtonDiv>
            </PopUpDivMain>
      );
}
