import { createContext, useState } from "react";
import { apiV1 } from "../lib/axios";
import { useAuth } from "../hooks/useAuth";
import { toast } from "react-toastify";
import { uploadS3 } from "../services/uploadS3";
import { fileToBase64 } from "../utils/fileToBase64";

export const AccountContext = createContext({});

export function AccountProvider({ children }) {
  const [balance, setBalance] = useState();
  const [avaliableBalance, setAvaliableBalance] = useState();
  const [blockedBalance, setBlockedBalance] = useState();

  const { account, getProfile } = useAuth();

  async function updateBalance() {
    await apiV1
      .patch(`/balance/update`)
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }

  async function getBalance() {
    setBalance(0);
    setAvaliableBalance(0);
    setBlockedBalance(0);

    await updateBalance();

    const response = await apiV1.get('account/show');
    const balance = response.data.data.balance;

    setBalance(Number(balance.balance));
    setAvaliableBalance(Number(balance.avaliableBalance));
    setBlockedBalance(Number(balance.balance - balance.avaliableBalance));
  }

  async function editPassword(data) {
    await apiV1
      .patch("/account/password/update", data)
      .then(() => {
        toast.success("Senha alterado com sucesso.");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }

  async function editAvatar(file) {
    const avatar = await fileToBase64(file);

    await apiV1
      .patch("/person/avatar/update", { avatar : {
        key: `avatars/${Date.now() + file.name}`,
        file: avatar
      }})
      .then(async (response) => {
        await getProfile();
        toast.success(response.data.message);
      })
      .catch((err) => {

      });
  }

  async function deleteAvatar() {
    await apiV1
      .delete("/person/avatar")
      .then(async () => {
        await getProfile();
        toast.success("Foto de perfil removida com sucesso.");
      })
      .catch((err) => {
      });
  }

  return (
    <AccountContext.Provider
      value={{
        account,
        balance,
        avaliableBalance,
        blockedBalance,
        getBalance,
        editPassword,
        editAvatar,
        deleteAvatar,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}
