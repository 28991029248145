export const maritalStatusOptions = [
  { label: "Selecione uma Opção", value: "", selected: true , disabled: true},
  { label: "Casado(a)", value: 1 },
  { label: "Divorciado(a)", value: 2 },
  { label: "Separado(a)", value: 3 },
  { label: "Viúvo(a)", value: 4 },
  { label: "Solteiro(a)", value: 5 },
  { label: "Outro", value: 6 },
]

export const getGender = (maritalStatus) => {
  const maritalStatusObj = maritalStatusOptions.find((item) => item.label == maritalStatus);

  return maritalStatusObj?.value;
};

export const getmaritalStatusLabel = (maritalStatus) => {
  const maritalStatusObj = maritalStatusOptions.find((item) => item.value == maritalStatus);

  return maritalStatusObj?.label;
}
