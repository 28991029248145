import './styles.css'

export function BoletoPdf() {
  const ticketPdfBase64 = localStorage.getItem('boletoPDF')

  return (
    <div className="pdfContainer">
      <iframe src={ticketPdfBase64} width='100%' height='100%'></iframe>
    </div>
  )
}