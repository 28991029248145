import './styles.css'

export function ItemMark() {
  return (
    <div className="boletos-item">
      <div className="item-mark">
        <span className="mark-circle"></span>
        <span className="mark-line"></span>
      </div>
    </div>
  )
}