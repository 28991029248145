import React from "react";

import { Routes, Route } from "react-router-dom";

import PaginaPrincipal from "./pages/pagina-principal/PaginaPrincipal";
import Pix from "../src/pages/Pagina-pix/PaginaPix";
import { PaginaBoleto } from "./pages/pagina-boleto";
import ResumoBoleto from "./pages/pagina-boleto/resumo-boleto/index";
import Deposito from "../src/pages/pagina-deposito/index";
import GerarCodigo from "../src/pages/Pagina-pix/gerar-codigo-pix/GerarCodigo";
import Pagar from "../src/pages/pagina-pagar/PaginaPagar";
import CopiarDados from "../src/pages/pagina-deposito/copia-dados-deposito/CopiarDados";
import Transferir from "../src/pages/pagina-transferir/PaginaTransferir";
import CadastrarChavePix from "./pages/Pagina-pix/nova-chave-pix/CadastrarChavePix";
import ConfirmarChavePix from "./pages/Pagina-pix/confirmar-chave-pix/ConfirmarChavePix";
import AppLayout from "./components/App-layout/AppLayout";
import PaginaExtrato from "./pages/pagina-extrato/PaginaExtrato";
import PaginaPerguntasFrequentes from "./pages/pagina-perguntas-frequentes/PaginaPerguntasFrequentes";
import PaginaContato from "./pages/pagina-contato/PaginaContato.jsx";
import {ContactFormPage} from "./pages/pagina-contact-form-page/ContactFormPage";
import TransferirPix from "./pages/Pagina-pix/transferir-pix/TransferirPix";
import { Private } from "./Private";
import PaginaLimitesPix from "./pages/Pagina-pix/limites-pix/PaginaLimitesPix";
import PaginaTedInicial from "./pages/pagina-transferir/pagina-inicial/PaginaTedInicial";
import { PagarBoleto } from "./pages/pagina-boleto/pagar-boleto";
import { TicketProvider } from "./contexts/Tickets/TicketContext";
import { CobrarComBoleto } from "./pages/pagina-boleto/cobrar-com-boleto";
import { BoletoPdf } from "./pages/pagina-boleto/boletoPdf";
import { Comprovante } from "./pages/pagina-boleto/comprovante";
import { Settings } from "./pages/configurações";
import { ChargeTicketProvider } from "./contexts/Tickets/ChargeTicket";
import { Login } from "./pages/login";
import { Register } from "./pages/register";
import { AccessLayout } from "./Layouts/AccessLayout";
import { RegisterProvider } from "./contexts/RegisterContext";
import { RegisterPF } from "./pages/register/PF";
import { RegisterPJ } from "./pages/register/PJ";
import RecuperarSenhaPage from "./pages/login/recuperar-senha";
import EsqueciSenha from "./components/login/esqueci-senha/EsqueciSenha";
import PaginaEcheque from "./pages/pagina-E-check/PaginaEcheque";
import PaginaRedeemEcheck from "./pages/pagina-redeem-echeck/PaginaRedeemEcheck";
import {routerLabels} from '../src/utils/routerLabels';


export default function () {
  return (
    <Routes>
      <Route path="/" element={<AccessLayout />}>
        <Route path="/" element={<Login />} />
        <Route path="/" element={<RegisterProvider />}>
          <Route path="/cadastro" element={<Register />} />
          <Route path="/cadastro/pf" element={<RegisterPF />} />
          <Route path="/cadastro/pj" element={<RegisterPJ />} />
        </Route>
        <Route path="/esqueci-senha" element={<EsqueciSenha />} />
        <Route path="/recuperar-senha/:hash" element={<RecuperarSenhaPage />} />
      </Route>
      <Route path="/boleto-emitido" element={<BoletoPdf />} />
      <Route path="/comprovante" element={<Comprovante />} />
      <Route path="/resgatar-echeck/:identifier" element={<PaginaRedeemEcheck />} />
      <Route path="/" element={<AppLayout />}>
        <Route path="/" element={<Private />}>
          <Route path="/pagina-principal" element={<PaginaPrincipal />} />
          <Route path="/contato" element={<PaginaContato />} />
          <Route path={routerLabels.contactFormPage} element={<ContactFormPage />} />
          <Route
            path="/perguntas-frequentes"
            element={<PaginaPerguntasFrequentes />}
          />
          <Route path="/pagina-extrato" element={<PaginaExtrato />} />
          <Route path="/configuracoes" element={<Settings />} />

          <Route path="/pagina-pix" element={<Pix />} />
          <Route path="/pagina-echeque" element={<PaginaEcheque />} />

          <Route path="/pagina-pagar-pix" element={<TransferirPix />} />
          <Route path="/pagina-pagar-pix/:id" element={<TransferirPix />} />
          <Route path="/gerar-codigo" element={<GerarCodigo />} />
          {/* <Route path="/chave-pix" element={<MinhaChaves />} /> */}
          <Route path="/nova-chave-pix" element={<CadastrarChavePix />} />
          <Route path="/confirmar-chave-pix" element={<ConfirmarChavePix />} />
          <Route path="/limites-pix" element={<PaginaLimitesPix />} />

          <Route path="/pagina-deposito" element={<Deposito />} />
          <Route path="/copiar-dados" element={<CopiarDados />} />

          <Route path="/pagina-transferencia" element={<Transferir />} />
          <Route path="/pagina-ted" element={<PaginaTedInicial />} />

          <Route path="/pagina-pagar" element={<Pagar />} />

          <Route path="/" element={<TicketProvider />}>
            <Route path="/pagina-boleto" element={<PaginaBoleto />} />
            <Route path="/pagina-pagar-boleto" element={<PagarBoleto />} />
            <Route
              path="/pagina-cobrar-boleto"
              element={
                <ChargeTicketProvider>
                  <CobrarComBoleto />
                </ChargeTicketProvider>
              }
            />
            <Route path="/pagina-resumo-boleto" element={<ResumoBoleto />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}


