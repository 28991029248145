import ReactModal from "react-modal"
import { BoxModal } from "../../../../../components/boxModal"
import { ActionsButton, BoxActions } from "./styles"
import { useRegister } from "../../../../../hooks/useRegister"

import './styles.css'

export const ConfirmModal = ({ isOpen, closeModal, event }) => {
  const { loading } = useRegister()

  return (
    <ReactModal
      isOpen={isOpen}
      className={"change-modal"}
      overlayClassName={"overlay-change-password-modal"}
    >
      <BoxModal
        title='Confirmação'
        description='Se já validou seus dados clique em prosseguir para finalizar seu cadastro.'
        maxWidth={680} 
        close={closeModal}
      >
        <BoxActions>
          <ActionsButton type="button" onClick={closeModal} disabled={loading}>Cancelar</ActionsButton>
          {loading ? (
            <ActionsButton variant="full" disabled>Concluindo...</ActionsButton>
          ) : (
            <ActionsButton variant="full" onClick={event}>Concluir</ActionsButton>
          )}
        </BoxActions>
      </BoxModal>
    </ReactModal>
  )
}