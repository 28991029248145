import { Input } from '../../../../components/Input'
import { useRegister } from '../../../../hooks/useRegister'
import { InputContainer, ResumeContent, ResumeInputs } from './styles'
import { formatPhone } from '../../../../utils/formatter'
import { BoxDefault } from '../../BoxDefault'
import { ConfirmModal } from './ConfirmModal'
import { useState } from 'react'
import { getBithStateLabel } from '../../../../utils/birthState'
import { getCompanyType } from '../../../../utils/company'
import { getGenderLabel } from '../../../../utils/genders'
import { getmaritalStatusLabel } from '../../../../utils/maritalStatus'

export const Resume = () => {
  const [ConfirmResumeModal, setConfirmResumeModal] = useState(false);

  const {
    setStep,
    companyDataPj,
    addressPj,
    representativePj,
    registerPj
  } = useRegister()

  const handleNext = () => {
    setConfirmResumeModal(true)
  }

  const handleBack = () => {
    setStep(5)
  }

  const handleCloseModal = () => {
    setConfirmResumeModal(false)
  }

  const handleConfirm = async () => {
    await registerPj()

    handleCloseModal()
  }

  return (
    <BoxDefault
      submitEvent={handleNext}
      title="Resumo dos dados"
      description="Validade seus dados antes de concluir, caso exista alguma divergência de dados sua conta estará sujeita a reprovação."
      buttonOne="Voltar"
      buttonTwo="Concluir"
      buttonTwoLoading="Carregando..."
      marginLeft="l"
      handleBack={handleBack}
    >
      <ResumeInputs>
        <ResumeContent>
          <span>Dados da Empresa</span>

          <InputContainer>
            <Input
              type="text"
              label="CNPJ"
              value={companyDataPj.cnpj}
              disabled
            />
            <Input
              type="text"
              label="Razão Social"
              value={companyDataPj.socialReason}
              disabled
            />
            <Input
              type="text"
              label="Tipo de Empresa"
              value={getCompanyType(companyDataPj.companyType)}
              disabled
            />
            <Input
              type="text"
              label="Nome Fantasia"
              value={companyDataPj.fantasyName}
              disabled
            />

            <Input
              type="date"
              label="Data de abertura"
              value={companyDataPj.constitutionDate}
              disabled
            />

            <Input
              type="currency"
              label="Faturamento da empresa"
              value={companyDataPj.monthlyIncome}
              disabled
            />

            <Input
              type="text"
              label="Atividade da empresa"
              value={companyDataPj.companyActivity}
              disabled
            />
          </InputContainer>
        </ResumeContent>

        <ResumeContent>
          <span>Dados de Contato</span>

          <InputContainer>
            <Input
              type="text"
              label="E-mail"
              value={companyDataPj.email}
              disabled
            />
            <Input
              type="text"
              label="Telefone"
              value={companyDataPj.phone}
              disabled
            />
          </InputContainer>
        </ResumeContent>

        <ResumeContent>
          <span>Dados de Endereço</span>

          <InputContainer>
            <Input
              type="text"
              label="CEP"
              value={addressPj.cep}
              disabled
            />
            <Input
              type="text"
              label="Rua"
              value={addressPj.street}
              disabled
            />
            <Input
              type="text"
              label="Número"
              value={addressPj.number}
              disabled
            />
            <Input
              type="text"
              label="Bairro"
              value={addressPj.neighborhood}
              disabled
            />
            <Input
              type="text"
              label="Cidade"
              value={addressPj.city}
              disabled
            />
            <Input
              type="text"
              label="Estado"
              value={getBithStateLabel(addressPj.state)}
              disabled
            />
            <Input
              type="text"
              label="Complemento"
              value={addressPj.complement}
              disabled
            />
          </InputContainer>
        </ResumeContent>

        {representativePj.map((item, index) => (
          <ResumeContent key={index}>
            <span>Dados do representante legal {index + 1}</span>

            <InputContainer>
              <Input
                type="text"
                label="Nome Completo"
                value={item.name}
                disabled
              />
              <Input
                type="text"
                label="Nome Completo da Mãe"
                value={item.motherName}
                disabled
              />
              <Input
                type="date"
                label="Data de Nascimento"
                value={item.birthDate}
                disabled
              />
              <Input
                type="mask"
                label="CPF"
                value={item.cpf}
                disabled
              />
              <Input
                type="text"
                label="E-mail"
                value={item.email}
                disabled
              />
              <Input
                type="text"
                label="Telefone"
                value={formatPhone(item.phone)}
                disabled
              />

              <Input
                type="text"
                label="Estado de Nascimento"
                value={getBithStateLabel(item.birthState)}
                disabled
              />

              <Input
                type="text"
                label="Cidade de Nascimento"
                value={item.birthCity}
                disabled
              />

              <Input
                type="text"
                label="Gênero"
                value={getGenderLabel(item.gender)}
                disabled
              />

              <Input
                type="text"
                label="Estado Civil"
                value={getmaritalStatusLabel(item.maritalStatus)}
                disabled
              />
            </InputContainer>
          </ResumeContent>
        ))}
      </ResumeInputs>

      <ConfirmModal
        closeModal={handleCloseModal}
        isOpen={ConfirmResumeModal}
        setConfirmResumeModal={setConfirmResumeModal}
        event={handleConfirm}
      />
    </BoxDefault>
  )
}
