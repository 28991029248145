import { CompleteRegisterClose, CompleteRegisterContainer, CompleteRegisterContent, CompleteRegisterTexts } from "./styles"
import { IoMdClose } from "react-icons/io";

import sucessRegister from '../../../assets/image/success-register.svg'

export const CompleteRegister = () => {
  return (
    <CompleteRegisterContainer>
      <img src={sucessRegister} alt="" />

      <CompleteRegisterContent>
        <h1>Solicitação de Abertura de Conta Enviada com Sucesso</h1>

        <p>Sua solicitação foi recebida. Seus dados estão sendo avaliados e em breve confirmaremos a abertura da conta.</p>

        <span>Agradecemos pela sua escolha!</span>
      </CompleteRegisterContent>

      <CompleteRegisterClose to="/">
        <IoMdClose size={24} />
      </CompleteRegisterClose>
    </CompleteRegisterContainer>
  )
}