import { CurrencyInput } from 'react-currency-mask'
import ReactInputMask from 'react-input-mask'

import './style.css'
import Asterisk from '../../shared/components/asterisk/asterisk'

export function Input({ 
  type, 
  label, 
  description,
  placeholder,
  icon, 
  value, 
  onChange, 
  onInput,
  error,
  isRequired,
  min,
  max,
  mask,
  disabled,
  maxLength,
  register,
  onlyNumber,
  accept,
}) {
  return (
    <div className="input-container">
      <label htmlFor="">{label} {isRequired && <Asterisk/>}</label>
      
      {description && <p>{description}</p>}
      
      <div className="input-with-icon">
        {icon}
        {type === 'currency' && (
          <CurrencyInput 
            value={value} 
            hideSymbol='false'
            onChangeValue={(_, value) => {
              onChange(value)
            }}
            InputElement={<input required={isRequired} />}
            disabled={disabled}
            maxLength={maxLength}
            {...register}
          />
        )}

        {type === 'mask' && (
          <ReactInputMask 
            mask={mask}
            value={value} 
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)} 
            required={isRequired}
            disabled={disabled}
            {...register}
          />
        )}

        {type === 'file' && (
          <input 
            className='inputFile'
            type={type} 
            required={isRequired}
            disabled={disabled}
            accept={accept}
            {...register}
          />
        )}

        {type !== 'currency' && type !== 'mask' && type !== 'file' && (
          <input 
            type={type} 
            value={value} 
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)} 
            min={min}
            max={max}
            required={isRequired}
            disabled={disabled}
            maxLength={maxLength}
            onInput={(e) => onlyNumber ? e.target.value = e.target.value.replace(/[^0-9.]/g, '') : e.target.value}
            {...register}
          />
        )}
      </div>

      {error && <span>{error}</span>}
    </div>
  )
}