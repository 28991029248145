import { banco_codigo } from '../assets/banco_codigo';

export function formatCnpjCpf(value) {
  if(value === "Não Informado"){
    return value;
  }
  const cnpjCpf = value.replace(/\D/g, '');

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }

  return cnpjCpf.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    '$1.$2.$3/$4-$5'
  );
}

export function formatCnpj(value) {
  const cnpj = value.replace(/\D/g, '');

  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
}

export function formatPhone(tel, hash) {
  if (tel.length == 10) {
    const telDDD = tel.slice(0, 2);
    const telPart1 = tel.slice(2, 6);
    const telPart2 = tel.slice(6, 10);

    return `(${telDDD}) ${telPart1}-${telPart2}`;
  } else if (tel.length == 11) {
    const telDDD = tel.slice(0, 2);
    const telPart1 = tel.slice(2, 7);
    const telPart2 = tel.slice(7, 11);

    const response = hash
      ? `(**) *****-${telPart2}`
      : `(${telDDD}) ${telPart1}-${telPart2}`;

    return response;
  } else {
    return tel;
  }
}

export function unformmattedTaxNumber(taxNumber) {
  return taxNumber.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
}

export function getAvatarChar(name) {
  const nameArr = name.trim().split(' ');

  if (nameArr.length > 1) {
    return nameArr[0][0] + nameArr[1][0];
  }

  return nameArr[0][0];
}

export function getBankName(code) {
  const bank = banco_codigo.find((item) => item.value === code);

  const label = bank.label.split('-');

  return label[1].trim();
}

export const priceFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export function capitalizeFirstLetter(word) {
  if (!word) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function formatValueToMonetary(value) {
  if(!isNaN(value)){
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });
  }
}

export const hideTaxNumber = (taxNumber) => {
  if (taxNumber) {
    let originalTaxNumber = taxNumber.toString();
    let formattedTaxNumber;
    if (originalTaxNumber.length === 14) {
      formattedTaxNumber = `** *** ${originalTaxNumber.slice(
        5,
        8
      )} /**** - ${originalTaxNumber.slice(-2)}`;
    } else {
      formattedTaxNumber = `*** *** ${originalTaxNumber.slice(
        6,
        9
      )} - ${originalTaxNumber.slice(-2)}`;
    }
    return formattedTaxNumber;
  }
};