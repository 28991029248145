import React from 'react';
import { getImage } from '../../../services/getAssets';
import RecuperarSenha from '../../../components/login/recuperar-senha/recuperarSenha';
import { ToastContainer } from 'react-toastify';
import { ImageContainer, LoginContent, LoginFormContainer } from '../styles';

const RecuperarSenhaPage = () => {
  return (
    <>
      <LoginContent>
        <LoginFormContainer>
          <ImageContainer>
            <img src={getImage('logo.png')} alt="" />
          </ImageContainer>
          <RecuperarSenha/>
        </LoginFormContainer>
       <img src="/images/login-image.png" alt="" />
      </LoginContent>
      <ToastContainer limit={2} />
    </>
  );
};

export default RecuperarSenhaPage;
