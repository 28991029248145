import { apiV1 } from "../lib/axios";
import { toast } from "react-toastify";

export const sendTransactionMfa = async () => {
  await apiV1.post("/mfa")
      .catch((err) => {
        toast.error("Erro ao enviar o MFA",err);
        return;
      });
    return
}

export const sendNewAccountMfa = async (phoneNumber) => {
  try {
    const response = await apiV1.post("/send-phone-mfa-new-account", { phoneNumber })
    return response.data

  } catch (err) {
    toast.error("Erro ao enviar o MFA");
    return;
  }
}

export const mfaNewAccountVerify = async (phoneNumber, otp) => {
  try {
    const response = await apiV1.post("/validate-phone-mfa-new-account", { phoneNumber, otp })
    return response.data
  } catch (err) {
    return 'error ao validar o otp'
    return;
  }
}
