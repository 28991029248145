import styled from 'styled-components';

import { Button } from '../../../../components/button';

export const RepresentativeFormContent = styled.div`
  padding-top: 1.6875rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  span {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const RepresentativeFormInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  div {
    max-width: 18.75rem;
    width: 100%;

    input {
      width: 50%;
    }
   
  }
`;

export const ButtonAdd = styled(Button)`
  max-width: 18.5rem;
  width: 100%;
  align-self: ${({ align }) => align ?? 'flex-end'};

  display: flex;
  align-items: center;
  gap: 1rem;

  background: ${({ theme, variant }) =>
    variant === 'full' ? theme.colors['primary-color'] : 'transparent'};
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.colors['primary-color']};

  color: ${({ variant, theme }) =>
    variant === 'full' ? theme.colors.white : theme.colors['primary-color']};

  &:hover {
    background: ${({ theme }) => theme.colors['secondary-color']};
    border-color: ${({ theme }) => theme.colors['secondary-color']};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const DocumentTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  span {
    font-size: 0.875rem;
    font-weight: 600;
  }
`;

export const DocumentTypeContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
  }
`

export const Select = styled.select`
  width: 100%;
  max-height: 3.6875rem;
  height: 100%;
  padding: 0.625rem 0.75rem;
  background-color: ${({ theme }) => theme.colors['bg-input-color']};
  border: 1px solid ${({ theme }) => theme.colors['text-primary']};
  border-radius: 4px;

  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
