import { css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import { TbCircleCheck, TbKey, TbShare } from 'react-icons/tb';
import ReactModal from 'react-modal';
import BeatLoader from 'react-spinners/BeatLoader';
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from '../../../../hooks/useAuth';
import bankList from '../../../../services/bankList.json';
import {
  getQrcodeInfoFitBank,
  hideTaxNumber,
  identificarTipoChavePix,
  sendPixFitBank,
} from '../../../../services/pix.service';
import './Style.css';
import { formatValueToMonetary } from '../../../../utils/formatter';
import { sendTransactionMfa } from '../../../../services/mfa.service';
import { getImage } from '../../../../services/getAssets';
import moment from 'moment';
import { apiV1 } from '../../../../lib/axios';
import { NavLink } from 'react-router-dom';

const CopiaeColaModal = ({
  setMostrarCopiaeColaModal,
  mostrarCopiaeColaModal,
}) => {
  const [copiaecolaLoader, setCopiaecolaLoader] = useState(false);
  const [copiaecolaValue, setCopiaecolaValue] = useState('');
  const [copiaecolaResult, setCopiaecolaResult] = useState();
  const [copiaecolaResponse, setCopiaecolaResponse] = useState();
  const [receiverInfo, setReceiverInfo] = useState();
  const [mfaRequired, setMfaRequired] = useState(false);
  const [mfaCode, setMfaCode] = useState('');
  const [passwordRequired, setPasswordRequired] = useState(false);

  const [loader, setLoader] = useState(false);
  const [voucherUrl, setVoucherUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const pdfRef = useRef(null);
  const { user, getProfile } = useAuth();

  const override = css`
    display: flex;
    margin: 0 auto;
    border-color: red;
  `;

  const closeModal = () => {
    setMostrarCopiaeColaModal(false);
    setCopiaecolaValue('');
    setCopiaecolaResult('');
    setCopiaecolaResponse('');
    setPasswordRequired(false);
  };

  const lerCopiaeCola = async () => {
    try {
      if (user.taxNumber == '12345678900') {
        if (
          copiaecolaValue ==
          '00020126710014br.gov.bcb.pix0136ae42d0d8-b34c-4a8e-afc9-d89f6d43fb9e0209pagamento52040000530398654043.005802BR5925PARCELA X SOLUCOES EM PAG6009Sao Paulo61081360046062290525AE4C79AC5C4924730168F17E363049231'
        ) {
          setCopiaecolaResult({
            ReceiverBank: '450',
            ReceiverName: 'PARCELA X SOLUCOES EM PAGAMENTO LTDA',
            ReceiverTaxNumber: '22007952000170',
            valor: '3',
            chave: 'ae42d0d8-b34c-4a8e-afc9-d89f6d43fb9e',
          });
          return;
        } else {
          toast.error('Código copia e cola inválido');
          return;
        }
      }

      setCopiaecolaLoader(true);

      const qrCodeResponse = await getQrcodeInfoFitBank(copiaecolaValue);
      if (!qrCodeResponse.data) {
        toast.error('Código Copia e Cola Inválido');
        setCopiaecolaLoader(false);
        return;
      }
      console.log(qrCodeResponse);
      setReceiverInfo({
        bank: qrCodeResponse.data.ReceiverBank,
        name: qrCodeResponse.data.ReceiverName,
        taxNumber: qrCodeResponse.data.ReceiverTaxNumber,
        bankBranch: qrCodeResponse.data.ReceiverBankBranch,
        bankAccount: qrCodeResponse.data.ReceiverBankAccount,
        bankAccountDigit: qrCodeResponse.data.ReceiverBankAccountDigit,
        accountType: qrCodeResponse.data.ReceiverAccountType,
      });
      setCopiaecolaResult({
        ReceiverBank: qrCodeResponse.data.ReceiverBank,
        ReceiverName: qrCodeResponse.data.ReceiverName,
        ReceiverTaxNumber: qrCodeResponse.data.ReceiverTaxNumber,
        searchProtocol: qrCodeResponse.data.searchProtocol,
        valor:
          qrCodeResponse.data.FinalValue || qrCodeResponse.data.OriginalValue,
        chave: qrCodeResponse.data.PixKeyValue,
      });
      sendTransactionMfa();
      setCopiaecolaLoader(false);
      setPasswordRequired(true);
      return;
    } catch (err) {
      toast.error('Erro ao buscar informações do QRCode');
      setCopiaecolaLoader(false);
      return;
    }
  };

  const pagarCopiaeCola = async (otp) => {
    if (user.taxNumber === '12345678900') {
      setCopiaecolaResponse({
        ReceiverName: 'Teste Beta',
        ReceiverTaxNumber: '36742398000189',
        ReceiverBank: '260',
        valor: '10',
      });
      return;
    }

    try {
      const chave = copiaecolaResult.chave;
      const type = identificarTipoChavePix(chave);

      const body = {
        value: parseFloat(copiaecolaResult.valor),
        keyType: JSON.stringify(type),
        key: chave,
        searchProtocol: copiaecolaResult.searchProtocol,
        receiverInfo: receiverInfo,
        otp,
      };
      setCopiaecolaLoader(true);
      let pixResponse = await sendPixFitBank(body);
      getComprovante(buildCommonData(pixResponse.data.data, user));
      console.log('pixResponse ', pixResponse);

      if (pixResponse) {
        setCopiaecolaResponse(pixResponse.data.data);
        setCopiaecolaResult('');
      }
      setCopiaecolaLoader(false);
      setPasswordRequired(false);
      return;
    } catch (err) {
      toast.error('Erro ao realizar a transferência');
      setCopiaecolaLoader(false);
      return;
    }
  };

  useEffect(() => {
    getProfile();
  }, []);
  async function getComprovante(data) {
    await apiV1.post('/voucher/generate', data).then((response) => {
      const res = response.data.data;

      localStorage.removeItem('comprovante');
      localStorage.setItem('comprovante', res.base64);
    });
  }
  const handleError = (error) => {
    console.error('Erro ao gerar ou buscar o comprovante:', error);
    toast.error('Erro ao gerar ou buscar o comprovante.');
  };
  const buildCommonData = (transactionData, user) => {
    if (!transactionData) {
      console.error('transactionData não está definido ou é inválido!');
      throw new Error('transactionData deve ser um objeto válido.');
    }
    if (!user) {
      console.error('Usuário não está definido!');
      throw new Error('Usuário deve ser fornecido.');
    }

    const formatTaxNumber = (taxNumber) => String(taxNumber).replace(/\D/g, '');
    const rawValue = transactionData.value;
    const value = Math.abs(parseFloat(rawValue));
    if (isNaN(value)) {
      console.error('Valor inválido:', rawValue);
      throw new Error('Valor é obrigatório e deve ser um número.');
    }

    return {
      transactionId: String(transactionData.id),
      transferDate:
        transactionData.type === 'pix'
          ? transactionData.createdAt
          : transactionData.transferDate || new Date().toISOString(),
      type: 'pix',
      receiverName: transactionData.toName,
      receiverTaxNumber: formatTaxNumber(transactionData.toTaxNumber),
      receiverBank: String(transactionData.toBank),
      receiverBankBranch: String(transactionData.toBankBranch).padStart(4, '0'),
      receiverBankAccount: String(transactionData.toBankAccount),
      receiverBankAccountDigit: String(transactionData.toBankAccountDigit),
      receiverAccountType: transactionData.toAccountType || '',

      payerName: user.name,
      payerTaxNumber: formatTaxNumber(user.taxNumber),
      payerBank: String(user.account.bank),
      payerBankBranch: String(user.account.bankBranch),
      payerBankAccount: String(user.account.bankAccount),
      payerBankAccountDigit: String(user.account.bankAccountDigit),

      identifier: String(transactionData.id),
      value: value,
      codigoBarras: transactionData.codigoBarras || '',
      url_logo: getImage('logo-preto.png'),
    };
  };

  return (
    <ReactModal
      isOpen={mostrarCopiaeColaModal}
      className={'pix-modal'}
      overlayClassName={'overlay-pix-modal'}
    >
      <div className="copiaecola-modal-container">
        {!copiaecolaResponse ? (
          <>
            <div className="col-md-5">
              {passwordRequired ? (
                <div>
                  <label>Código MFA</label>
                  <div className="input-area">
                    <div className="input-area-icon col-md-1">
                      <TbKey size={18} />
                    </div>
                    <div className="col-md-11">
                      <input
                        type="text"
                        placeholder="Insira o código MFA"
                        value={mfaCode}
                        onChange={(e) => {
                          setMfaCode(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <label>Pix Copia e Cola</label>
                  <div className="input-area">
                    <div className="input-area-icon col-md-1">
                      <TbKey size={18} />
                    </div>
                    <div className="col-md-11">
                      <input
                        value={copiaecolaValue}
                        onChange={(e) => {
                          setCopiaecolaValue(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="button-area">
                <button className="cancel-button" onClick={closeModal}>
                  Cancelar
                </button>

                {!copiaecolaResult ? (
                  <button
                    className="confirm-button"
                    onClick={copiaecolaLoader ? () => {} : lerCopiaeCola}
                  >
                    {copiaecolaLoader ? (
                      <BeatLoader
                        color="var(--text-primary)"
                        loading={copiaecolaLoader}
                        css={override}
                        size={20}
                      />
                    ) : (
                      'Consultar'
                    )}
                  </button>
                ) : (
                  <button
                    className="confirm-button"
                    value={mfaCode}
                    onClick={() => pagarCopiaeCola(mfaCode)}
                  >
                    {copiaecolaLoader ? (
                      <BeatLoader
                        color="white"
                        loading={copiaecolaLoader}
                        css={override}
                        size={20}
                      />
                    ) : (
                      'Confirmar'
                    )}
                  </button>
                )}
              </div>
            </div>

            <div className="col-md-5">
              {copiaecolaResult && (
                <>
                  <div>
                    <h1>Resumo da Transferência</h1>
                  </div>

                  <div>
                    <div className="receipt-items">
                      <label>Nome</label>
                      <h4>{copiaecolaResult.ReceiverName}</h4>
                    </div>

                    <div className="receipt-items">
                      <label>CPF / CNPJ</label>
                      <h4>{copiaecolaResult.ReceiverTaxNumber}</h4>
                    </div>

                    <div className="receipt-items">
                      <label>Banco</label>
                      <h4>
                        {copiaecolaResult.ReceiverBank} -{' '}
                        {
                          bankList.filter(
                            (item) => item.code == copiaecolaResult.ReceiverBank
                          )[0]?.name
                        }
                      </h4>
                    </div>

                    <div className="receipt-items">
                      <label>Valor</label>
                      <h4>
                        {formatValueToMonetary(Number(copiaecolaResult.valor))}
                      </h4>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div>
            <div className="receipt-header">
              <TbCircleCheck color="#32D16D" size={75} />
              <h1>Transferência Pix realizada com sucesso!</h1>
            </div>

            <div>
              <button className="close-button" onClick={closeModal}>
                X
              </button>
            </div>

            <div className="receipt-body">
              <div className="d-flex">
                <div className="col-md-4">
                  <label>Nome</label>
                  <p>{copiaecolaResponse?.toName}</p>
                </div>
                <div className="col-md-4">
                  <label>Instituição de Destino</label>
                  <p>
                    {copiaecolaResponse?.toBank} -{' '}
                    {
                      bankList.filter(
                        (item) => item.code == copiaecolaResponse?.toBank
                      )[0]?.name
                    }
                  </p>
                </div>
                <div className="col-md-4">
                  <label>CPF/CNPJ do Favorecido</label>
                  <p>{hideTaxNumber(copiaecolaResponse?.toTaxNumber)}</p>
                </div>
              </div>
            </div>

            <div className="receipt-footer">
              <NavLink
                to="/comprovante"
                target="_blank"
                className="share-button"
              >
                Compartilhar comprovante
                <TbShare size={16} />
              </NavLink>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </ReactModal>
  );
};

export default CopiaeColaModal;
