import { Box } from "../../../../components/box";
import { useChargeTicket } from "../../../../hooks/useChargeTicket";
import { FiShare2 } from "react-icons/fi";
import { useTicket } from "../../../../hooks/useTicket";
import { NavLink } from "react-router-dom";

export function CobrarBoletoSucesso() {
  const { ticketValue, ticketRecipientName, ticketTaxNumber } = useChargeTicket()
  const { emitedTicketCod } = useTicket()

  return (
    <Box 
      icon='boletoEnviado'
      title='Boleto enviado com Sucesso'
      description='Seu boleto está sendo emitido, aguarde.'
    >
      <div className='step3-content'>
        <div className='step3-content-infos'>
          <div className='step3-content-info'>
            <div className='info-item'>
              Valor
              <span>R$: {String(Number(ticketValue).toFixed(2)).replaceAll('.', ',')}</span>
            </div>

            <div className='info-item'>
              Código de Boleto
              <span>{emitedTicketCod}</span>
            </div>
          </div>

          <div className='step3-content-info'>
            <div className='info-item'>
              Nome do Favorecido
              <span>{ticketRecipientName}</span>
            </div>

            <div className='info-item'>
              CPF/CNPJ do Favorecido
              <span>{ticketTaxNumber}</span>
            </div>
          </div>
        </div>

        <div className='step-buttons step3-buttons'>
          <NavLink to='/boleto-emitido' target='_blank' className='step-button'>
            Compartilhar comprovante
            <FiShare2 size={16} />
          </NavLink>
        </div>
      </div>
    </Box>
  )
}