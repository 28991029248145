import styled from "styled-components";

import { Button } from "../../../../../components/button";

export const BoxActions = styled.div`
  margin-top: 2.75rem;
  max-width: 23.5rem;
  width: 100%;
  align-self: flex-end;

  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ActionsButton = styled(Button)`
  width: 100%;
  background: ${({ theme, variant }) =>
    variant === 'full' ? theme.colors['primary-color'] : 'transparent'};
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.colors['primary-color']};

  color: ${({ variant, theme }) =>
    variant === 'full' ? theme.colors.white : theme.colors['primary-color']};

  &:hover {
    background: ${({ theme }) => theme.colors['secondary-color']};
    border-color: ${({ theme }) => theme.colors['secondary-color']};
    color: ${({ theme }) => theme.colors.white};
  }
   &:disabled {
    cursor: not-allowed;
    opacity: 0.6; /* Altere o estilo conforme necessário */
  }

  &:disabled:hover {
    background: ${({ theme, variant }) =>
      variant === 'full' ? theme.colors['primary-color'] : 'transparent'};
    border-color: ${({ theme }) => theme.colors['primary-color']};
    color: ${({ theme, variant }) =>
      variant === 'full' ? theme.colors.white : theme.colors['primary-color']};
  }
`;