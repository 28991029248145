import styled from "styled-components";
import { Button } from "../../../components/button";

export const LoginMFAContainer = styled.div`
  margin-top: 4rem;

  &, form {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
`;

export const LoginMFAHeader = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
  }
`;

export const LoginMFAActions = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
`;

export const LoginMFAButton = styled(Button)`
  background: ${({ theme }) => theme.colors["primary-color"]};
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  width: 100%;

  &:hover {
    background: ${({ theme }) => theme.colors["secondary-color"]};
  }
`;
