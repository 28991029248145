import React, { useEffect } from 'react'
import { getImage } from '../../services/getAssets'
import { Link } from 'react-router-dom'
import { MdKeyboardArrowRight } from 'react-icons/md'
import './Style.css'
import {ContactFormPage} from "../../pages/pagina-contact-form-page/ContactFormPage";

const PaginaContato = () => {

  return (
    <div className='pagina-contato'>
      <div className='pagina-contato-container col-md-10'>
        <div className="header">
          <div className="col-xl-12">
            <label>
              <Link to={"/pagina-principal"}>
                Pagina inicial
                <MdKeyboardArrowRight size={20} />
              </Link>
              Contato
            </label>
            <p>Contato</p>
          </div>
        </div>

        <div className='body'>

          <div className='logo-area'>
            <img src={getImage('logo.png')}/>
          </div>


          <ContactFormPage />
        </div>
      </div>
    </div>
  )
}

export default PaginaContato
