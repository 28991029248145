import React from "react";
import './Style.css';
import { TbCircleKey, TbClipboardCheck, TbCopy, TbShare, TbMeteor } from "react-icons/tb";
import { pixTypes } from "../../../../services/enums";
import { useState } from "react";
import { InfoStatusButton } from "./InfoStatusButton";

const PixKeyCard = ({ tipo , chave , id}) => {
  const [Id, setId] = useState(id);
  const [isCopied, setIsCopied] = useState(false)

  const getTypeDescription = () =>{
    const typeResult = pixTypes.filter((type)=> type.key == tipo);
    const type = typeResult[0].value;

    return type;
  }

  function copiarTexto() {
    const campoTexto = document.createElement("textarea");
    campoTexto.value = chave;

    document.body.appendChild(campoTexto);

    campoTexto.select();
    campoTexto.setSelectionRange(0, 99999);

    document.execCommand("copy");

    document.body.removeChild(campoTexto);

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false)
    }, 3000);

  }

  getTypeDescription();
  return (
    <div className="pix-key-card col-md-10">
      <div className="col-md-1 key-icon">
        <TbCircleKey size={40} />
      </div>
      <div className="col-md-7 key-infos">
        <div className="info-header">
          <h2>{getTypeDescription()}</h2>
          {/* <span className="separator">-</span>
          <div className="info-status-title">Status:</div>
          <div className="info-status-value">Ativa</div>
          <div className="info-status-button">
            <InfoStatusButton onClick={()=>{}} label={'enviar código de confirmação'}/>
          </div> */}
        </div>
        <h1>{chave}</h1>
      </div>
      <div className="col-md-2"/>
      <div className="col-md-2 key-share">
           { isCopied
            ? (
              <TbClipboardCheck color='#00875F' size={24}/>
            )
            : (
              <TbCopy size={24}  onClick={copiarTexto}/>
            )}
        {/* <TbShare id={id} size={24} onClick={()=> {}}/> */}
      </div>
    </div>
  );
};

export default PixKeyCard;
