import { PageHeader } from '../../../components/page-header'
import { useTicket } from '../../../hooks/useTicket'
import { useEffect } from 'react'
import { BeatLoader } from 'react-spinners'
import { css } from "@emotion/react";
import { CobrarBoletoInfos } from './cobrar-boleto-infos'
import { CobrarBoletoReceiveInfos } from './cobrar-boleto-receive-infos'
import { CobrarBoletoSucesso } from './cobrar-boleto-sucesso'

import './styles.css'

export function CobrarComBoleto() {
  const { ticketLoader, step, setStep } = useTicket()

  useEffect(() => {
    setStep(1)
  }, [])

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-top: 3em;
  `;

  return (
    <div className='cobrar-boleto-container'>
      <PageHeader 
        title='Cobrar via boleto'
        pages={[
          { name: 'Boleto', link: '/pagina-boleto' },
          { name: 'Cobrar boleto', link: '' },
        ]}
      />

      {(step === 1 && !ticketLoader) && (
        <CobrarBoletoInfos />
      )}

      {(step === 2 && !ticketLoader) && (
        <CobrarBoletoReceiveInfos />
      )}

      {(step === 3 && !ticketLoader) && (
        <CobrarBoletoSucesso />
      )}

      {ticketLoader && (
        <div className="loader-div">
          <BeatLoader
            color={"var(--secondary-color)"}
            loading={ticketLoader}
            css={override}
            size={20}
          />
        </div>
      )}
    </div>
  )
}