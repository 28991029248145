import './styles.css'

export function Contato({ avatar, name, bank }) {
  return (
    <div className="contato-container">
      <div className='contato-avatar'>
        {avatar}
      </div>

      <div className="contato-infos">
        <strong>{name}</strong>
        <span>{bank}</span>
      </div>
    </div>
  ) 
}