import ReactModal from "react-modal"
import React, { useState, useEffect } from 'react';
import { BoxModal } from "../../../../../components/boxModal"
import { ActionsButton, BoxActions } from "./styles"
import { useRegister } from "../../../../../hooks/useRegister"

import './styles.css'


export const ConfirmModal = ({ isOpen, closeModal, event }) => {
  const { loading } = useRegister();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    if (isOpen) {
      setIsButtonDisabled(true);
      setCounter(5);
      const timer = setTimeout(() => {
        setIsButtonDisabled(false);
      }, 5000);

      const interval = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter > 1) {
            return prevCounter - 1;
          }
          clearInterval(interval);
          return 0;
        });
      }, 1000);

   
      return () => {
        clearTimeout(timer);
        clearInterval(interval);
      };
    }
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      className={"change-modal"}
      overlayClassName={"overlay-change-password-modal"}
    >
      <BoxModal
        title='Confirmação'
        description='Se já validou seus dados clique em prosseguir para finalizar seu cadastro.'
        maxWidth={680} 
        close={closeModal}
      >
        {counter > 0 && <p>Aguarde {counter} segundos para prosseguir.</p>}
        <BoxActions>
          <ActionsButton type="button" onClick={closeModal} disabled={loading}>Cancelar</ActionsButton>
          {loading ? (
            <ActionsButton variant="full" disabled>Concluindo...</ActionsButton>
          ) : (
            <ActionsButton 
              variant="full" 
              onClick={event} 
              disabled={isButtonDisabled}
            >
              Concluir
            </ActionsButton>
          )}
        </BoxActions>
      </BoxModal>
    </ReactModal>
  );
};