import { toast } from "react-toastify";
import { apiV1 } from "../lib/axios";

export const sendTedFitBank = async (data) => {
  try {
    const response = await apiV1.post("/ted/send", data);
    return response; 
  } catch (err) {
    const message = err.response?.data?.message || "Ocorreu um erro ao enviar a TED. Tente novamente mais tarde.";
    toast.error(message);
    return { error: true, message: `${message}` };
  }
};

export const sendMfaTedFitBank = async () => {
  await apiV1.post("/mfa")
    .catch((err) => {
      toast.error("Erro ao enviar o MFA",err);
      return;
    });
  return
}