import ReCAPTCHA from "react-google-recaptcha";

import { useRef, useState } from "react";
import { Input } from '../../../components/Input';
import { TbKey, TbUser } from "react-icons/tb";
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form';
import { z } from 'zod'

import { ForgotPasswordLink, FormContainer, InputContainer, LoginButton, LoginButtonArea } from "./styles"
import { useAuth } from "../../../hooks/useAuth";
import { Link } from "react-router-dom";

const loginFormSchema = z.object({
  taxNumber: z.string().max(14, 'O campo CPF/CNPJ aceita no máximo 14 digitos.'),
  password: z.string(),
})

export const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: zodResolver(loginFormSchema),
  })

  const recaptchaRef = useRef(null);

  const { login } = useAuth();

  const handleLogin = async (data) => {
    const recaptchaToken = recaptchaRef.current.getValue();

    await login(data.taxNumber, data.password, '', recaptchaToken);
  }

  return (
    <FormContainer onSubmit={handleSubmit(handleLogin)}>
      <InputContainer>
        <Input
          type="text"
          label="CPF/CNPJ"
          icon={<TbUser />}
          maxLength={14}
          onlyNumber
          register={register('taxNumber')}
          isRequired={true}
        />
        {errors.taxNumber && <span>{errors.taxNumber.message}</span>}
      </InputContainer>
      <InputContainer>
        <Input
          type="password"
          label="Senha"
          icon={<TbKey />}
          register={register('password')}
          isRequired={true}
        />
        {errors.password && <span>{errors.password.message}</span>}
      </InputContainer>
      <ReCAPTCHA
        theme={process.env.REACT_APP_COMPANY_THEME}
        className="recaptcha"
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      />

      { isSubmitting
      ? (
        <LoginButtonArea>
          <LoginButton type='submit' disabled>
            Acessando...
          </LoginButton>
        </LoginButtonArea>
      ) : (
        <LoginButtonArea>
          <LoginButton type='submit'>
            Acessar
          </LoginButton>

          <ForgotPasswordLink>
            <Link to={'/esqueci-senha'}>Esqueci a senha</Link>
          </ForgotPasswordLink>
        </LoginButtonArea>
      )}
    </FormContainer>
  )
}
