import { Input } from '../../../../components/Input'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { Box } from '../../Box'
import { DocumentTypeContainer, DocumentTypeContent, DocumentsFormContent, DocumentsFormInputs, InputContainer } from './style'
import { useRegister } from '../../../../hooks/useRegister'
import { useState } from 'react'

const DocumentsFormSchema = z.object({
  documentFront: z.any(),
  documentBack: z.any(),
})

export const DocumentsForm = () => {
  const [documentType, setDocumentType] = useState('RG');

  const { postDocumentsFormPf, setStep } = useRegister();

  const { 
    register, 
    handleSubmit, 
    setError,
    formState: { errors, isSubmitting } 
  } = useForm({
    resolver: zodResolver(DocumentsFormSchema)
  })

  const handleChangeDocumentType = (event) => {
    setDocumentType(event.target.value);
  };

  const validateFile = (name, value) => {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
    
    if (!value) {
      setError(name, { type: 'manual', message: 'Por favor, escolha um arquivo.' });
      return false;
    }

    if (!allowedTypes.includes(value.type)) {
      setError(name, { type: 'manual', message: 'Tipo de arquivo não permitido.' });
      return false;
    }

    return true;
  };

  const handleNext = async (data) => {
    if (documentType === 'CNH') {
      data.documentBack = data.documentFront
    }

    const validFornt = validateFile('documentFront', data.documentFront.item(0))
    const validBack = validateFile('documentBack', data.documentBack.item(0))

    if (validFornt && validBack) {
      await postDocumentsFormPf(documentType, data);
    }
  }

  const handleBack = () => {
    setStep(3);
  }

  return (
    <Box
      handleSubmit={handleSubmit}
      submitEvent={handleNext}
      title="Preencha com seus documentos."
      description="Para prosseguir precisamos de alguns documentos, aceitamos somente arquivos com extensão .jpeg, .jpg, .png e .pdf"
      secondDescription="É importante que antes de enviar os documentos verifique alguns pontos como: "
      pointList={[
        'Evite luzes que podem impedir a visibilidade do documento.', 
        'Remova acessórios como capas plásticas de proteção.', 
        'Rasuras e partes danificadas podem impedir a leitura do documento.', 
        'Em caso de documentos digitais é necessário que o mesmo contenha o QR para validação.',
        'Evite documentos como RG digitalizados, bata uma foto legível do documento e o envie.',
        'Evite mandar frente e verso juntos no mesmo arquivo.',
        'Verifique a validade do seu documento de RG, aceitamos o documento com até 10 anos.',
      ]}
      buttonOne="Voltar"
      buttonTwo="Finalizar"
      buttonTwoLoading="Finalizando..."
      marginLeft="l"
      handleBack={handleBack}
    >
      <DocumentsFormInputs>
        <DocumentsFormContent>
          <span>Documentação</span>

          <DocumentTypeContainer>
            <span>Tipo de documento</span>

            <DocumentTypeContent>
              <label>
                <input
                  type="radio"
                  value="RG"
                  checked={documentType === 'RG'}
                  onChange={handleChangeDocumentType}
                />
                RG
              </label>

              <label>
                <input
                  type="radio"
                  value="CNH"
                  checked={documentType === 'CNH'}
                  onChange={handleChangeDocumentType}
                />
                CNH
              </label>
            </DocumentTypeContent>
          </DocumentTypeContainer>

          <InputContainer>
            {
              documentType === 'RG'
              ? (
                <>
                  <Input 
                    type="file"
                    accept=".jpg, .jpeg, .png, .pdf"
                    label="RG - Somente Frente"
                    description="Evite enviar o documento digitalizado"
                    error={errors.documentFront && errors.documentFront.message}
                    isRequired
                    register={register('documentFront', {
                      required: true,
                    })}
                  />

                  <Input 
                    type="file"
                    accept=".jpg, .jpeg, .png, .pdf"
                    label="RG - Somente Verso"
                    description="Evite enviar o documento digitalizado"
                    error={errors.documentBack && errors.documentBack.message}
                    isRequired
                    register={register('documentBack', {
                      required: true,
                    })}
                  />
                </>
              ) : (
                <>
                  <Input 
                    type="file"
                    accept=".jpg, .jpeg, .png, .pdf"
                    label="CNH"
                    error={errors.documentFront && errors.documentFront.message}
                    isRequired
                    register={register('documentFront', {
                      required: true,
                    })}
                  />
                </>
              )
            }
          </InputContainer>
        </DocumentsFormContent>
      </DocumentsFormInputs>
    </Box>
  )
}