import React from 'react';
import './Style.css';
import moment from 'moment';
import { MdKeyboardArrowRight } from 'react-icons/md';
import bankList from '../../../../services/bankList.json';
import 'moment/locale/pt-br';

export default function (props) {
  const data = Array.isArray(props.data) ? props.data : [props.data];
  const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));

  const hideTaxNumber = (taxNumber) => {
    if (taxNumber) {
      let formattedTaxNumber;
      if (taxNumber.length === 14) {
        formattedTaxNumber = `CNPJ ** *** ${taxNumber.slice(
          5,
          8
        )} /**** - ${taxNumber.slice(-2)}`;
      } else {
        formattedTaxNumber = `CPF *** *** ${taxNumber.slice(
          6,
          9
        )} - ${taxNumber.slice(-2)}`;
      }
      return formattedTaxNumber;
    }
    return '';
  };

  const formatValue = (value, status) => {
    return status === 'Recebido' ? `R$${value}` : `- R$ ${value}`;
  };

  return (
    <div className="historico-pix-list">
      {sortedData.map((item, index) => (
        <div key={index} className="historico-pix-item">
          <div className="col-xl-1">
            <div className="circle"></div>
            <div className="column"></div>
          </div>

          <div className="col-xl-4 name-area">
            <div>
              <h2>{item.name}</h2>
              <p>{moment(item.date).format('DD [de] MMMM [às] HH:mm')}</p>
            </div>
          </div>

          <div className="col-xl-3 taxnumber-area">
            <p>{hideTaxNumber(item.taxNumber)}</p>
            <p>
  {props.data.bank} -{' '}
  {
    bankList.filter((item) => String(item.code) === String(props.data.bank))[0]?.name 
  }
</p>
          </div>

          <div className="col-xl-3">
            <div className="value-area">
              <h2>{formatValue(item.value, item.status)}</h2>
              {/* <div className="d-flex align-items-center">
                                <p>Ver comprovante</p>
                                <MdKeyboardArrowRight size={16} />
                            </div> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
