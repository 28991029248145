import { MdKeyboardArrowRight } from 'react-icons/md'
import { ItemMark } from '../item-mark'

import './styles.css'

export function BoletoItem({ name, date, cpf, bank, value }) {
  return (
    <div className="boletos-item">
      <div className="boletos-item-content">
        <ItemMark />

        <div className="boletos-item-info">
          <div className="item-info-principal">
            {name}
            <span>{date}</span>
          </div>

          <div  className="item-info-secondary">
            CPF/CNPJ - {cpf}
            <span>{bank}</span>
          </div>
        </div>
      </div>

      <div className="boletos-item-actions">
        <span>R$ {String(Number(value).toFixed(2)).replaceAll('.', ',')}</span>
        <button disabled title='Em breve'>
          Ver Status 
          <MdKeyboardArrowRight size={16} />  
        </button>
      </div>
    </div>
  )
}