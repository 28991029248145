import React from "react";
import "./Style.css";

const LimitsMessage = (props) => {
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  return (
    <div className="limits-message">
      <label>* Limite máximo atual: {formatCurrency(props.limite)}</label>
    </div>
  );
};

export default LimitsMessage;
