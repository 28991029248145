import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const CompleteRegisterContainer = styled.div`
  color: ${({ theme }) => theme.colors.black};
  max-width: 39.75rem;
  height: 39.75rem;
  padding: 3.25rem 4.375rem;
  background: ${({ theme }) => theme.colors["bg-color"]};
  border: 0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.375rem;
  position: relative;
`;

export const CompleteRegisterContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  text-align: center;

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  p, span {
    font-size: 0.875rem;
    font-weight: 400;
  }
`;

export const CompleteRegisterClose = styled(NavLink)`
  position: absolute;
  top: 18px;
  right: 17px;

  color: ${({ theme }) => theme.colors.black};

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`
