import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

export const LoginContent = styled.div`
  max-width: 68.75rem;
  max-height: 39.75rem;
  width: 96%;
  height: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  background: ${({ theme }) => theme.colors['bg-color']};
  border: 0;
  border-radius: 12px;

  > img {
    width: 34.375rem;
    height: 39.75rem;
    border-radius: 0 12px 12px 0;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    max-width: 100%;
    max-height: auto;
    border-radius: 12px;

    > img {
      display: none;
    }
    
  }
`;

export const LoginFormContainer = styled.div`
   padding: 2rem 3.4375rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 480px) {
    padding: 1rem;
  }
`;

export const ImageContainer = styled.div`
  height: 10rem;
  width: 90%;
  max-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: 480px) {
    max-width: 70%;
    max-height: 70%;
    margin-top: 1rem;
    #logo{
      width: 80%;
    }
  }
`;

export const NewUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  margin-top: 1.5rem;

  @media (max-width: 480px) {
    margin-top: 1rem;
  }
`;

export const NewUserContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.875rem;

  div {
    max-width: 27.4375rem;

    h3 {
      font-size: 0.875rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
`;

export const NewUserButton = styled(NavLink)`
  all: unset;
  padding: 0.5rem 0;
  border: 1px solid ${({ theme }) => theme.colors['primary-color']};
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;

  font-size: 1rem;
  font-weight: 600;

  width: 11.5rem;
  color: ${({ theme }) => theme.colors['primary-color']};

  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors['secondary-color']};
  }

  @media (max-width: 480px) {
    padding: 0.5rem 0.75rem;
  }
`;
