import './styles.css'

export function Comprovante() {
  const ticketPdfBase64 = localStorage.getItem('comprovante')

  return (
    <div className="pdfContainer">
      <iframe src={ticketPdfBase64} width='100%' height='100%'></iframe>
      <h1>teste</h1>
    </div>
  )
}