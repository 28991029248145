import { useState, useRef, forwardRef, useImperativeHandle } from "react";
import './styles.css';

const INTERVAL_DURATION_IN_SECONDS = 30;

export const ResendConfirmationCode = forwardRef((props, ref) => {
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timer, setTimer] = useState('00:00');

  const IntervalRef = useRef(null);

  const getInterval = () => {
    let currentTime = new Date();
    currentTime.setSeconds(currentTime.getSeconds()+INTERVAL_DURATION_IN_SECONDS);
    return currentTime
  }
  function StopTimer(){
    if (IntervalRef.current){
      clearInterval(IntervalRef.current);
    }
    setIsTimerActive(false);
  }

  useImperativeHandle(ref, ()=>({
    StopTimer,
  }));

  const resetTimer = (interval) => {
    StopTimer();
    setTimer(`00:${INTERVAL_DURATION_IN_SECONDS}`)
    setIsTimerActive(true);
    const counter = setInterval(()=>{
      startTimer(interval);
    },1000);
    IntervalRef.current = counter;
  }

  const startTimer = (interval) => {
    let { total, minutes, seconds }= getTimeRemaining(interval);
    if (total >= 0) {
      const formattedMinutes = (minutes > 9 ? minutes : `0${minutes}`);
      const formattedSeconds = (seconds > 9 ? seconds : `0${seconds}`);
      setTimer(`${formattedMinutes}:${formattedSeconds}`);
    }else {
      StopTimer();
    }

  }

  const getTimeRemaining = (interval) => {
    const total = Date.parse(interval) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
        total, minutes, seconds
    };
  }

  const onClickToResend = () => {
    resetTimer(getInterval());
    props.onClick()
  }

  return (
    <div>
      {!isTimerActive && (
        <div className="resend-container">
          <div className="title">não recebeu seu código de confirmação?</div>
          <a className="btn-resend" href="#" onClick={onClickToResend}>
              enviar novamente!
          </a>
        </div>
      )}
      {isTimerActive && (
        <div className="resend-container timer-container">
          <div className="title"><strong>Código enviado!</strong></div>
          <div className="timer-content">
            <div className="subtitle">Caso não tenha recebido, tente novamente em:</div>
            <div className="timer">{timer}</div>
          </div>
        </div>
      )}

    </div>
  );
});
