import styled from 'styled-components';
import { Button } from '../../../components/button';
import { NavLink } from 'react-router-dom';

const boxMarginLeft = {
  m: '4.1875rem',
  l: '6.6875rem',
};

export const BoxContainer = styled.div`
  
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  margin-right: ${({ $marginLeft }) => boxMarginLeft[$marginLeft]};

  overflow-y: auto;
`;

export const BoxHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h1,
  p,
  ul {
    margin-bottom: 0;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  p {
    font-size: 0.875rem;
    font-weight: 400;
  }

  ul {
    li {
      font-size: 0.875rem;
      font-weight: 600;
    }
  }
`;

export const BoxContent = styled.div`
  /* height: 20.8125rem; */
`;

export const BoxActions = styled.div`
  margin-top: 2.75rem;
  max-width: 23.5rem;
  width: 100%;
  align-self: flex-end;

  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ActionsButton = styled(Button)`
  width: 100%;
  background: ${({ theme, variant }) =>
    variant === 'full' ? theme.colors['primary-color'] : 'transparent'};
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.colors['primary-color']};

  color: ${({ variant, theme }) =>
    variant === 'full' ? theme.colors.white : theme.colors['primary-color']};

  &:hover {
    background: ${({ theme }) => theme.colors['secondary-color']};
    border-color: ${({ theme }) => theme.colors['secondary-color']};
    color: ${({ theme }) => theme.colors.white};
  }
`;
