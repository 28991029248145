import { useRegister } from "../../../hooks/useRegister"
import { ActionsButton, BoxActions, BoxContainer, BoxContent, BoxHeader } from "./styles"

export const Box = ({ 
  handleSubmit, 
  submitEvent, 
  children, 
  title, 
  description, 
  secondDescription, 
  pointList,
  buttonOne, 
  buttonTwo, 
  buttonTwoLoading,
  marginLeft,
  handleBack,
}) => {
  const { loading } = useRegister();

  return (
    <BoxContainer $marginLeft={marginLeft} onSubmit={handleSubmit(submitEvent)}>
      <BoxHeader>
        <h1>{title}</h1>
        {description && <p>{description}</p>}
        {secondDescription && <p>{secondDescription}</p>}
        {pointList && (
          <ul>
            {pointList.map(item => <li key={item}>{item}</li>)}
          </ul>
        )}
      </BoxHeader>

      <BoxContent>
        {children}
      </BoxContent>

      <BoxActions>
        <ActionsButton type="button" disabled={loading} onClick={handleBack}>{buttonOne}</ActionsButton>

        {loading 
        ? <ActionsButton type="submit" disabled={loading} variant="full">{buttonTwoLoading}</ActionsButton> 
        : <ActionsButton type="submit" disabled={loading} variant="full">{buttonTwo}</ActionsButton>}
      </BoxActions>
    </BoxContainer>
  )
}