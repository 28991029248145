import styled from "styled-components";

    export const PopUpDivMain = styled.div`
    border-radius: 10px;
    margin-top: -43px;

    & input, select {
      background: var(--bg-color) !important;
      color: var(--text-primary) !important;
      border-radius: 4px;
    }
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  margin-bottom: 60px;
`;

export const ButtonExport = styled.button`
  background-color: ${({theme}) => theme.colors['filter-button-background'] };
  color: ${({theme}) => theme.colors['filter-button-color'] };
`;