import { toast } from "react-toastify";
import { getAll } from "./pix.service";

let CachedMethod;
let cachedMessage;
let responseFirstLenght = 0;
let cachedKeysData;
const FIELDS_TO_EXCLUDE = ['createdAt', 'updatedAt', 'id']
export let MethodToCache = ( method) => {
    CachedMethod = method;
}
export let MessageToCache = ( message) => {
    cachedMessage = message;
}
export let KeyDataToCache = ( keysData) => {
  cachedKeysData = responseFormatter(keysData, FIELDS_TO_EXCLUDE);
}

export const PullerGetAllPixKeyList = async (message, setIsWaitDBChange) => {

    const response = await getAll()
    const responseFormatted = responseFormatter(response, FIELDS_TO_EXCLUDE);
    if(responseFirstLenght === 0){
        responseFirstLenght = response.length;
    }

    if((JSON.stringify(cachedKeysData) === JSON.stringify(responseFormatted))){
        setTimeout(PullerGetAllPixKeyList, 2000);
    }else {
        toast.success(cachedMessage);
        CachedMethod(true);
        endPuller();
        return;
    }

}

function endPuller(){
    CachedMethod = undefined;
    cachedMessage = "";
    responseFirstLenght = 0;
    cachedKeysData = undefined;
}


function responseFormatter(arrayOriginal, camposRemover) {
  const arrayFormatado = arrayOriginal.map(item => {
    let novoItem = {};
    for (const chave in item) {
      if (!camposRemover.includes(chave)) {
        novoItem[chave] = item[chave];
      }
    }
    return novoItem;
  });
  return arrayFormatado;
}
