import styled from "styled-components";

export const PersonDataFormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  span {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const PersonDataFormInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  > div {
    max-width: 18.75rem;
    width: 100%;
  }
`;

export const Select = styled.select`
  width: 100%;
  max-height: 2.6875rem;
  height: 100%;
  padding: 0.625rem 0.75rem;
  background-color: ${({ theme }) => theme.colors['bg-input-color']};
  border: 1px solid ${({ theme }) => theme.colors['text-primary']};
  border-radius: 4px;

  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
