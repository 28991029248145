import styled from "styled-components";

export const RegisterPJContainer = styled.div`
  color: ${({ theme }) => theme.colors.black};
  max-width: 68.75rem;
  width: 100%;
  max-height: 39.75rem;
  padding: 4.1875rem 0 3.25rem 3.5rem;
  background: ${({ theme }) => theme.colors["bg-color"]};
  border: 0;
  border-radius: 12px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors['primary-color']};
    border-radius: 20px;
  }
`;

