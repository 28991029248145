import React, { useEffect, useState } from 'react';
import './Style.css';
import { TbKey, TbUser } from 'react-icons/tb';
import { ToastContainer, toast } from 'react-toastify';
import {
  changePassword,
  isPasswordStrong,
  verifyHash,
} from '../../../services/login.service';
import { Link } from 'react-router-dom';
import { InputContainer, LoginButton } from '../esqueci-senha/styles';
import { Input } from '../../Input';

const RecuperarSenha = () => {
  const [loginLoader, setLoginLoader] = useState(false);
  const [hash, setHash] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [code, setCode] = useState(0);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const handleChangePassword = async () => {
    if (password !== confirmPassword) {
      return toast.error('As senhas precisam ser iguais');
    }

    if (!isPasswordStrong(password)) {
      toast.error(
        'Senha muito fraca, a senha deve conter pelo menos 8 dígitos, incluindo números, símbolos, letras minúsculas e maiúsculas'
      );
      return '';
    }

    setLoginLoader(true);

    try {
      await changePassword(hash, password, confirmPassword, code);

      setLoginLoader(false);
      setPasswordChanged(true);
    } catch (err) {
      toast.error('Não foi possível redefinir a senha, tente novamente');
      setLoginLoader(false);
    }
  };

  useEffect(() => {
    const validateHash = async () => {
      const hashEncoded = window.location.pathname.split('/').pop();

      const hashDecoded = decodeURIComponent(hashEncoded);

      setHash(hashDecoded);

      const { ok } = await verifyHash(hashDecoded);

      if (!ok) {
        toast.error('Solicitação Inválida ou Expirada');

        setTimeout(() => {
          document.location.href = '/';
        }, 2000);
      }
    };
    validateHash();
  }, []);

  return (
    <>
      {!passwordChanged ? (
        <div className="d-flex flex-column gap-3">
          <h1>Redefinição de Senha</h1>
          <InputContainer>
            <Input
              type="password"
              label="Senha"
              icon={<TbUser />}
              onChange={setPassword}
            />
          </InputContainer>
          <InputContainer>
            <Input
              type="password"
              label="Confirmar Senha"
              icon={<TbUser />}
              onChange={setConfirmPassword}
            />
          </InputContainer>
          <InputContainer>
            <Input
              type="number"
              label="Código de Verificação"
              icon={<TbKey />}
              onChange={setCode}
            />
          </InputContainer>
          {loginLoader ? (
            <LoginButton disabled>Confirmando...</LoginButton>
          ) : (
            <LoginButton onClick={handleChangePassword}>Confirmar</LoginButton>
          )}
        </div>
      ) : (
        <>
          <div className="col-md-12 login-area">
            <h1>Senha redefinida com sucesso!</h1>
            <h3>Você já pode voltar a acessar sua conta</h3>
            <Link to={'/'}>
              <button>Voltar</button>
            </Link>
          </div>
        </>
      )}
      <ToastContainer />
    </>
  );
};

export default RecuperarSenha;
