import React from "react";

import "../gerar-codigo-pix/Style.css"

import { Link } from "react-router-dom"

export default function () {
    return (
        <div className="gerar-codigo">
            <div className="container">
                <div className='pix row'>
                    <div className='col-xl-6'>
                        <p>Gerar Código</p>
                    </div>

                    <div className="col-xl-2">
                        <Link to={"/pagina-pix"}>
                            <button>Nova Transferência</button>
                        </Link>
                    </div>

                    <div className="gerar-codigo col-xl-2">
                        <Link to={"/gerar-codigo"}>
                            <button>Gerar Codigo</button>
                        </Link>
                    </div>
                </div>

                <div className="codigo">
                    <div className="row">
                        <div className="col-xl-6">
                            <h1>Gerar Código </h1>
                            <h2>Qual seria o valor ?</h2>

                            <div>
                                <p>Valor</p>
                                <input type="text" placeholder="Digite o valor" />
                            </div>
                        </div>

                        <div className="col-xl-6">
                            <div className="resumo-fatura">
                                <h1>Resumo da transferência</h1>

                                <p>Valor</p>
                                <h4>R$ 120,00</h4>

                                <h3>Código de deposito</h3>

                                <p>0002rfr6580014BR.GOV.BCB.PIX01frfr276d-9f71-49ba-
                                    9247-854644c6233e52040000frrf9fr040.20580referr4Emer
                                    son Rosa dos Santos6009SAO PAULO6108fr0900062070503*
                                    **6304A562</p>
                            </div>

                            <div>
                                <button>Copiar Codigo</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}