import QRCode from 'qrcode.react';
import './style.css';
import { EcheckTemplate, EcheckTemplateBody, EcheckTemplateContent, EcheckTemplateHeader, EcheckTemplateHeaderContent, EcheckTemplateHeaderIdLabel, EcheckTemplateHeaderImage, EcheckTemplateHeaderPrefixLabel, EcheckTemplateHeaderTextArea, EcheckTemplateBodyFieldsArea, EcheckTemplateBodyLabel, EcheckTemplateBodyValues, EcheckTemplateBodyValueFieldsArea, EcheckTemplateBodyValueField, EcheckTemplateBodySymbolValueField } from './styles';
import moment from 'moment/moment';
import { convertNumberToWords } from '../../utils/convertNumberToWords';
import { capitalizeFirstLetter } from '../../utils/formatter';
import { useEffect, useState } from 'react';
export default function (props) {
  const [logo, setLogo] = useState();
  const [backgroundImage, setBackgroundImage] = useState();

  useEffect(()=>{
    const companyKey = process.env.REACT_APP_COMPANY_KEY;
    const urlLogo = props.echeck.layout.logo;
    const logo = urlLogo?.replace('{{COMPANY_KEY}}', companyKey);
    setLogo(logo);
    const urlBackgroundImage = props.echeck.layout.backgroundImage;
    const backgroundImage = urlBackgroundImage?.replace('{{COMPANY_KEY}}', companyKey);
    setBackgroundImage(backgroundImage);
  }, [])

  function verifyValue(value) {
    const formattedNumber = parseFloat(value);

    if(typeof formattedNumber === 'number') {
      return formattedNumber.toFixed(2).replace('.',',');
    } 
    return "";
  }

  return (
    <EcheckTemplate>

      <EcheckTemplateContent 
        backgroundColor={props?.echeck?.layout?.backgroundColor}
        backgroundImageUrl={backgroundImage}
      >
        <EcheckTemplateHeader>
          <EcheckTemplateHeaderImage>
            <img src={logo} alt="Logo" />
          </EcheckTemplateHeaderImage>
          <EcheckTemplateHeaderContent barColor={props?.echeck?.layout?.barColor}>
            <EcheckTemplateHeaderTextArea>
                <EcheckTemplateHeaderPrefixLabel>No.</EcheckTemplateHeaderPrefixLabel>
                <EcheckTemplateHeaderIdLabel>{props?.echeck?.id || '-'}</EcheckTemplateHeaderIdLabel>
            </EcheckTemplateHeaderTextArea>
          </EcheckTemplateHeaderContent>
        </EcheckTemplateHeader>
        <EcheckTemplateBody>

          <EcheckTemplateBodyFieldsArea>

            <EcheckTemplateBodyValueField width={'100%'}>
              <EcheckTemplateBodyLabel labelColor={props?.echeck?.layout?.labelColor}>NOME COMPLETO:</EcheckTemplateBodyLabel>
              <EcheckTemplateBodyValues textColor={props?.echeck?.layout?.textColor}>{props?.echeck?.client?.name || ''}</EcheckTemplateBodyValues>
            </EcheckTemplateBodyValueField>

            <EcheckTemplateBodyValueField width={'25%'}>
              <EcheckTemplateBodyLabel marginleft={'1.8vw'} labelColor={props?.echeck?.layout?.labelColor}>DATA:</EcheckTemplateBodyLabel>
              <EcheckTemplateBodyValues 
                hasBorder={true} 
                textColor={props?.echeck?.layout?.textColor}
                borderColor={props?.echeck?.layout?.borderColor}
              >{props?.echeck?.createdAt ? moment(props?.echeck?.createdAt).format('DD/MM/YY') : ''}</EcheckTemplateBodyValues>
            </EcheckTemplateBodyValueField>

          </EcheckTemplateBodyFieldsArea>

          <EcheckTemplateBodyValueFieldsArea>

            <EcheckTemplateBodyValueField width={'100%'}>
              <EcheckTemplateBodyLabel labelColor={props?.echeck?.layout?.labelColor}> VALOR: </EcheckTemplateBodyLabel>
              <EcheckTemplateBodyValues 
                hasBorder={true} 
                textColor={props?.echeck?.layout?.textColor}
                borderColor={props?.echeck?.layout?.borderColor}
              >{props?.echeck?.value ? capitalizeFirstLetter(convertNumberToWords(props?.echeck?.value)) : ''}</EcheckTemplateBodyValues>
            </EcheckTemplateBodyValueField>

            <EcheckTemplateBodyValueField width={'25%'}>
              <EcheckTemplateBodySymbolValueField>R$</EcheckTemplateBodySymbolValueField>
              <EcheckTemplateBodyValues 
                hasBorder={true} 
                textColor={props?.echeck?.layout?.textColor}
                borderColor={props?.echeck?.layout?.borderColor}
              >{verifyValue(props?.echeck?.value) || ''}</EcheckTemplateBodyValues>
            </EcheckTemplateBodyValueField>

          </EcheckTemplateBodyValueFieldsArea>

          <EcheckTemplateBodyValueFieldsArea>

            <EcheckTemplateBodyValueField width={'100%'}>
              <EcheckTemplateBodyLabel labelColor={props?.echeck?.layout?.labelColor}>RE:</EcheckTemplateBodyLabel>
              <EcheckTemplateBodyValues 
                hasBorder={true} 
                textColor={props?.echeck?.layout?.textColor}
                borderColor={props?.echeck?.layout?.borderColor}
              >{props?.echeck?.account?.person?.legalName || ''}</EcheckTemplateBodyValues>
            </EcheckTemplateBodyValueField>

            <EcheckTemplateBodyValueField width={'25%'}>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
                <div style={{ marginRight: '1vw' }}>
                  <svg
                    style={{ height: '0.9vw', transform: 'scale(3.1)', marginTop: '1.1vw' }}
                    width="9"
                    height="13"
                    viewBox="0 0 9 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.79999 4.62H6.78V2.23999C6.78 0.99999 5.77 -0.0100098 4.53 -0.0100098C3.29 -0.0100098 2.28 0.99999 2.28 2.23999V4.62H2.26001C1.30001 4.62 0.51001 5.39999 0.51001 6.37V10.47C0.51001 11.43 1.29001 12.22 2.26001 12.22H6.79999C7.75999 12.22 8.54999 11.44 8.54999 10.47V6.37C8.54999 5.41 7.76999 4.62 6.79999 4.62ZM3.51001 2.24999C3.51001 1.68999 3.97 1.23 4.53 1.23C5.09 1.23 5.54999 1.68999 5.54999 2.24999V4.62999H3.51001V2.24999ZM7.32001 10.47C7.32001 10.76 7.08999 10.99 6.79999 10.99H2.26001C1.97001 10.99 1.73999 10.76 1.73999 10.47V6.37C1.73999 6.08 1.97001 5.84999 2.26001 5.84999H6.79999C7.08999 5.84999 7.32001 6.08 7.32001 6.37V10.47Z"
                      fill="#1E1E1E"
                    />
                    <path
                      d="M4.53003 6.59998C4.19003 6.59998 3.92004 6.86998 3.92004 7.20998V9.62998C3.92004 9.96998 4.19003 10.24 4.53003 10.24C4.87003 10.24 5.14005 9.96998 5.14005 9.62998V7.20998C5.14005 6.86998 4.87003 6.59998 4.53003 6.59998Z"
                      fill="#1E1E1E"
                    />
                  </svg>
                </div>
                <div style={{
                  margin: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  fontSize: '0.8vw',
                  color: '#1e1e1e',
                  fontWeight: 'bold',
                }}>
                  <div>SECURITY</div>
                  <div>FEATURED</div>
                  <div>INCLUDED</div>
                </div>
              </div>
            </EcheckTemplateBodyValueField>

          </EcheckTemplateBodyValueFieldsArea>

          <EcheckTemplateBodyValueFieldsArea>
              <div style={{
                color: `#000`,
                marginTop: '1.5vw',
                fontSize: '1.3vw',
                fontWeight: 'bold',
              }}>
                {props?.echeck?.identifier || ''}
              </div>
            <div style={{ marginTop: '0.5rem' }}>
              <QRCode value={props?.qrCodeUrl || ''} style={{maxWidth: '5vw', maxHeight: '5vw'}}/>
            </div>
          </EcheckTemplateBodyValueFieldsArea>
        </EcheckTemplateBody>
      </EcheckTemplateContent>
    </EcheckTemplate>
  );
}
