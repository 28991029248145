import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const RegisterContainer = styled.div`
  color: ${({ theme }) => theme.colors.black};
  max-width: 68.75rem;
  max-height: 39.75rem;
  padding: 3.25rem;
  background: ${({ theme }) => theme.colors["bg-color"]};
  border: 0;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  text-align: center;
`;

export const RegisterHeader = styled.div`
  h1 {
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: 600;
  }
`;

export const RegisterContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const OptionBox = styled(NavLink)`
  padding: 1rem 2rem;
  border: 2px solid ${({ theme }) => theme.colors["primary-color"]};
  border-radius: 12px;

  color: ${({ theme }) => theme.colors["primary-color"]};

  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors["primary-color"]};
  }
`;

export const DisabledOptionBox = styled.button`
  padding: 1rem 2rem;
  border: 2px solid ${({ theme }) => theme.colors["primary-color"]};
  border-radius: 12px;

  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors["primary-color"]};

  opacity: 0.5;

  cursor: not-allowed;
`
