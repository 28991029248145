export function cnpjCpfValidator(value) {
  const cnpjCpf = value.replace(/\D/g, "");

  if (cnpjCpf.length === 11) {
    if (
      !cnpjCpf ||
      cnpjCpf.length != 11 ||
      cnpjCpf == "00000000000" ||
      cnpjCpf == "11111111111" ||
      cnpjCpf == "22222222222" ||
      cnpjCpf == "33333333333" ||
      cnpjCpf == "44444444444" ||
      cnpjCpf == "55555555555" ||
      cnpjCpf == "66666666666" ||
      cnpjCpf == "77777777777" ||
      cnpjCpf == "88888888888" ||
      cnpjCpf == "99999999999"
    )
      return false;
    var soma = 0;
    var resto;
    for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(cnpjCpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cnpjCpf.substring(9, 10))) return false;
    soma = 0;
    for (var i = 1; i <= 10; i++)
      soma = soma + parseInt(cnpjCpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cnpjCpf.substring(10, 11))) return false;
    return true;
  }

  if (
    !cnpjCpf ||
    cnpjCpf.length != 14 ||
    cnpjCpf == "00000000000000" ||
    cnpjCpf == "11111111111111" ||
    cnpjCpf == "22222222222222" ||
    cnpjCpf == "33333333333333" ||
    cnpjCpf == "44444444444444" ||
    cnpjCpf == "55555555555555" ||
    cnpjCpf == "66666666666666" ||
    cnpjCpf == "77777777777777" ||
    cnpjCpf == "88888888888888" ||
    cnpjCpf == "99999999999999"
  )
    return false;
  var tamanho = cnpjCpf.length - 2;
  var numeros = cnpjCpf.substring(0, tamanho);
  var digitos = cnpjCpf.substring(tamanho);
  var soma = 0;
  var pos = tamanho - 7;
  for (var i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;
  tamanho = tamanho + 1;
  numeros = cnpjCpf.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (var i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;
  return true;
}
