import { useState } from "react";
import { createContext } from "react";

export const ChargeTicketContext = createContext({});

export function ChargeTicketProvider({ children }) {
  const [ticketValue, setTicketValue] = useState("");
  const [ticketDueDate, setTicketDueDate] = useState("");
  const [ticketBillingName, setTicketBillingName] = useState("");
  const [ticketRecipientName, setTicketRecipientName] = useState("");
  const [ticketTaxNumber, setTicketTaxNumber] = useState("");
  const [ticketEmail, setTicketEmail] = useState("");
  const [ticketPhone, setTicketPhone] = useState("");
  const [ticketZipCode, setTicketZipCode] = useState("");
  const [ticketCity, setTicketCity] = useState("");
  const [ticketState, setTicketState] = useState("");
  const [fineValue, setFineValue] = useState("");
  const [feesValue, setFessValue] = useState(1);
  const [deadlinePayment, setDeadlinePayment] = useState(1);
  const [deadlineDiscount, setDeadlineDiscount] = useState(1);
  const [fineType, setFineType] = useState("fixed");
  const [discountType, setDiscountType] = useState("fixed");
  const [discountValue, setDiscountValue] = useState("");
  const [chargeInterest, setChargeInterest] = useState(false);
  const [chargeFees, setChargeFees] = useState(false);
  const [chargeDiscount, setChargeDiscount] = useState(false);

  return (
    <ChargeTicketContext.Provider
      value={{
        ticketValue,
        ticketDueDate,
        ticketBillingName,
        ticketRecipientName,
        ticketTaxNumber,
        ticketEmail,
        ticketPhone,
        ticketZipCode,
        ticketCity,
        ticketState,
        fineValue,
        feesValue,
        deadlinePayment,
        fineType,
        deadlineDiscount,
        discountType,
        discountValue,
        chargeInterest,
        chargeFees,
        chargeDiscount,
        setTicketValue,
        setTicketDueDate,
        setTicketBillingName,
        setTicketRecipientName,
        setTicketTaxNumber,
        setTicketEmail,
        setTicketPhone,
        setTicketZipCode,
        setTicketCity,
        setTicketState,
        setFineValue,
        setFessValue,
        setDeadlinePayment,
        setFineType,
        setDeadlineDiscount,
        setDiscountType,
        setDiscountValue,
        setChargeInterest,
        setChargeFees,
        setChargeDiscount,
      }}
    >
      {children}
    </ChargeTicketContext.Provider>
  );
}
