import { useRegister } from "../../../hooks/useRegister"
import { CompleteRegister } from "../CompleteRegister"
import { PersonDataForm } from "./PersonDataForm"
import { AddressForm } from "./AddressForm"
import { DocumentsForm } from "./DocumentsForm"
import { RegisterPJContainer } from "./styles"
import { ToastContainer } from "react-toastify"
import { PersonContactForm } from "./PersonContactForm"
import { ResumePf } from "./Resume"

export const RegisterPF = () => {
  const { success, step } = useRegister();

  return (
    <>
      {
        !success ? (
          <RegisterPJContainer>
            {step === 1 && <PersonDataForm />}
            {step === 2 && <PersonContactForm />}
            {step === 3 && <AddressForm />}
            {step === 4 && <DocumentsForm />}
            {step === 5 && <ResumePf />}
          </RegisterPJContainer>
        ) : <CompleteRegister />
      }
      <ToastContainer limit={2} />
    </>
  )
}