import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "./Style.css";
import { MdKeyboardArrowRight } from "react-icons/md";
import { TbKey } from 'react-icons/tb';
import { useAuth } from "../../../../hooks/useAuth";

export default function () {
  const [disabledInput, setDisabledInput] = useState(false);
  const [placeHolderInput, setPlaceHolderInput] = useState("Digite a chave Pix");
  const [inputMask, setInputMask] = useState("");
  const [keyType, setKeyType] = useState();
  const [keyValue, setKeyValue] = useState();

  const { user } = useAuth()

  let cnpj = user.taxNumber;
  const navigate = useNavigate();

  const handleSelectOnChange = (value) => {
    if (value == 0) {
      setKeyValue("");
      setInputMask("");
      setDisabledInput(false);
    }
    if (value == 1) {
      setKeyValue(cnpj);
      setDisabledInput(true);
    } else if (value == 2) {
      setKeyValue("");
      setInputMask("");
      setDisabledInput(false);
    } else if (value == 3) {
      setKeyValue("");
      setInputMask("(99)99999-9999");
      setDisabledInput(false);
    } else if (value == 4) {
      setKeyValue("");
      setDisabledInput(true);
      setPlaceHolderInput("Chave aleatória será gerada automaticamente");
    }
    setKeyType(value);
  };

  const handleOnClick = () => {
    if (keyType != 4) {
      if (keyValue == "") {
        toast.error("Valor é obrigatório");
        return false;
      }
    }
    if (keyType == "") {
      toast.error("Tipo da chave é obrigatório");
      return false;
    }
    navigate("/confirmar-chave-pix");
  };

  return (
    <div className="cadastro-chave-pix">
      <div className="cadastro-chave-pix-container col-md-10">
        <div className="header">
            <div className="col-xl-12">
              <label>
                <Link to={"/pagina-principal"}>
                  Pagina inicial
                  <MdKeyboardArrowRight size={20} />
                </Link>
                <Link to={"/pagina-pix"}>
                  Área Pix
                  <MdKeyboardArrowRight size={20} />
                </Link>
                Minha Chaves
              </label>
              <p>Minha Chaves</p>
            </div>
          </div>

        <div className="body">
          <div>
            <h1>Cadastre uma nova Chave</h1>
          </div>

          <div>
            <label>Tipo de Chave</label>
            <div className="input-area col-md-5">
              <div className="col-md-1 input-area-icon">
                <TbKey size={20} color="#000"/>
              </div>
              <div className="col-md-11">
                <select
                  name="select"
                  onChange={(e) => handleSelectOnChange(e.target.value)}
                >
                  <option hidden value=""></option>
                  <option value="4">Chave aleatória</option>
                </select>
              </div>
            </div>
          </div>

            <label>Chave Pix</label>
          <div className="input-area col-md-5">
            <div className="col-md-1">

            </div>
            <div className="col-md-11">
              <input
                disabled={disabledInput}
                value={keyValue}
                onChange={(e) => setKeyValue(e.target.value)}
                ></input>
            </div>
            </div>

          <div>
            <button onClick={() => handleOnClick()}>Avançar</button>
          </div>
        </div>
      </div>
      <ToastContainer limit={2} />
    </div>
  );
}
