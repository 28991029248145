import ReactModal from "react-modal";
import { BoxModal } from "../../../components/boxModal";
import { Input } from "../../../components/Input";
import { TbCircleCheck, TbCircleX, TbCircle } from 'react-icons/tb'
import { useState } from "react";
import { useAccount } from '../../../hooks/useAccount'
import { BeatLoader } from "react-spinners";
import { css } from "@emotion/react";

import './styles.css'

export function PasswordChangeModal({ isOpen, setPasswordChangeModal }) {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [validNewPassword, setValidNewPassword] = useState(false)
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [newPasswordConfirmationError, setNewPasswordConfirmationError] = useState('')
  const [loader, setLoader] = useState(false)

  const [passwordMinLength, setPasswordMinLength] = useState('default')
  const [passwordHasNumber, setPasswordHasNumber] = useState('default')
  const [passwordHashUpperCase, setPasswordHashUpperCase] = useState('default')
  const [passwordHasSpecialChar, setPasswordHasSpecialChar] = useState('default')

  const { editPassword } = useAccount()

  function handleCloseModal() {
    setPasswordChangeModal(false)
  }

  function handleChangePassword(e) {
    e.preventDefault()

    setLoader(true)

    let passMatch = false;

    if (newPassword !== newPasswordConfirmation) {
      passMatch = false
      setNewPasswordConfirmationError('As senhas não coincidem.')
      setLoader(false)
    } else {
      passMatch = true
      setNewPasswordConfirmationError('')
    }

    if (validNewPassword && passMatch) {
      const data = {
        currentPassword: currentPassword,
        newPassword: newPassword, 
        confirmationNewPassword: newPasswordConfirmation,
      }

      editPassword(data)
        .then(() => {
          setPasswordChangeModal(false)
          setCurrentPassword('')
          setNewPassword('')
          setNewPasswordConfirmation('')
          setNewPasswordConfirmationError('')
          setPasswordMinLength('default')
          setPasswordHasNumber('default')
          setPasswordHashUpperCase('default')
          setPasswordHasSpecialChar('default')
          setLoader(false)
        })
        .catch(() => {
          setLoader(false)
        })
    }
  }

  function handleChangeNewPassword(value) {
    let passLength = false;
    let passNumber = false;
    let passUpper = false;
    let passChar = false;

    if (value.length < 8) {
      setPasswordMinLength('error')
      passLength= false
    } else {
      setPasswordMinLength('check')
      passLength = true
    }

    if (!/[0-9]/.test(value)) {
      setPasswordHasNumber('error')
      passNumber= false
    } else {
      setPasswordHasNumber('check')
      passNumber = true
    }

    if (!/[A-Z]/.test(value)) {
      setPasswordHashUpperCase('error')
      passUpper= false
    } else {
      setPasswordHashUpperCase('check')
      passUpper = true
    }

    if (!/[!@#$%^&*()\-_=+{};:,<.>/?]/.test(value)) {
      setPasswordHasSpecialChar('error')
      passChar= false
    } else {
      setPasswordHasSpecialChar('check')
      passChar = true
    }

    setNewPassword(value)

    if (
      passLength &&
      passNumber &&
      passUpper &&
      passChar 
    ) {
      setValidNewPassword(true)
    }
  }

  const override = css`
    display: flex;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <ReactModal
      isOpen={isOpen}
      className={"change-modal"}
      overlayClassName={"overlay-change-password-modal"}
    >
      <BoxModal
        title='Alterar Senha'
        maxWidth={680} 
        close={handleCloseModal}
      >
        <form className="change-password-container" onSubmit={handleChangePassword}>
          <div className="change-password-section">
            <h3>Confirme a Senha Atual</h3>
            <Input 
              type="password"
              label="Senha Atual"
              value={currentPassword}
              onChange={setCurrentPassword}
              placeholder="••••••••"
              isRequired
            />
          </div>
          <div className="change-password-section">
            <h3>Insira a nova Senha</h3>
            
            <div className="password-change-inputs">
              <Input 
                type="password"
                label="Nova Senha"
                value={newPassword}
                onChange={handleChangeNewPassword}
                placeholder="••••••••"
                isRequired
              />

              <div className="password-validation-container">
                <span>
                  {
                    passwordMinLength === 'default' 
                    ? <TbCircle size={16} /> 
                    : passwordMinLength === 'check'
                      ? <TbCircleCheck className="check-validation" size={16} />
                      : <TbCircleX className="error-validation" size={16} />
                  }
                  8 Caracteres
                </span>
                <span>
                  {
                    passwordHasNumber === 'default' 
                    ? <TbCircle size={16} /> 
                    : passwordHasNumber === 'check'
                      ? <TbCircleCheck className="check-validation" size={16} />
                      : <TbCircleX className="error-validation" size={16} />
                  }
                  Mínimo de 1 número
                </span>
                <span>
                  {
                    passwordHashUpperCase === 'default' 
                    ? <TbCircle size={16} /> 
                    : passwordHashUpperCase === 'check'
                      ? <TbCircleCheck className="check-validation" size={16} />
                      : <TbCircleX className="error-validation" size={16} />
                  }
                  Mínimo de 1 Letra maiúscula
                </span>
                <span>
                  {
                    passwordHasSpecialChar === 'default' 
                    ? <TbCircle size={16} /> 
                    : passwordHasSpecialChar === 'check'
                      ? <TbCircleCheck className="check-validation" size={16} />
                      : <TbCircleX className="error-validation" size={16} />
                  }
                  Mínimo de 1 caractere especial
                </span>
              </div>

              <div className="password-confirmation">
                <Input 
                  type="password"
                  label="Nova Senha"
                  value={newPasswordConfirmation}
                  onChange={setNewPasswordConfirmation}
                  placeholder="••••••••"
                  isRequired
                />
                {newPasswordConfirmationError && (
                  <span>
                    <TbCircleX className="error-validation" size={16} /> 
                    {newPasswordConfirmationError}
                  </span>
                )}
              </div>
            </div>
          </div>

          <button type="submit" className="password-change-button">
            {
              loader 
              ? (
                <BeatLoader
                  color="white"
                  loading={loader}
                  css={override}
                  size={20}
                /> 
              )
              : "Salvar nova senha"
            }
          </button>
        </form>
      </BoxModal>
    </ReactModal>
  )
}