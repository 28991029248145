import { Input } from '../../../../components/Input';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Box } from '../../Box';
import { useNavigate } from 'react-router-dom';
import { useRegister } from '../../../../hooks/useRegister';

import {
  InputContainer,
  PersonContactFormContent,
  PersonContactFormInputs,
} from './style';
import { formatPhone } from '../../../../utils/formatter';

const PersonContactFormSchema = z.object({
  email: z.string().email(),
  phone: z.string().min(14, 'Telefone inválido.').max(15, 'Telefone inválido.'),
  password: z
    .string()
    .min(8, { message: 'A senha deve ter pelo menos 8 caracteres.' })
    .regex(/[a-z]/, { message: 'A senha deve conter pelo menos uma letra minúscula.' })
    .regex(/[A-Z]/, { message: 'A senha deve conter pelo menos uma letra maiúscula.' })
    .regex(/[0-9]/, { message: 'A senha deve conter pelo menos um número.' })
    .regex(/[^a-zA-Z0-9]/, { message: 'A senha deve conter pelo menos um caractere especial.' }),
  confirmPassword: z
    .string()
    .min(8, 'O campo senha precisa de no mínimo 8 caracteres.'),
});

export const PersonContactForm = () => {
  const { postContactDataPf, personPf, setStep } = useRegister();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      email: personPf.email ?? '',
      phone: personPf.phone ?? '',
      password: personPf.password ?? '',
      confirmPassword: personPf.confirmPassword ?? '',
    },
    resolver: zodResolver(PersonContactFormSchema),
  });

  const handleNext = async (data) => {
    const password = data.password
    const confirmPassword = data.confirmPassword

    if (confirmPassword !== password) {
      setError("confirmPassword", { type: "custom", message: "A senhas não coincidem." })

      return
    }
    
    postContactDataPf(data);
  };

  const handleBack = () => {
    setStep(1)
  };

  return (
    <Box
      handleSubmit={handleSubmit}
      submitEvent={handleNext}
      title="Preencha seus dados"
      description="Complete os campos essenciais para iniciar o processo de abertura de conta pessoal"
      buttonOne="Voltar"
      buttonTwo="Prosseguir"
      marginLeft="l"
      handleBack={handleBack}
    >
      <PersonContactFormInputs>
        <PersonContactFormContent>
          <span>Dados de Contato</span>

          <InputContainer>
            <Input
              type="text"
              label="E-mail"
              error={errors.email && errors.email.message}
              isRequired
              register={register('email', {
                required: true,
              })}
            />
            <Input
              type="text"
              label="Telefone"
              error={errors.phone && errors.phone.message}
              maxLength={15}
              isRequired
              onlyNumber
              register={register('phone', {
                required: true,
                onChange: (e) => e.target.value = formatPhone(e.target.value.replace(/\D/g, '')),
              })}
            />
            <Input
              type="password"
              label="Senha"
              error={errors.password && errors.password.message}
              isRequired
              register={register('password', {
                required: true,
              })}
            />
            <Input
              type="password"
              label="Confirme sua senha"
              error={errors.confirmPassword && errors.confirmPassword.message}
              isRequired
              register={register('confirmPassword', {
                required: true,
              })}
            />
          </InputContainer>
        </PersonContactFormContent>
      </PersonContactFormInputs>
    </Box>
  );
};
