import axios from "axios";
const pjson = require("../../package.json");

export async function getCNPJ(cnpj) {
    const token = "Hi1dIc5Ph4sMLpqQKkr0a79P0GEL3fYF2k3umB0287c5";
    try {
      const empresa = await consultarCNPJ(cnpj, token);
      return empresa;
    } catch(err) {
      console.log('erro:', err);
      return err;
    }
}

async function consultarCNPJ(cnpj, token) {
  if (!cnpj) throw new Error("CNPJ não informado");

  cnpj = cnpj.replace(/[^0-9]/g, "");

  return new Promise((resolve, reject) => {
    let url = "";

    if (token) {
      url = `https://comercial.cnpj.ws/cnpj/${cnpj}?token=${token}`;
    } else {
      url = `https://publica.cnpj.ws/cnpj/${cnpj}`;
    }

    axios({
      method: "get",
      url,
      headers: { "User-Agent": `consultar-cnpj/${pjson.version}` },
    })
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
