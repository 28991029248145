import styled from 'styled-components';

export const DocumentsFormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  span {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const DocumentsFormInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;

  > div {
    max-width: 18.75rem;
    width: 100%;
  }
`;

export const InstituicaoInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  label {
    font-size: 0.875rem;
    font-weight: 400;

    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  div {
    height: 43px;
  }

  span {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.danger};
  }
`;

export const Select = styled.select`
  width: 100%;
  max-height: 2.6875rem;
  height: 100%;
  padding: 0.625rem 0.75rem;
  background-color: ${({ theme }) => theme.colors['bg-input-color']};
  border: 1px solid ${({ theme }) => theme.colors['text-primary']};
  border-radius: 4px;

  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const DocumentTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  span {
    font-size: 0.875rem;
    font-weight: 600;
  }
`;

export const DocumentTypeContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
  }
`
