import styled from "styled-components";

export const EcheckTemplate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 60%;
`

export const EcheckTemplateContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Arial, sans-serif';
  margin-top: 1.8vw;
  width: 100%;
  border: 1px solid #ccc;
  background-color: ${({ backgroundColor }) => `${backgroundColor}`};
  background-image: ${({ backgroundImageUrl }) => `url(${backgroundImageUrl})`};
  padding-bottom: 2rem;

  @media (max-width: 1080px) {
   padding-bottom: 1rem;
  }
`

export const EcheckTemplateHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.1vw;
`

export const EcheckTemplateHeaderImage = styled.div`
 margin-top: 1.4vw;
 margin-left: 2.5vw;

 & img{
  max-width: 7vw;
 }
`

export const EcheckTemplateHeaderContent = styled.div`
  width: 50%;
  background: ${({ barColor }) => `${barColor}`};
  clip-path: polygon(50px 0, 100% 0, 100% 100%, 0 100%);
`

export const EcheckTemplateHeaderTextArea = styled.div`
  text-align: right;
  color: #fff;
  height: 60%;
  align-items: center;
  display: flex;
  justify-content: end;
  margin-right: 2.5vw;
`

export const EcheckTemplateHeaderPrefixLabel = styled.label`
  font-size: 0.9vw;
  align-items: end;
  display: flex;
  margin-right: 0.8vw;
`

export const EcheckTemplateHeaderIdLabel = styled.label`
  font-size: 1vw;
  line-height: 0.9;
`

export const EcheckTemplateBody = styled.div`
  margin: 0 2.5vw 1.4vw 2.5vw;
`

export const EcheckTemplateBodyContent = styled.div`
  display: flex;
  justify-content: space-between;
`

export const EcheckTemplateBodyFieldsArea = styled.div`
  font-size: 0.8vw;
  margin: 0;
  margin-bottom: 0.4vw;
  font-weight: bold;
  display: flex;
`

export const EcheckTemplateBodyLabel = styled.p`
  padding-top: 0.8vw;
  color: ${({ labelColor }) => `${labelColor}`};
  font-size: 0.7vw;
  font-weight: bold;
  margin-right: 0.8vw;
  margin-left: ${(props) => props.marginleft ?? '0' };
`

export const EcheckTemplateBodyValues = styled.p`
  margin-top: -0.2vw;
  padding-bottom: 2vw;
  font-size: 1.6vw;
  font-family: 'Dancing Script', cursive;
  font-weight: normal;
  color: ${({ textColor }) => `${textColor}`};
  border-bottom: ${({ hasBorder, borderColor }) => hasBorder ? `3px solid ${borderColor}`: ''};
  flex-grow: 1;
`

export const EcheckTemplateBodyValueFieldsArea = styled.div`
  display: flex;
  justify-content: space-between;
  height: 3.5vw;
`

export const EcheckTemplateBodyValueField = styled.div`
  display: flex;
  height: 3vw;
  width: ${(props) => props.width };
`

export const EcheckTemplateBodySymbolValueField = styled.p`
  font-size: 1.6vw;
  font-weight: bold;
  margin-left: 1.8vw;
  margin-right: 0.8vw;
  color: ${({ labelColor }) => `${labelColor}`};
`
